////
/// @group typography
////

@use './font';
@use './shared';
@use '@angular/material';
@use 'sass:list';
@use 'sass:map';

/// default value of the $lightweight parameter of the
/// [font-faces](#typography-mixin-font-faces)
/// mixin
/// @type Boolean
$lightweight-default: false;

/// [Angular Material typography config](https://material.angular.io/guide/typography#typography-configs-and-theming)
/// that applies the
/// [Roboto](https://fonts.google.com/specimen/Roboto)
/// font family required by
/// [IUX](https://dukeenergy.sharepoint.com/sites/UXDesign)
/// and may be directly incorporated into an
/// [Angular Material theme](https://material.angular.io/guide/theming)
/// @type Map
$typography-config: material.define-typography-config(
    $font-family: font.$font-family,
);

/// Emits @font-face rules for the
/// [Roboto](https://fonts.google.com/specimen/Roboto)
/// font family required by
/// [IUX](https://dukeenergy.sharepoint.com/sites/UXDesign).
/// By default, these rules aim for high quality by referencing many font face files that each exhibit unique
/// style and weight combinations. The font face files are included in
/// [@iux/ng-mat-theme](../typography)
/// and intended to be copied to the distribution of an
/// [Angular](https://angular.io/docs)
/// app. This should happen automatically with Angular's use of
/// [sass-loader](https://www.npmjs.com/package/sass-loader).
/// In referencing all of these files, the rules significantly reduce or eliminate the need of the browser
/// to artificially slope, bolden, or otherwise manipulate the font of text to apply font weight or other
/// font-related styles. This lessens runtime computational load and results in text with higher
/// fidelity fonts. For a better understanding of the quality difference, see this
/// [example of a true italic font versus a normal font artficially styled to look like an italic font](https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-style#examples).
/// However, this approach has a downside. Instead of the browser having to issue one HTTP request to
/// collect a single font face file, under this arrangement, the browser must issue a separate HTTP request for each
/// font face file. The good news is that such a request will be issued only if and when the the app needs
/// a particular font face file. In other words, if the app applies a 900-weight font to only one piece
/// of text of an infrequently-used feature, the supporting font face file will be collected through HTTP only
/// at the first rendering of that text. (Each @font-face rule uses a "swap" font-display strategy.) And if the
/// application has no 900-weight text, there will never be an HTTP request for the  associated font face file. Also,
/// [WOFF2](https://www.w3.org/TR/WOFF2/)
/// is used to make the files as small as possible. See
/// [@iux/ng-mat-theme typography](../typography).
/// @param {Boolean} $lightweight [false] - If true, emits only one normal, 400-weight @font-face rule.
/// Use this option if you accept the lower quality of artificially styled fonts and want to minimize
/// font-fetching HTTP requests.
/// @output [@font-face](https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face) rules
/// @throw if a parameter is supplied an argument of the wrong type
/// @example scss - Emit only one normal, 400-weight, Roboto @font-face rule.
///     @use '@iux/ng-mat-theme';
///
///     @include ng-mat-theme.font-faces($lightweight: true);
///
@mixin font-faces($lightweight: $lightweight-default) {
    $void: shared.error-if-incorrect-argument-type(
        '$lightweight',
        $lightweight,
        'bool'
    );
    @each $font-face in font.$font-faces {
        $font-style: map.get($font-face, 'font-style');
        $font-weight: map.get($font-face, 'font-weight');
        $is-normal: $font-style == 'normal' and $font-weight == 400;

        @if (not $lightweight) or $is-normal {
            $path: map.get($font-face, 'path');

            @font-face {
                font-display: map.get($font-face, 'font-display');
                font-family: map.get($font-face, 'font-family');
                font-style: $font-style;
                font-weight: $font-weight;
                src: map.get($font-face, 'src');
            }
        }
    }
}
