// Grid

/*
 * Grid Column Percent
 * Used to simply get the percentage of each column to accomodate
 * the number of columns count passed into the function.
 *
 * @param {string}  $columns  The number of columns in a row
 *
 * Usage
 * @include grid-column-percent(3);
 */

@function grid-column-percent($columns) {
  @return 100 / $columns * 1%;
}

/*
 * Grid Gutter
 * Used to simply get gutter size from the spacing scale. It
 * takes a size from the spacing scale and divides it in half.
 *
 * @param {string}  $spacing  The size from spacing scale
 *
 * Usage
 * @include grid-gutter(m);
 */

@function grid-gutter($spacing) {
  @return spacing($spacing) / 2;
}

/*
 * Grid Container
 * Used to containing element of the columns to offset the gutter between
 * the columns. The values are pulled from the spacing scale. You can pass
 * in one value (if gutter is equal on all sides) or two variables (if gutter
 * has different values vertically than horizontally).
 *
 * @param {string}  $gutter  The gutter size (equal or offset)
 *
 * Usage
 * @include grid-container(m);
 * @include grid-container(m l);
 * @include grid-container((l xl));
 */

@mixin grid-container($gutter) {

  // Get count of gutters
  $count: length($gutter);

  // If only one, then equal gutter sizes
  @if $count == 1 {

    // Offset column gutters equally
    margin: grid-gutter(-#{$gutter});

  // If two, then offset gutter sizes
  } @else if $count == 2 {

    // Get top/bottom gutter
    $first: nth($gutter, 1);

    // Get left/right gutter
    $last: nth($gutter, 2);

    // Offset column gutters offset
    margin: grid-gutter(-#{$first}) grid-gutter(-#{$last});

  }
}

/*
 * Grid Column
 * Used to create your columns. You are required to pass in the column count.
 * It will create equal width columns using a percentage. You can optionally
 * pass in a gutter size. The values are pulled from the spacing scale. You can
 * pass in one value (if gutter is equal on all sides) or two variables (if gutter
 * has different values vertically than horizontally).
 *
 * @param {int}     $columns   The number of columns
 * @param {string}  $gutter    The gutter size (equal or offset)
 * @param {string}  $method    Use padding or margin for the gutter
 *
 * Usage
 * @include grid-column(3);
 * @include grid-column(3, m l);
 * @include grid-column(4, (m l));
 * @include grid-column(4, (m l), margin);
 */

@mixin grid-column($columns, $gutter: null, $method: null) {
  $columnHasUnit: unit($columns) != '';
  //@debug $columnHasUnit;
  $columnSize: if($columnHasUnit, $columns, grid-column-percent($columns));
  $gutterLength: length($gutter);
  $gutterSize: spacing($gutter);

  @if $gutter {
    @if $method == 'margin' {
      margin: calc(#{$gutterSize} / 2);
      width: calc(100% / #{$columns} - #{$gutterSize});
    } @else {
      padding: spacing($gutter) / 2;
      width: #{$columnSize};
    }
  } @else {
    width: #{$columnSize};
  }
}

@mixin grid-gutter($gutter) {
  @if map-has-key($spacing-scale, $gutter) {
    @include spacing-equal(padding, #{$gutter});
  }
}

@mixin grid-gutter-offset($gutter) {
  @if map-has-key($spacing-scale, $gutter) {
    @include spacing-equal(margin, -#{$gutter});
  }
}
