////
/// @group iconography
////

/// Emits
/// [Material Icons](https://fonts.google.com/icons?icon.set=Material+Icons)
/// styles. See
/// [@iux/ng-mat-theme iconography](../iconography).
/// @output [@font-face](https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face)
/// rules and other styles that facilitate
/// [self-hosting](https://developers.google.com/fonts/docs/material_icons#setup_method_2_self_hosting)
/// of the
/// [Material Icons](https://fonts.google.com/icons?icon.set=Material+Icons)
/// font family.
@mixin icons() {
    $font-family: 'Material Icons';
    @font-face {
        $font-format: 'woff2';

        font-family: $font-family;
        font-style: normal;
        font-weight: 400;
        src: url('material-icons-regular.#{$font-format}') format($font-format);
    }

    .material-icons {
        font-family: $font-family;
        font-size: 24px; /* Preferred icon size */
        font-style: normal;
        font-weight: normal;
        direction: ltr;
        display: inline-block;
        letter-spacing: normal;
        line-height: 1;
        text-transform: none;
        white-space: nowrap;
        word-wrap: normal;

        /* Support for Safari and Chrome. */
        text-rendering: optimizeLegibility;

        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;

        /* Support for all WebKit browsers. */
        -webkit-font-smoothing: antialiased;
    }
}
