@use 'sass:meta';
@use 'sass:string';

/// @access private
@mixin custom-properties-from-map(
    $map,
    $property-prefix: null,
    $valueType: 'color'
) {
    $complete-property-prefix: '--iux-ng-mat-theme';

    @if $property-prefix {
        $complete-property-prefix: '#{$complete-property-prefix}-#{$property-prefix}';
    }

    :root {
        @include -custom-properties-from-map(
            $map,
            $complete-property-prefix,
            $valueType
        );
    }
}

/// @access private
@function error-if-incorrect-argument-type(
    $parameter,
    $argument,
    $correct-type
) {
    $argument-type: meta.type-of($argument);
    @if $argument-type != $correct-type {
        @error (
            'Parameter #{$parameter} requires an argument of type #{$correct-type} but was supplied one of type #{$argument-type}.'
        );
    }
    @return null;
}

@mixin -custom-properties-from-map($map, $property-prefix, $valueType) {
    @each $key, $value in $map {
        $is-key-public: string.slice(#{$key}, 1, 1) != '_';
        @if $is-key-public {
            $property: '#{$property-prefix}-#{$key}';
            @if meta.type-of($value) == $valueType {
                #{$property}: #{$value};
            } @else if meta.type-of($value) == 'map' {
                @include -custom-properties-from-map(
                    $value,
                    $property,
                    $valueType
                );
            }
        }
    }
}
