.mdc-data-table__header-cell {
    color: var(--iux-ng-mat-theme-blue-dark-primary);
    font-weight: 700;
    font-size: 16px;
}

.table-container {
    padding: 2rem 1.5rem;

    &--blue {
        background-color: var(--iux-ng-mat-theme-blue-dark-primary);
    }
    .table-holder {
        border-radius: 6px;
        overflow:hidden;
    }
}

.mdc-data-table__table {
    border-top-color: var(--mat-table-row-item-outline-color, rgba(0, 0, 0, 0.12)) !important;
    border-top-width: var(--mat-table-row-item-outline-width, 1px) !important;
    border-top-style: solid !important;
}