$theme-colors: (
    blue-dark-primary: var(--iux-ng-mat-theme-blue-dark-primary),
    blue-light-primary: var(--iux-ng-mat-theme-blue-light-primary),
    green-primary: var(--iux-ng-mat-theme-green-primary),
    burgandy-dark-secondary: var(--iux-ng-mat-theme-burgandy-dark-secondary),
    burgandy-medium-secondary: var(--iux-ng-mat-theme-burgandy-medium-secondary),
    cream-secondary: var(--iux-ng-mat-theme-cream-secondary),
    gray-1-secondary: var(--iux-ng-mat-theme-gray-1-secondary),
    gray-2-secondary: var(--iux-ng-mat-theme-gray-2-secondary),
    green-dark-secondary: var(--iux-ng-mat-theme-green-dark-secondary),
    green-medium-secondary: var(--iux-ng-mat-theme-green-medium-secondary),
    orange-secondary: var(--iux-ng-mat-theme-orange-secondary),
    tan-secondary: var(--iux-ng-mat-theme-tan-secondary),
    teal-secondary: var(--iux-ng-mat-theme-teal-secondary),
    yellow-medium-secondary: var(--iux-ng-mat-theme-yellow-medium-secondary),
    yellow-secondary: var(--iux-ng-mat-theme-yellow-secondary)
);

.mdc-snackbar {
    @each $key, $val in $theme-colors {
        &.#{$key} {
            .mdc-snackbar__surface {
                background-color: #{$val};
            }
            .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
                color: white;
            }
        }
    }
}