////
/// @group index
////

@forward './coloration';
@forward './iconography';
@forward './palette';
@forward './theme';
@forward './typography';
@use './coloration';
@use './iconography';
@use './palette';
@use './shared';
@use './theme';
@use './typography';
@use '@angular/material';
@use '@material/textfield';
@use 'sass:color';
@use 'sass:map';
@use 'sass:math';

$-contrast-prefix: 'contrast';

/// Include this mixin in a Sass global style sheet of an
/// [Angular](https://angular.io/docs)
/// 15, 16, or 17 application project that exclusively utilizes components that participate in the
/// [Angular Material theming system](https://material.angular.io/guide/theming).
/// This mixin equips an Angular app with all styles and associated resources needed to satisfy
/// [Material Design](https://m2.material.io/)
/// and
/// [IUX](https://dukeenergy.sharepoint.com/sites/UXDesign)
/// standards for internal web UIs in terms of
/// [coloration](../coloration),
/// [typography](../typography),
/// [iconography](../iconography),
/// and other aspects. This is the only mixin of
/// [@iux/ng-mat-theme](../getting-started)
/// that must be included, and its use eliminates the need to implement the steps described in both the
/// [Theming](https://material.angular.io/guide/theming)
/// and
/// [Customizing Typography](https://material.angular.io/guide/typography)
/// [Angular Material](https://material.angular.io/)
/// guides. You may find the
/// [prebuilt CSS offered by @iux/ng-mat-theme](../getting-started#import-the-@nuc-mp/ng-mat-theme-css)
/// easier to use, esspecially if you have no need of the advanced options and customizations
/// facilitated by this mixin.
/// @param {Map} $light-theme-colors [( 'primary': #005984, 'accent': #26bcd7, 'warn': #9e1b32 )] - A map of
/// [Angular Material theme palette](https://material.angular.io/guide/theming#themes)
/// labels ("primary", "accent", and "warn") to light theme colors, each of which informs
/// the automatic generation of its associated palette and serves as the 500 variant of that palette.
/// @param {Map} $dark-theme-colors [( 'primary': #26bcd7, 'accent': #54b948, 'warn': #bb5f70 )] - A map of
/// [Angular Material theme palette](https://material.angular.io/guide/theming#themes)
/// labels ("primary", "accent", and "warn") to dark theme colors, each of which informs
/// the automatic generation of its associated palette and serves as the 500 variant of that palette.
/// @param {String} $dark-theme-trigger-root-html-class [null] - A class that, when imparted to the <html> element,
/// triggers the application of the dark theme. Otherwise, the light theme is applied. If omitted, the applied theme
/// is based on operating system or browser appearance settings.
/// @param {String} $palette-generation-algorithm ['default'] - Algorithm for calculating palette variant colors.
/// If omitted, the recommended "default" algorithm is used. A "lighter-accent" algorithm produces the same result
/// but with lighter accent variants, which bear labels starting with "A". And a "higher-contrast" algorithm is
/// supported for producing a palette map with larger color differences between each variant compared to the maps
/// produced by the other agorithms.
/// @param {Number} $density [-2] -
/// [Angular Material density customization scale](https://material.angular.io/guide/theming#customizing-density).
/// The default is an
/// [IUX](https://dukeenergy.sharepoint.com/sites/UXDesign)-recommended
/// reduced density scale of -2, which results in relatively vertically denser components.
/// @param {Map} $typography-config [@iux/ng-mat-theme.$typography-config] -
/// [Angular Material typography config](https://material.angular.io/guide/typography#typography-configs-and-theming).
/// The
/// [default-config](#theme-function-define-mat-theme)
/// applies the
/// [Roboto](https://fonts.google.com/specimen/Roboto)
/// font-family.
/// Warning: A $typography-config that does not employ the
/// [Roboto](https://fonts.google.com/specimen/Roboto)
/// font family is not
/// [IUX](https://dukeenergy.sharepoint.com/sites/UXDesign)-compliant.
/// See
/// [@iux/ng-mat-theme coloration](../typography).
/// @param {Boolean} $lightweight-typography [false] - If true, only one normal, 400-weight
/// [@font-face](https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face)
/// rule is emitted. Use this option if you accept the lower quality of
/// [artificially styled fonts](https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-style#examples)
/// and want to minimize the size of your app's distribution and reduce font-fetching HTTP requests.
/// @output [Material-Design](https://m2.material.io)-based
/// light and dark theme coloration styles, typography styles,
/// [Roboto](https://fonts.google.com/specimen/Roboto)
/// and
/// [Material Icons](https://fonts.google.com/icons?icon.set=Material+Icons)
/// font face styles, and all other styles required for
/// [IUX](https://dukeenergy.sharepoint.com/sites/UXDesign)
/// compliance in an Angular app that
/// utilizes components that participate in the
/// [Angular Material theming system](https://material.angular.io/guide/theming)
/// @example scss - Emit [Material Design](https://m2.material.io/) and [IUX](https://dukeenergy.sharepoint.com/sites/UXDesign) compliant styles for your Angular app while ensuring that dark theme is not activated based operating system or browser appearance settings, but rather when the "dark" class is applied to the <html> element.
///     @use '@iux/ng-mat-theme';
///
///     @include ng-mat-theme.theme($dark-theme-trigger-root-html-class: dark);
///
@mixin theme(
    $light-theme-colors: coloration.$light-theme-colors,
    $dark-theme-colors: coloration.$dark-theme-colors,
    $dark-theme-trigger-root-html-class: null,
    $palette-generation-algorithm:
        map.get(palette.$palette-algorithms, 'default'),
    $density: theme.$density-default,
    $typography-config: typography.$typography-config,
    $lightweight-typography: typography.$lightweight-default
) {
    $offset-prefix: offset;
    $light-theme: theme.define-mat-theme(
        $light-theme-colors,
        map.get(theme.$theme-types, 'light'),
        $palette-generation-algorithm,
        $density,
        $typography-config
    );
    @include shared.custom-properties-from-map(
        $light-theme,
        map.get(theme.$theme-types, 'light')
    );

    $offset-light-theme: theme.define-mat-theme(
        -offset-theme-colors($light-theme-colors, map.get(theme.$theme-types, 'light')),
        map.get(theme.$theme-types, 'light'),
        $palette-generation-algorithm,
        $density,
        $typography-config
    );
    @include shared.custom-properties-from-map(
        $offset-light-theme,
        "#{$offset-prefix}-#{map.get(theme.$theme-types, 'light')}"
    );

    @include -light-theme-colors-and-core-styles(
        $light-theme,
        $offset-light-theme
    );

    $dark-theme: theme.define-mat-theme(
        $dark-theme-colors,
        map.get(theme.$theme-types, 'dark'),
        $palette-generation-algorithm,
        $density,
        $typography-config
    );
    @include shared.custom-properties-from-map(
        $dark-theme,
        map.get(theme.$theme-types, 'dark')
    );

    $offset-dark-theme: theme.define-mat-theme(
        -offset-theme-colors($dark-theme-colors, map.get(theme.$theme-types, 'dark')),
        map.get(theme.$theme-types, 'dark'),
        $palette-generation-algorithm,
        $density,
        $typography-config
    );
    @include shared.custom-properties-from-map(
        $offset-dark-theme,
        "#{$offset-prefix}#{map.get(theme.$theme-types, 'dark')}"
    );

    @if $dark-theme-trigger-root-html-class {
        :root.#{$dark-theme-trigger-root-html-class} {
            @include -dark-theme-colors($dark-theme, $offset-dark-theme);
        }
    } @else {
        @media (prefers-color-scheme: dark) {
            @include -dark-theme-colors($dark-theme, $offset-dark-theme);
        }
    }
    @include -dense-form-field-bug-workaround($density);
    @include typography.font-faces($lightweight-typography);
    @include material.typography-hierarchy(typography.$typography-config);
    @include iconography.icons;
    @include -corporate-color-custom-properties;
    @include shared.custom-properties-from-map(
        palette.$contrast-colors,
        $-contrast-prefix
    );
}

// Supports the creation of uniformly offset theme palettes for specific components.
@function -offset-theme-colors($theme-colors, $theme-type) {
    $modifier: 1;
    @if $theme-type == map.get(theme.$theme-types, 'dark') {
        $modifier: -1;
    }

    // The differences between the blue-dark-primary corporate color and the 500 variant
    // of the light theme primary palette serve as standard offsets.
    $red-offset: (
            color.red(
                    map.get(coloration.$corporate-colors, 'blue-dark-primary')
                )- color.red(map.get(coloration.$light-theme-colors, 'primary'))
        ) * $modifier;
    $green-offset: (
            color.green(
                    map.get(coloration.$corporate-colors, 'blue-dark-primary')
                )- color.green(map.get(coloration.$light-theme-colors, 'primary'))
        ) * $modifier;
    $blue-offset: (
            color.blue(
                    map.get(coloration.$corporate-colors, 'blue-dark-primary')
                )- color.blue(map.get(coloration.$light-theme-colors, 'primary'))
        ) * $modifier;
    $offset-theme-colors: ();
    @each $key, $value in $theme-colors {
        $offset-theme-colors: map.set(
            $offset-theme-colors,
            $key,
            color.adjust(
                $value,
                $red: $red-offset,
                $green: $green-offset,
                $blue: $blue-offset
            )
        );
    }
    @return $offset-theme-colors;
}

@mixin -corporate-color-custom-properties {
    @include shared.custom-properties-from-map(coloration.$corporate-colors);

    $contrasts: palette.color-map-to-contrast-map(coloration.$corporate-colors);

    @include shared.custom-properties-from-map($contrasts, $-contrast-prefix);
}

// Forces anchor colors to match those applied by default in dark theme by browsers with the Blink HTML
// rendering engine (Chromium-based browsers). This makes dark theme anchor colors consistent across
// browsers, though that isn't important in and of itself. The goal is to address this Safari bug,
// which prevents that browser from adopting anchor colors in dark theme that differ from those in
// light theme: https://bugs.webkit.org/show_bug.cgi?id=209851
@mixin -dark-theme-anchor-colors {
    :where(a:link) {
        color: #9e9eff;
    }

    // Use of the :where pseudoclass function in the selector of this rule is desirable,
    // but if used, an apparent bug causes Safari to ignore the rule as invalid.
    a:visited {
        color: #d0adf0;
    }
}

@mixin -dark-theme-colors($theme, $offset-theme) {
    @include material.core-color($theme);

    // A small amount of duplicate color styles are emitted below with the include of the all-component-colors
    // mixin followed by other theme mixins of @angular/material. This is unavoidable in supporting @angular/material 15, 16, and 17.
    // Each of those major versions of @angular/material features slightly different component-specific color mixins, precluding an
    // include of each as needed to completely avoid duplication in emitted styles.
    @include material.all-component-colors($theme);
    @include material.toolbar-color($offset-theme);
    @include -dark-theme-anchor-colors;
}

// See https://github.com/angular/components/issues/26579.
@mixin -dense-form-field-bug-workaround($density) {
    @if $density < 0 {
        textfield.$minimum-height-for-filled-label: 10px;

        @include material.form-field-density(
            (
                density: math.max($density, -2),
            )
        );

        // stylelint-disable-next-line selector-class-pattern
        .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined)
            .mat-mdc-floating-label {
            display: inline;
        }

        // Fix for bug that impacts @angular/material@17. This CSS custom property should
        // assigned translateY(-8px) with both -1 and -2 density scales, but a bug sets it
        // none for the latter.
        :root {
            --mat-select-arrow-transform: translateY(-8px);
        }
    }
}

@mixin -light-theme-colors-and-core-styles($theme, $offset-theme) {
    // See https://github.com/angular/components/blob/main/guides/duplicate-theming-styles.md#disabling-duplication-warnings.
    // A small amount of duplicate density and typography styles are emitted below with the include of the all-component-themes
    // mixin followed by other theme mixins of @angular/material. This is unavoidable in supporting @angular/material 15, 16, and 17.
    // Each of those major versions of @angular/material features slightly different component-specific theme mixins, precluding an
    // include of each as needed to completely avoid duplication in emitted styles.
    material.$theme-ignore-duplication-warnings: true;

    @include material.core;
    @include material.core-theme($theme);
    @include material.all-component-themes($theme);
    @include material.toolbar-theme($offset-theme);
}
