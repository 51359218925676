:root {
  --iux-ng-mat-theme-light-color-primary-50: #e0eff3;
  --iux-ng-mat-theme-light-color-primary-100: #b3d7e2;
  --iux-ng-mat-theme-light-color-primary-200: #80bccf;
  --iux-ng-mat-theme-light-color-primary-300: #4da1bb;
  --iux-ng-mat-theme-light-color-primary-400: #268cad;
  --iux-ng-mat-theme-light-color-primary-500: #00789e;
  --iux-ng-mat-theme-light-color-primary-600: #007096;
  --iux-ng-mat-theme-light-color-primary-700: #00658c;
  --iux-ng-mat-theme-light-color-primary-800: #005b82;
  --iux-ng-mat-theme-light-color-primary-900: #004871;
  --iux-ng-mat-theme-light-color-primary-A100: #4fe4ff;
  --iux-ng-mat-theme-light-color-primary-A200: #12daff;
  --iux-ng-mat-theme-light-color-primary-A400: #00d1f7;
  --iux-ng-mat-theme-light-color-primary-A700: #00c4e8;
  --iux-ng-mat-theme-light-color-primary-contrast-50: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-primary-contrast-100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-primary-contrast-200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-primary-contrast-300: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-primary-contrast-400: white;
  --iux-ng-mat-theme-light-color-primary-contrast-500: white;
  --iux-ng-mat-theme-light-color-primary-contrast-600: white;
  --iux-ng-mat-theme-light-color-primary-contrast-700: white;
  --iux-ng-mat-theme-light-color-primary-contrast-800: white;
  --iux-ng-mat-theme-light-color-primary-contrast-900: white;
  --iux-ng-mat-theme-light-color-primary-contrast-A100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-primary-contrast-A200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-primary-contrast-A400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-primary-contrast-A700: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-primary-default: #00789e;
  --iux-ng-mat-theme-light-color-primary-lighter: #b3d7e2;
  --iux-ng-mat-theme-light-color-primary-darker: #00658c;
  --iux-ng-mat-theme-light-color-primary-text: #00789e;
  --iux-ng-mat-theme-light-color-primary-default-contrast: white;
  --iux-ng-mat-theme-light-color-primary-lighter-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-primary-darker-contrast: white;
  --iux-ng-mat-theme-light-color-primary-50-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-primary-100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-primary-200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-primary-300-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-primary-400-contrast: white;
  --iux-ng-mat-theme-light-color-primary-500-contrast: white;
  --iux-ng-mat-theme-light-color-primary-600-contrast: white;
  --iux-ng-mat-theme-light-color-primary-700-contrast: white;
  --iux-ng-mat-theme-light-color-primary-800-contrast: white;
  --iux-ng-mat-theme-light-color-primary-900-contrast: white;
  --iux-ng-mat-theme-light-color-primary-A100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-primary-A200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-primary-A400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-primary-A700-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-accent-50: #e5f7fa;
  --iux-ng-mat-theme-light-color-accent-100: #beebf3;
  --iux-ng-mat-theme-light-color-accent-200: #93deeb;
  --iux-ng-mat-theme-light-color-accent-300: #67d0e3;
  --iux-ng-mat-theme-light-color-accent-400: #47c6dd;
  --iux-ng-mat-theme-light-color-accent-500: #26bcd7;
  --iux-ng-mat-theme-light-color-accent-600: #22b6d3;
  --iux-ng-mat-theme-light-color-accent-700: #1cadcd;
  --iux-ng-mat-theme-light-color-accent-800: #17a5c7;
  --iux-ng-mat-theme-light-color-accent-900: #0e97be;
  --iux-ng-mat-theme-light-color-accent-A100: #aaf9ff;
  --iux-ng-mat-theme-light-color-accent-A200: #6df5ff;
  --iux-ng-mat-theme-light-color-accent-A400: #53f3ff;
  --iux-ng-mat-theme-light-color-accent-A700: #44f2ff;
  --iux-ng-mat-theme-light-color-accent-contrast-50: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-accent-contrast-100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-accent-contrast-200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-accent-contrast-300: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-accent-contrast-400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-accent-contrast-500: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-accent-contrast-600: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-accent-contrast-700: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-accent-contrast-800: white;
  --iux-ng-mat-theme-light-color-accent-contrast-900: white;
  --iux-ng-mat-theme-light-color-accent-contrast-A100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-accent-contrast-A200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-accent-contrast-A400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-accent-contrast-A700: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-accent-default: #26bcd7;
  --iux-ng-mat-theme-light-color-accent-lighter: #beebf3;
  --iux-ng-mat-theme-light-color-accent-darker: #1cadcd;
  --iux-ng-mat-theme-light-color-accent-text: #26bcd7;
  --iux-ng-mat-theme-light-color-accent-default-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-accent-lighter-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-accent-darker-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-accent-50-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-accent-100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-accent-200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-accent-300-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-accent-400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-accent-500-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-accent-600-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-accent-700-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-accent-800-contrast: white;
  --iux-ng-mat-theme-light-color-accent-900-contrast: white;
  --iux-ng-mat-theme-light-color-accent-A100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-accent-A200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-accent-A400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-accent-A700-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-warn-50: #f3e4e6;
  --iux-ng-mat-theme-light-color-warn-100: #e2bbc2;
  --iux-ng-mat-theme-light-color-warn-200: #cf8d99;
  --iux-ng-mat-theme-light-color-warn-300: #bb5f70;
  --iux-ng-mat-theme-light-color-warn-400: #ad3d51;
  --iux-ng-mat-theme-light-color-warn-500: #9e1b32;
  --iux-ng-mat-theme-light-color-warn-600: #96182d;
  --iux-ng-mat-theme-light-color-warn-700: #8c1426;
  --iux-ng-mat-theme-light-color-warn-800: #821020;
  --iux-ng-mat-theme-light-color-warn-900: #710914;
  --iux-ng-mat-theme-light-color-warn-A100: #ff5b8a;
  --iux-ng-mat-theme-light-color-warn-A200: #ff1e5e;
  --iux-ng-mat-theme-light-color-warn-A400: #ff044c;
  --iux-ng-mat-theme-light-color-warn-A700: #f40046;
  --iux-ng-mat-theme-light-color-warn-contrast-50: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-warn-contrast-100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-warn-contrast-200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-warn-contrast-300: white;
  --iux-ng-mat-theme-light-color-warn-contrast-400: white;
  --iux-ng-mat-theme-light-color-warn-contrast-500: white;
  --iux-ng-mat-theme-light-color-warn-contrast-600: white;
  --iux-ng-mat-theme-light-color-warn-contrast-700: white;
  --iux-ng-mat-theme-light-color-warn-contrast-800: white;
  --iux-ng-mat-theme-light-color-warn-contrast-900: white;
  --iux-ng-mat-theme-light-color-warn-contrast-A100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-warn-contrast-A200: white;
  --iux-ng-mat-theme-light-color-warn-contrast-A400: white;
  --iux-ng-mat-theme-light-color-warn-contrast-A700: white;
  --iux-ng-mat-theme-light-color-warn-default: #9e1b32;
  --iux-ng-mat-theme-light-color-warn-lighter: #e2bbc2;
  --iux-ng-mat-theme-light-color-warn-darker: #8c1426;
  --iux-ng-mat-theme-light-color-warn-text: #9e1b32;
  --iux-ng-mat-theme-light-color-warn-default-contrast: white;
  --iux-ng-mat-theme-light-color-warn-lighter-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-warn-darker-contrast: white;
  --iux-ng-mat-theme-light-color-warn-50-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-warn-100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-warn-200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-warn-300-contrast: white;
  --iux-ng-mat-theme-light-color-warn-400-contrast: white;
  --iux-ng-mat-theme-light-color-warn-500-contrast: white;
  --iux-ng-mat-theme-light-color-warn-600-contrast: white;
  --iux-ng-mat-theme-light-color-warn-700-contrast: white;
  --iux-ng-mat-theme-light-color-warn-800-contrast: white;
  --iux-ng-mat-theme-light-color-warn-900-contrast: white;
  --iux-ng-mat-theme-light-color-warn-A100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-warn-A200-contrast: white;
  --iux-ng-mat-theme-light-color-warn-A400-contrast: white;
  --iux-ng-mat-theme-light-color-warn-A700-contrast: white;
  --iux-ng-mat-theme-light-color-foreground-base: black;
  --iux-ng-mat-theme-light-color-foreground-divider: rgba(0, 0, 0, 0.12);
  --iux-ng-mat-theme-light-color-foreground-dividers: rgba(0, 0, 0, 0.12);
  --iux-ng-mat-theme-light-color-foreground-disabled: rgba(0, 0, 0, 0.38);
  --iux-ng-mat-theme-light-color-foreground-disabled-button: rgba(0, 0, 0, 0.26);
  --iux-ng-mat-theme-light-color-foreground-disabled-text: rgba(0, 0, 0, 0.38);
  --iux-ng-mat-theme-light-color-foreground-elevation: black;
  --iux-ng-mat-theme-light-color-foreground-hint-text: rgba(0, 0, 0, 0.38);
  --iux-ng-mat-theme-light-color-foreground-secondary-text: rgba(0, 0, 0, 0.54);
  --iux-ng-mat-theme-light-color-foreground-icon: rgba(0, 0, 0, 0.54);
  --iux-ng-mat-theme-light-color-foreground-icons: rgba(0, 0, 0, 0.54);
  --iux-ng-mat-theme-light-color-foreground-text: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-foreground-slider-min: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-color-foreground-slider-off: rgba(0, 0, 0, 0.26);
  --iux-ng-mat-theme-light-color-foreground-slider-off-active: rgba(0, 0, 0, 0.38);
  --iux-ng-mat-theme-light-color-background-status-bar: #e0e0e0;
  --iux-ng-mat-theme-light-color-background-app-bar: #f5f5f5;
  --iux-ng-mat-theme-light-color-background-background: #fafafa;
  --iux-ng-mat-theme-light-color-background-hover: rgba(0, 0, 0, 0.04);
  --iux-ng-mat-theme-light-color-background-card: white;
  --iux-ng-mat-theme-light-color-background-dialog: white;
  --iux-ng-mat-theme-light-color-background-disabled-button: rgba(0, 0, 0, 0.12);
  --iux-ng-mat-theme-light-color-background-raised-button: white;
  --iux-ng-mat-theme-light-color-background-focused-button: rgba(0, 0, 0, 0.12);
  --iux-ng-mat-theme-light-color-background-selected-button: #e0e0e0;
  --iux-ng-mat-theme-light-color-background-selected-disabled-button: #bdbdbd;
  --iux-ng-mat-theme-light-color-background-disabled-button-toggle: #eeeeee;
  --iux-ng-mat-theme-light-color-background-unselected-chip: #e0e0e0;
  --iux-ng-mat-theme-light-color-background-disabled-list-option: #eeeeee;
  --iux-ng-mat-theme-light-color-background-tooltip: #616161;
  --iux-ng-mat-theme-light-primary-50: #e0eff3;
  --iux-ng-mat-theme-light-primary-100: #b3d7e2;
  --iux-ng-mat-theme-light-primary-200: #80bccf;
  --iux-ng-mat-theme-light-primary-300: #4da1bb;
  --iux-ng-mat-theme-light-primary-400: #268cad;
  --iux-ng-mat-theme-light-primary-500: #00789e;
  --iux-ng-mat-theme-light-primary-600: #007096;
  --iux-ng-mat-theme-light-primary-700: #00658c;
  --iux-ng-mat-theme-light-primary-800: #005b82;
  --iux-ng-mat-theme-light-primary-900: #004871;
  --iux-ng-mat-theme-light-primary-A100: #4fe4ff;
  --iux-ng-mat-theme-light-primary-A200: #12daff;
  --iux-ng-mat-theme-light-primary-A400: #00d1f7;
  --iux-ng-mat-theme-light-primary-A700: #00c4e8;
  --iux-ng-mat-theme-light-primary-contrast-50: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-primary-contrast-100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-primary-contrast-200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-primary-contrast-300: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-primary-contrast-400: white;
  --iux-ng-mat-theme-light-primary-contrast-500: white;
  --iux-ng-mat-theme-light-primary-contrast-600: white;
  --iux-ng-mat-theme-light-primary-contrast-700: white;
  --iux-ng-mat-theme-light-primary-contrast-800: white;
  --iux-ng-mat-theme-light-primary-contrast-900: white;
  --iux-ng-mat-theme-light-primary-contrast-A100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-primary-contrast-A200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-primary-contrast-A400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-primary-contrast-A700: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-primary-default: #00789e;
  --iux-ng-mat-theme-light-primary-lighter: #b3d7e2;
  --iux-ng-mat-theme-light-primary-darker: #00658c;
  --iux-ng-mat-theme-light-primary-text: #00789e;
  --iux-ng-mat-theme-light-primary-default-contrast: white;
  --iux-ng-mat-theme-light-primary-lighter-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-primary-darker-contrast: white;
  --iux-ng-mat-theme-light-primary-50-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-primary-100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-primary-200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-primary-300-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-primary-400-contrast: white;
  --iux-ng-mat-theme-light-primary-500-contrast: white;
  --iux-ng-mat-theme-light-primary-600-contrast: white;
  --iux-ng-mat-theme-light-primary-700-contrast: white;
  --iux-ng-mat-theme-light-primary-800-contrast: white;
  --iux-ng-mat-theme-light-primary-900-contrast: white;
  --iux-ng-mat-theme-light-primary-A100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-primary-A200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-primary-A400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-primary-A700-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-accent-50: #e5f7fa;
  --iux-ng-mat-theme-light-accent-100: #beebf3;
  --iux-ng-mat-theme-light-accent-200: #93deeb;
  --iux-ng-mat-theme-light-accent-300: #67d0e3;
  --iux-ng-mat-theme-light-accent-400: #47c6dd;
  --iux-ng-mat-theme-light-accent-500: #26bcd7;
  --iux-ng-mat-theme-light-accent-600: #22b6d3;
  --iux-ng-mat-theme-light-accent-700: #1cadcd;
  --iux-ng-mat-theme-light-accent-800: #17a5c7;
  --iux-ng-mat-theme-light-accent-900: #0e97be;
  --iux-ng-mat-theme-light-accent-A100: #aaf9ff;
  --iux-ng-mat-theme-light-accent-A200: #6df5ff;
  --iux-ng-mat-theme-light-accent-A400: #53f3ff;
  --iux-ng-mat-theme-light-accent-A700: #44f2ff;
  --iux-ng-mat-theme-light-accent-contrast-50: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-accent-contrast-100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-accent-contrast-200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-accent-contrast-300: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-accent-contrast-400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-accent-contrast-500: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-accent-contrast-600: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-accent-contrast-700: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-accent-contrast-800: white;
  --iux-ng-mat-theme-light-accent-contrast-900: white;
  --iux-ng-mat-theme-light-accent-contrast-A100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-accent-contrast-A200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-accent-contrast-A400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-accent-contrast-A700: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-accent-default: #26bcd7;
  --iux-ng-mat-theme-light-accent-lighter: #beebf3;
  --iux-ng-mat-theme-light-accent-darker: #1cadcd;
  --iux-ng-mat-theme-light-accent-text: #26bcd7;
  --iux-ng-mat-theme-light-accent-default-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-accent-lighter-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-accent-darker-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-accent-50-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-accent-100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-accent-200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-accent-300-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-accent-400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-accent-500-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-accent-600-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-accent-700-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-accent-800-contrast: white;
  --iux-ng-mat-theme-light-accent-900-contrast: white;
  --iux-ng-mat-theme-light-accent-A100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-accent-A200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-accent-A400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-accent-A700-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-warn-50: #f3e4e6;
  --iux-ng-mat-theme-light-warn-100: #e2bbc2;
  --iux-ng-mat-theme-light-warn-200: #cf8d99;
  --iux-ng-mat-theme-light-warn-300: #bb5f70;
  --iux-ng-mat-theme-light-warn-400: #ad3d51;
  --iux-ng-mat-theme-light-warn-500: #9e1b32;
  --iux-ng-mat-theme-light-warn-600: #96182d;
  --iux-ng-mat-theme-light-warn-700: #8c1426;
  --iux-ng-mat-theme-light-warn-800: #821020;
  --iux-ng-mat-theme-light-warn-900: #710914;
  --iux-ng-mat-theme-light-warn-A100: #ff5b8a;
  --iux-ng-mat-theme-light-warn-A200: #ff1e5e;
  --iux-ng-mat-theme-light-warn-A400: #ff044c;
  --iux-ng-mat-theme-light-warn-A700: #f40046;
  --iux-ng-mat-theme-light-warn-contrast-50: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-warn-contrast-100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-warn-contrast-200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-warn-contrast-300: white;
  --iux-ng-mat-theme-light-warn-contrast-400: white;
  --iux-ng-mat-theme-light-warn-contrast-500: white;
  --iux-ng-mat-theme-light-warn-contrast-600: white;
  --iux-ng-mat-theme-light-warn-contrast-700: white;
  --iux-ng-mat-theme-light-warn-contrast-800: white;
  --iux-ng-mat-theme-light-warn-contrast-900: white;
  --iux-ng-mat-theme-light-warn-contrast-A100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-warn-contrast-A200: white;
  --iux-ng-mat-theme-light-warn-contrast-A400: white;
  --iux-ng-mat-theme-light-warn-contrast-A700: white;
  --iux-ng-mat-theme-light-warn-default: #9e1b32;
  --iux-ng-mat-theme-light-warn-lighter: #e2bbc2;
  --iux-ng-mat-theme-light-warn-darker: #8c1426;
  --iux-ng-mat-theme-light-warn-text: #9e1b32;
  --iux-ng-mat-theme-light-warn-default-contrast: white;
  --iux-ng-mat-theme-light-warn-lighter-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-warn-darker-contrast: white;
  --iux-ng-mat-theme-light-warn-50-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-warn-100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-warn-200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-warn-300-contrast: white;
  --iux-ng-mat-theme-light-warn-400-contrast: white;
  --iux-ng-mat-theme-light-warn-500-contrast: white;
  --iux-ng-mat-theme-light-warn-600-contrast: white;
  --iux-ng-mat-theme-light-warn-700-contrast: white;
  --iux-ng-mat-theme-light-warn-800-contrast: white;
  --iux-ng-mat-theme-light-warn-900-contrast: white;
  --iux-ng-mat-theme-light-warn-A100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-warn-A200-contrast: white;
  --iux-ng-mat-theme-light-warn-A400-contrast: white;
  --iux-ng-mat-theme-light-warn-A700-contrast: white;
  --iux-ng-mat-theme-light-foreground-base: black;
  --iux-ng-mat-theme-light-foreground-divider: rgba(0, 0, 0, 0.12);
  --iux-ng-mat-theme-light-foreground-dividers: rgba(0, 0, 0, 0.12);
  --iux-ng-mat-theme-light-foreground-disabled: rgba(0, 0, 0, 0.38);
  --iux-ng-mat-theme-light-foreground-disabled-button: rgba(0, 0, 0, 0.26);
  --iux-ng-mat-theme-light-foreground-disabled-text: rgba(0, 0, 0, 0.38);
  --iux-ng-mat-theme-light-foreground-elevation: black;
  --iux-ng-mat-theme-light-foreground-hint-text: rgba(0, 0, 0, 0.38);
  --iux-ng-mat-theme-light-foreground-secondary-text: rgba(0, 0, 0, 0.54);
  --iux-ng-mat-theme-light-foreground-icon: rgba(0, 0, 0, 0.54);
  --iux-ng-mat-theme-light-foreground-icons: rgba(0, 0, 0, 0.54);
  --iux-ng-mat-theme-light-foreground-text: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-foreground-slider-min: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-light-foreground-slider-off: rgba(0, 0, 0, 0.26);
  --iux-ng-mat-theme-light-foreground-slider-off-active: rgba(0, 0, 0, 0.38);
  --iux-ng-mat-theme-light-background-status-bar: #e0e0e0;
  --iux-ng-mat-theme-light-background-app-bar: #f5f5f5;
  --iux-ng-mat-theme-light-background-background: #fafafa;
  --iux-ng-mat-theme-light-background-hover: rgba(0, 0, 0, 0.04);
  --iux-ng-mat-theme-light-background-card: white;
  --iux-ng-mat-theme-light-background-dialog: white;
  --iux-ng-mat-theme-light-background-disabled-button: rgba(0, 0, 0, 0.12);
  --iux-ng-mat-theme-light-background-raised-button: white;
  --iux-ng-mat-theme-light-background-focused-button: rgba(0, 0, 0, 0.12);
  --iux-ng-mat-theme-light-background-selected-button: #e0e0e0;
  --iux-ng-mat-theme-light-background-selected-disabled-button: #bdbdbd;
  --iux-ng-mat-theme-light-background-disabled-button-toggle: #eeeeee;
  --iux-ng-mat-theme-light-background-unselected-chip: #e0e0e0;
  --iux-ng-mat-theme-light-background-disabled-list-option: #eeeeee;
  --iux-ng-mat-theme-light-background-tooltip: #616161;
}

:root {
  --iux-ng-mat-theme-offset-light-color-primary-50: #e0ebf0;
  --iux-ng-mat-theme-offset-light-color-primary-100: #b3cdda;
  --iux-ng-mat-theme-offset-light-color-primary-200: #80acc2;
  --iux-ng-mat-theme-offset-light-color-primary-300: #4d8ba9;
  --iux-ng-mat-theme-offset-light-color-primary-400: #267296;
  --iux-ng-mat-theme-offset-light-color-primary-500: #005984;
  --iux-ng-mat-theme-offset-light-color-primary-600: #00517c;
  --iux-ng-mat-theme-offset-light-color-primary-700: #004871;
  --iux-ng-mat-theme-offset-light-color-primary-800: #003e67;
  --iux-ng-mat-theme-offset-light-color-primary-900: #002e54;
  --iux-ng-mat-theme-offset-light-color-primary-A100: #33d2ff;
  --iux-ng-mat-theme-offset-light-color-primary-A200: #00bff5;
  --iux-ng-mat-theme-offset-light-color-primary-A400: #00abdb;
  --iux-ng-mat-theme-offset-light-color-primary-A700: #009fcc;
  --iux-ng-mat-theme-offset-light-color-primary-contrast-50: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-primary-contrast-100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-primary-contrast-200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-primary-contrast-300: white;
  --iux-ng-mat-theme-offset-light-color-primary-contrast-400: white;
  --iux-ng-mat-theme-offset-light-color-primary-contrast-500: white;
  --iux-ng-mat-theme-offset-light-color-primary-contrast-600: white;
  --iux-ng-mat-theme-offset-light-color-primary-contrast-700: white;
  --iux-ng-mat-theme-offset-light-color-primary-contrast-800: white;
  --iux-ng-mat-theme-offset-light-color-primary-contrast-900: white;
  --iux-ng-mat-theme-offset-light-color-primary-contrast-A100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-primary-contrast-A200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-primary-contrast-A400: white;
  --iux-ng-mat-theme-offset-light-color-primary-contrast-A700: white;
  --iux-ng-mat-theme-offset-light-color-primary-default: #005984;
  --iux-ng-mat-theme-offset-light-color-primary-lighter: #b3cdda;
  --iux-ng-mat-theme-offset-light-color-primary-darker: #004871;
  --iux-ng-mat-theme-offset-light-color-primary-text: #005984;
  --iux-ng-mat-theme-offset-light-color-primary-default-contrast: white;
  --iux-ng-mat-theme-offset-light-color-primary-lighter-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-primary-darker-contrast: white;
  --iux-ng-mat-theme-offset-light-color-primary-50-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-primary-100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-primary-200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-primary-300-contrast: white;
  --iux-ng-mat-theme-offset-light-color-primary-400-contrast: white;
  --iux-ng-mat-theme-offset-light-color-primary-500-contrast: white;
  --iux-ng-mat-theme-offset-light-color-primary-600-contrast: white;
  --iux-ng-mat-theme-offset-light-color-primary-700-contrast: white;
  --iux-ng-mat-theme-offset-light-color-primary-800-contrast: white;
  --iux-ng-mat-theme-offset-light-color-primary-900-contrast: white;
  --iux-ng-mat-theme-offset-light-color-primary-A100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-primary-A200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-primary-A400-contrast: white;
  --iux-ng-mat-theme-offset-light-color-primary-A700-contrast: white;
  --iux-ng-mat-theme-offset-light-color-accent-50: #e5f3f7;
  --iux-ng-mat-theme-offset-light-color-accent-100: #bee2eb;
  --iux-ng-mat-theme-offset-light-color-accent-200: #93cede;
  --iux-ng-mat-theme-offset-light-color-accent-300: #67bad1;
  --iux-ng-mat-theme-offset-light-color-accent-400: #47acc7;
  --iux-ng-mat-theme-offset-light-color-accent-500: #269dbd;
  --iux-ng-mat-theme-offset-light-color-accent-600: #2295b7;
  --iux-ng-mat-theme-offset-light-color-accent-700: #1c8bae;
  --iux-ng-mat-theme-offset-light-color-accent-800: #1781a6;
  --iux-ng-mat-theme-offset-light-color-accent-900: #0e7098;
  --iux-ng-mat-theme-offset-light-color-accent-A100: #84efff;
  --iux-ng-mat-theme-offset-light-color-accent-A200: #47e7ff;
  --iux-ng-mat-theme-offset-light-color-accent-A400: #2de4ff;
  --iux-ng-mat-theme-offset-light-color-accent-A700: #1ee2ff;
  --iux-ng-mat-theme-offset-light-color-accent-contrast-50: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-accent-contrast-100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-accent-contrast-200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-accent-contrast-300: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-accent-contrast-400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-accent-contrast-500: white;
  --iux-ng-mat-theme-offset-light-color-accent-contrast-600: white;
  --iux-ng-mat-theme-offset-light-color-accent-contrast-700: white;
  --iux-ng-mat-theme-offset-light-color-accent-contrast-800: white;
  --iux-ng-mat-theme-offset-light-color-accent-contrast-900: white;
  --iux-ng-mat-theme-offset-light-color-accent-contrast-A100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-accent-contrast-A200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-accent-contrast-A400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-accent-contrast-A700: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-accent-default: #269dbd;
  --iux-ng-mat-theme-offset-light-color-accent-lighter: #bee2eb;
  --iux-ng-mat-theme-offset-light-color-accent-darker: #1c8bae;
  --iux-ng-mat-theme-offset-light-color-accent-text: #269dbd;
  --iux-ng-mat-theme-offset-light-color-accent-default-contrast: white;
  --iux-ng-mat-theme-offset-light-color-accent-lighter-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-accent-darker-contrast: white;
  --iux-ng-mat-theme-offset-light-color-accent-50-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-accent-100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-accent-200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-accent-300-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-accent-400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-accent-500-contrast: white;
  --iux-ng-mat-theme-offset-light-color-accent-600-contrast: white;
  --iux-ng-mat-theme-offset-light-color-accent-700-contrast: white;
  --iux-ng-mat-theme-offset-light-color-accent-800-contrast: white;
  --iux-ng-mat-theme-offset-light-color-accent-900-contrast: white;
  --iux-ng-mat-theme-offset-light-color-accent-A100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-accent-A200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-accent-A400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-accent-A700-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-warn-50: #f3e0e3;
  --iux-ng-mat-theme-offset-light-color-warn-100: #e2b3ba;
  --iux-ng-mat-theme-offset-light-color-warn-200: #cf808c;
  --iux-ng-mat-theme-offset-light-color-warn-300: #bb4d5d;
  --iux-ng-mat-theme-offset-light-color-warn-400: #ad263b;
  --iux-ng-mat-theme-offset-light-color-warn-500: #9e0018;
  --iux-ng-mat-theme-offset-light-color-warn-600: #960015;
  --iux-ng-mat-theme-offset-light-color-warn-700: #8c0011;
  --iux-ng-mat-theme-offset-light-color-warn-800: #82000e;
  --iux-ng-mat-theme-offset-light-color-warn-900: #710008;
  --iux-ng-mat-theme-offset-light-color-warn-A100: #ff5281;
  --iux-ng-mat-theme-offset-light-color-warn-A200: #ff1554;
  --iux-ng-mat-theme-offset-light-color-warn-A400: #fa0044;
  --iux-ng-mat-theme-offset-light-color-warn-A700: #eb0040;
  --iux-ng-mat-theme-offset-light-color-warn-contrast-50: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-warn-contrast-100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-warn-contrast-200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-warn-contrast-300: white;
  --iux-ng-mat-theme-offset-light-color-warn-contrast-400: white;
  --iux-ng-mat-theme-offset-light-color-warn-contrast-500: white;
  --iux-ng-mat-theme-offset-light-color-warn-contrast-600: white;
  --iux-ng-mat-theme-offset-light-color-warn-contrast-700: white;
  --iux-ng-mat-theme-offset-light-color-warn-contrast-800: white;
  --iux-ng-mat-theme-offset-light-color-warn-contrast-900: white;
  --iux-ng-mat-theme-offset-light-color-warn-contrast-A100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-warn-contrast-A200: white;
  --iux-ng-mat-theme-offset-light-color-warn-contrast-A400: white;
  --iux-ng-mat-theme-offset-light-color-warn-contrast-A700: white;
  --iux-ng-mat-theme-offset-light-color-warn-default: #9e0018;
  --iux-ng-mat-theme-offset-light-color-warn-lighter: #e2b3ba;
  --iux-ng-mat-theme-offset-light-color-warn-darker: #8c0011;
  --iux-ng-mat-theme-offset-light-color-warn-text: #9e0018;
  --iux-ng-mat-theme-offset-light-color-warn-default-contrast: white;
  --iux-ng-mat-theme-offset-light-color-warn-lighter-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-warn-darker-contrast: white;
  --iux-ng-mat-theme-offset-light-color-warn-50-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-warn-100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-warn-200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-warn-300-contrast: white;
  --iux-ng-mat-theme-offset-light-color-warn-400-contrast: white;
  --iux-ng-mat-theme-offset-light-color-warn-500-contrast: white;
  --iux-ng-mat-theme-offset-light-color-warn-600-contrast: white;
  --iux-ng-mat-theme-offset-light-color-warn-700-contrast: white;
  --iux-ng-mat-theme-offset-light-color-warn-800-contrast: white;
  --iux-ng-mat-theme-offset-light-color-warn-900-contrast: white;
  --iux-ng-mat-theme-offset-light-color-warn-A100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-warn-A200-contrast: white;
  --iux-ng-mat-theme-offset-light-color-warn-A400-contrast: white;
  --iux-ng-mat-theme-offset-light-color-warn-A700-contrast: white;
  --iux-ng-mat-theme-offset-light-color-foreground-base: black;
  --iux-ng-mat-theme-offset-light-color-foreground-divider: rgba(0, 0, 0, 0.12);
  --iux-ng-mat-theme-offset-light-color-foreground-dividers: rgba(0, 0, 0, 0.12);
  --iux-ng-mat-theme-offset-light-color-foreground-disabled: rgba(0, 0, 0, 0.38);
  --iux-ng-mat-theme-offset-light-color-foreground-disabled-button: rgba(0, 0, 0, 0.26);
  --iux-ng-mat-theme-offset-light-color-foreground-disabled-text: rgba(0, 0, 0, 0.38);
  --iux-ng-mat-theme-offset-light-color-foreground-elevation: black;
  --iux-ng-mat-theme-offset-light-color-foreground-hint-text: rgba(0, 0, 0, 0.38);
  --iux-ng-mat-theme-offset-light-color-foreground-secondary-text: rgba(0, 0, 0, 0.54);
  --iux-ng-mat-theme-offset-light-color-foreground-icon: rgba(0, 0, 0, 0.54);
  --iux-ng-mat-theme-offset-light-color-foreground-icons: rgba(0, 0, 0, 0.54);
  --iux-ng-mat-theme-offset-light-color-foreground-text: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-foreground-slider-min: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-color-foreground-slider-off: rgba(0, 0, 0, 0.26);
  --iux-ng-mat-theme-offset-light-color-foreground-slider-off-active: rgba(0, 0, 0, 0.38);
  --iux-ng-mat-theme-offset-light-color-background-status-bar: #e0e0e0;
  --iux-ng-mat-theme-offset-light-color-background-app-bar: #f5f5f5;
  --iux-ng-mat-theme-offset-light-color-background-background: #fafafa;
  --iux-ng-mat-theme-offset-light-color-background-hover: rgba(0, 0, 0, 0.04);
  --iux-ng-mat-theme-offset-light-color-background-card: white;
  --iux-ng-mat-theme-offset-light-color-background-dialog: white;
  --iux-ng-mat-theme-offset-light-color-background-disabled-button: rgba(0, 0, 0, 0.12);
  --iux-ng-mat-theme-offset-light-color-background-raised-button: white;
  --iux-ng-mat-theme-offset-light-color-background-focused-button: rgba(0, 0, 0, 0.12);
  --iux-ng-mat-theme-offset-light-color-background-selected-button: #e0e0e0;
  --iux-ng-mat-theme-offset-light-color-background-selected-disabled-button: #bdbdbd;
  --iux-ng-mat-theme-offset-light-color-background-disabled-button-toggle: #eeeeee;
  --iux-ng-mat-theme-offset-light-color-background-unselected-chip: #e0e0e0;
  --iux-ng-mat-theme-offset-light-color-background-disabled-list-option: #eeeeee;
  --iux-ng-mat-theme-offset-light-color-background-tooltip: #616161;
  --iux-ng-mat-theme-offset-light-primary-50: #e0ebf0;
  --iux-ng-mat-theme-offset-light-primary-100: #b3cdda;
  --iux-ng-mat-theme-offset-light-primary-200: #80acc2;
  --iux-ng-mat-theme-offset-light-primary-300: #4d8ba9;
  --iux-ng-mat-theme-offset-light-primary-400: #267296;
  --iux-ng-mat-theme-offset-light-primary-500: #005984;
  --iux-ng-mat-theme-offset-light-primary-600: #00517c;
  --iux-ng-mat-theme-offset-light-primary-700: #004871;
  --iux-ng-mat-theme-offset-light-primary-800: #003e67;
  --iux-ng-mat-theme-offset-light-primary-900: #002e54;
  --iux-ng-mat-theme-offset-light-primary-A100: #33d2ff;
  --iux-ng-mat-theme-offset-light-primary-A200: #00bff5;
  --iux-ng-mat-theme-offset-light-primary-A400: #00abdb;
  --iux-ng-mat-theme-offset-light-primary-A700: #009fcc;
  --iux-ng-mat-theme-offset-light-primary-contrast-50: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-primary-contrast-100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-primary-contrast-200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-primary-contrast-300: white;
  --iux-ng-mat-theme-offset-light-primary-contrast-400: white;
  --iux-ng-mat-theme-offset-light-primary-contrast-500: white;
  --iux-ng-mat-theme-offset-light-primary-contrast-600: white;
  --iux-ng-mat-theme-offset-light-primary-contrast-700: white;
  --iux-ng-mat-theme-offset-light-primary-contrast-800: white;
  --iux-ng-mat-theme-offset-light-primary-contrast-900: white;
  --iux-ng-mat-theme-offset-light-primary-contrast-A100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-primary-contrast-A200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-primary-contrast-A400: white;
  --iux-ng-mat-theme-offset-light-primary-contrast-A700: white;
  --iux-ng-mat-theme-offset-light-primary-default: #005984;
  --iux-ng-mat-theme-offset-light-primary-lighter: #b3cdda;
  --iux-ng-mat-theme-offset-light-primary-darker: #004871;
  --iux-ng-mat-theme-offset-light-primary-text: #005984;
  --iux-ng-mat-theme-offset-light-primary-default-contrast: white;
  --iux-ng-mat-theme-offset-light-primary-lighter-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-primary-darker-contrast: white;
  --iux-ng-mat-theme-offset-light-primary-50-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-primary-100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-primary-200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-primary-300-contrast: white;
  --iux-ng-mat-theme-offset-light-primary-400-contrast: white;
  --iux-ng-mat-theme-offset-light-primary-500-contrast: white;
  --iux-ng-mat-theme-offset-light-primary-600-contrast: white;
  --iux-ng-mat-theme-offset-light-primary-700-contrast: white;
  --iux-ng-mat-theme-offset-light-primary-800-contrast: white;
  --iux-ng-mat-theme-offset-light-primary-900-contrast: white;
  --iux-ng-mat-theme-offset-light-primary-A100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-primary-A200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-primary-A400-contrast: white;
  --iux-ng-mat-theme-offset-light-primary-A700-contrast: white;
  --iux-ng-mat-theme-offset-light-accent-50: #e5f3f7;
  --iux-ng-mat-theme-offset-light-accent-100: #bee2eb;
  --iux-ng-mat-theme-offset-light-accent-200: #93cede;
  --iux-ng-mat-theme-offset-light-accent-300: #67bad1;
  --iux-ng-mat-theme-offset-light-accent-400: #47acc7;
  --iux-ng-mat-theme-offset-light-accent-500: #269dbd;
  --iux-ng-mat-theme-offset-light-accent-600: #2295b7;
  --iux-ng-mat-theme-offset-light-accent-700: #1c8bae;
  --iux-ng-mat-theme-offset-light-accent-800: #1781a6;
  --iux-ng-mat-theme-offset-light-accent-900: #0e7098;
  --iux-ng-mat-theme-offset-light-accent-A100: #84efff;
  --iux-ng-mat-theme-offset-light-accent-A200: #47e7ff;
  --iux-ng-mat-theme-offset-light-accent-A400: #2de4ff;
  --iux-ng-mat-theme-offset-light-accent-A700: #1ee2ff;
  --iux-ng-mat-theme-offset-light-accent-contrast-50: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-accent-contrast-100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-accent-contrast-200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-accent-contrast-300: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-accent-contrast-400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-accent-contrast-500: white;
  --iux-ng-mat-theme-offset-light-accent-contrast-600: white;
  --iux-ng-mat-theme-offset-light-accent-contrast-700: white;
  --iux-ng-mat-theme-offset-light-accent-contrast-800: white;
  --iux-ng-mat-theme-offset-light-accent-contrast-900: white;
  --iux-ng-mat-theme-offset-light-accent-contrast-A100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-accent-contrast-A200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-accent-contrast-A400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-accent-contrast-A700: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-accent-default: #269dbd;
  --iux-ng-mat-theme-offset-light-accent-lighter: #bee2eb;
  --iux-ng-mat-theme-offset-light-accent-darker: #1c8bae;
  --iux-ng-mat-theme-offset-light-accent-text: #269dbd;
  --iux-ng-mat-theme-offset-light-accent-default-contrast: white;
  --iux-ng-mat-theme-offset-light-accent-lighter-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-accent-darker-contrast: white;
  --iux-ng-mat-theme-offset-light-accent-50-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-accent-100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-accent-200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-accent-300-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-accent-400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-accent-500-contrast: white;
  --iux-ng-mat-theme-offset-light-accent-600-contrast: white;
  --iux-ng-mat-theme-offset-light-accent-700-contrast: white;
  --iux-ng-mat-theme-offset-light-accent-800-contrast: white;
  --iux-ng-mat-theme-offset-light-accent-900-contrast: white;
  --iux-ng-mat-theme-offset-light-accent-A100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-accent-A200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-accent-A400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-accent-A700-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-warn-50: #f3e0e3;
  --iux-ng-mat-theme-offset-light-warn-100: #e2b3ba;
  --iux-ng-mat-theme-offset-light-warn-200: #cf808c;
  --iux-ng-mat-theme-offset-light-warn-300: #bb4d5d;
  --iux-ng-mat-theme-offset-light-warn-400: #ad263b;
  --iux-ng-mat-theme-offset-light-warn-500: #9e0018;
  --iux-ng-mat-theme-offset-light-warn-600: #960015;
  --iux-ng-mat-theme-offset-light-warn-700: #8c0011;
  --iux-ng-mat-theme-offset-light-warn-800: #82000e;
  --iux-ng-mat-theme-offset-light-warn-900: #710008;
  --iux-ng-mat-theme-offset-light-warn-A100: #ff5281;
  --iux-ng-mat-theme-offset-light-warn-A200: #ff1554;
  --iux-ng-mat-theme-offset-light-warn-A400: #fa0044;
  --iux-ng-mat-theme-offset-light-warn-A700: #eb0040;
  --iux-ng-mat-theme-offset-light-warn-contrast-50: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-warn-contrast-100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-warn-contrast-200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-warn-contrast-300: white;
  --iux-ng-mat-theme-offset-light-warn-contrast-400: white;
  --iux-ng-mat-theme-offset-light-warn-contrast-500: white;
  --iux-ng-mat-theme-offset-light-warn-contrast-600: white;
  --iux-ng-mat-theme-offset-light-warn-contrast-700: white;
  --iux-ng-mat-theme-offset-light-warn-contrast-800: white;
  --iux-ng-mat-theme-offset-light-warn-contrast-900: white;
  --iux-ng-mat-theme-offset-light-warn-contrast-A100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-warn-contrast-A200: white;
  --iux-ng-mat-theme-offset-light-warn-contrast-A400: white;
  --iux-ng-mat-theme-offset-light-warn-contrast-A700: white;
  --iux-ng-mat-theme-offset-light-warn-default: #9e0018;
  --iux-ng-mat-theme-offset-light-warn-lighter: #e2b3ba;
  --iux-ng-mat-theme-offset-light-warn-darker: #8c0011;
  --iux-ng-mat-theme-offset-light-warn-text: #9e0018;
  --iux-ng-mat-theme-offset-light-warn-default-contrast: white;
  --iux-ng-mat-theme-offset-light-warn-lighter-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-warn-darker-contrast: white;
  --iux-ng-mat-theme-offset-light-warn-50-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-warn-100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-warn-200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-warn-300-contrast: white;
  --iux-ng-mat-theme-offset-light-warn-400-contrast: white;
  --iux-ng-mat-theme-offset-light-warn-500-contrast: white;
  --iux-ng-mat-theme-offset-light-warn-600-contrast: white;
  --iux-ng-mat-theme-offset-light-warn-700-contrast: white;
  --iux-ng-mat-theme-offset-light-warn-800-contrast: white;
  --iux-ng-mat-theme-offset-light-warn-900-contrast: white;
  --iux-ng-mat-theme-offset-light-warn-A100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-warn-A200-contrast: white;
  --iux-ng-mat-theme-offset-light-warn-A400-contrast: white;
  --iux-ng-mat-theme-offset-light-warn-A700-contrast: white;
  --iux-ng-mat-theme-offset-light-foreground-base: black;
  --iux-ng-mat-theme-offset-light-foreground-divider: rgba(0, 0, 0, 0.12);
  --iux-ng-mat-theme-offset-light-foreground-dividers: rgba(0, 0, 0, 0.12);
  --iux-ng-mat-theme-offset-light-foreground-disabled: rgba(0, 0, 0, 0.38);
  --iux-ng-mat-theme-offset-light-foreground-disabled-button: rgba(0, 0, 0, 0.26);
  --iux-ng-mat-theme-offset-light-foreground-disabled-text: rgba(0, 0, 0, 0.38);
  --iux-ng-mat-theme-offset-light-foreground-elevation: black;
  --iux-ng-mat-theme-offset-light-foreground-hint-text: rgba(0, 0, 0, 0.38);
  --iux-ng-mat-theme-offset-light-foreground-secondary-text: rgba(0, 0, 0, 0.54);
  --iux-ng-mat-theme-offset-light-foreground-icon: rgba(0, 0, 0, 0.54);
  --iux-ng-mat-theme-offset-light-foreground-icons: rgba(0, 0, 0, 0.54);
  --iux-ng-mat-theme-offset-light-foreground-text: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-foreground-slider-min: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offset-light-foreground-slider-off: rgba(0, 0, 0, 0.26);
  --iux-ng-mat-theme-offset-light-foreground-slider-off-active: rgba(0, 0, 0, 0.38);
  --iux-ng-mat-theme-offset-light-background-status-bar: #e0e0e0;
  --iux-ng-mat-theme-offset-light-background-app-bar: #f5f5f5;
  --iux-ng-mat-theme-offset-light-background-background: #fafafa;
  --iux-ng-mat-theme-offset-light-background-hover: rgba(0, 0, 0, 0.04);
  --iux-ng-mat-theme-offset-light-background-card: white;
  --iux-ng-mat-theme-offset-light-background-dialog: white;
  --iux-ng-mat-theme-offset-light-background-disabled-button: rgba(0, 0, 0, 0.12);
  --iux-ng-mat-theme-offset-light-background-raised-button: white;
  --iux-ng-mat-theme-offset-light-background-focused-button: rgba(0, 0, 0, 0.12);
  --iux-ng-mat-theme-offset-light-background-selected-button: #e0e0e0;
  --iux-ng-mat-theme-offset-light-background-selected-disabled-button: #bdbdbd;
  --iux-ng-mat-theme-offset-light-background-disabled-button-toggle: #eeeeee;
  --iux-ng-mat-theme-offset-light-background-unselected-chip: #e0e0e0;
  --iux-ng-mat-theme-offset-light-background-disabled-list-option: #eeeeee;
  --iux-ng-mat-theme-offset-light-background-tooltip: #616161;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, transparent);
  color: var(--mat-app-text-color, inherit);
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #00789e;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #26bcd7;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #9e1b32;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #00789e;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #00789e;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: #26bcd7;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #26bcd7;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #26bcd7;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #26bcd7;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #9e1b32;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #9e1b32;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mat-option-label-text-font: Roboto;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 16px;
  --mat-option-label-text-tracking: 0.03125em;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Roboto;
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 16px;
  --mat-optgroup-label-text-tracking: 0.03125em;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #00789e;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #26bcd7;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #9e1b32;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #00789e;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #00789e;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: #26bcd7;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #26bcd7;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #26bcd7;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #26bcd7;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #9e1b32;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #9e1b32;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mat-option-label-text-font: Roboto;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 16px;
  --mat-option-label-text-tracking: 0.03125em;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Roboto;
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 16px;
  --mat-optgroup-label-text-tracking: 0.03125em;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}

html {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-card-title-text-font: Roboto;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: 0.0125em;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: Roboto;
  --mat-card-subtitle-text-line-height: 22px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: 0.0071428571em;
  --mat-card-subtitle-text-weight: 500;
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #00789e;
  --mdc-linear-progress-track-color: rgba(0, 120, 158, 0.25);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #26bcd7;
  --mdc-linear-progress-track-color: rgba(38, 188, 215, 0.25);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #9e1b32;
  --mdc-linear-progress-track-color: rgba(158, 27, 50, 0.25);
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Roboto;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}

html {
  --mdc-filled-text-field-caret-color: #00789e;
  --mdc-filled-text-field-focus-active-indicator-color: #00789e;
  --mdc-filled-text-field-focus-label-text-color: rgba(0, 120, 158, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #9e1b32;
  --mdc-filled-text-field-error-focus-label-text-color: #9e1b32;
  --mdc-filled-text-field-error-label-text-color: #9e1b32;
  --mdc-filled-text-field-error-caret-color: #9e1b32;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #9e1b32;
  --mdc-filled-text-field-error-focus-active-indicator-color: #9e1b32;
  --mdc-filled-text-field-error-hover-active-indicator-color: #9e1b32;
  --mdc-outlined-text-field-caret-color: #00789e;
  --mdc-outlined-text-field-focus-outline-color: #00789e;
  --mdc-outlined-text-field-focus-label-text-color: rgba(0, 120, 158, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #9e1b32;
  --mdc-outlined-text-field-error-focus-label-text-color: #9e1b32;
  --mdc-outlined-text-field-error-label-text-color: #9e1b32;
  --mdc-outlined-text-field-error-hover-label-text-color: #9e1b32;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #9e1b32;
  --mdc-outlined-text-field-error-hover-outline-color: #9e1b32;
  --mdc-outlined-text-field-error-outline-color: #9e1b32;
  --mat-form-field-focus-select-arrow-color: rgba(0, 120, 158, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #9e1b32;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #26bcd7;
  --mdc-filled-text-field-focus-active-indicator-color: #26bcd7;
  --mdc-filled-text-field-focus-label-text-color: rgba(38, 188, 215, 0.87);
  --mdc-outlined-text-field-caret-color: #26bcd7;
  --mdc-outlined-text-field-focus-outline-color: #26bcd7;
  --mdc-outlined-text-field-focus-label-text-color: rgba(38, 188, 215, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(38, 188, 215, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #9e1b32;
  --mdc-filled-text-field-focus-active-indicator-color: #9e1b32;
  --mdc-filled-text-field-focus-label-text-color: rgba(158, 27, 50, 0.87);
  --mdc-outlined-text-field-caret-color: #9e1b32;
  --mdc-outlined-text-field-focus-outline-color: #9e1b32;
  --mdc-outlined-text-field-focus-label-text-color: rgba(158, 27, 50, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(158, 27, 50, 0.87);
}

html {
  --mat-form-field-container-height: 48px;
  --mat-form-field-filled-label-display: none;
  --mat-form-field-container-vertical-padding: 12px;
  --mat-form-field-filled-with-label-container-padding-top: 12px;
  --mat-form-field-filled-with-label-container-padding-bottom: 12px;
}

html {
  --mdc-filled-text-field-label-text-font: Roboto;
  --mdc-filled-text-field-label-text-size: 16px;
  --mdc-filled-text-field-label-text-tracking: 0.03125em;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: Roboto;
  --mdc-outlined-text-field-label-text-size: 16px;
  --mdc-outlined-text-field-label-text-tracking: 0.03125em;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: Roboto;
  --mat-form-field-container-text-line-height: 24px;
  --mat-form-field-container-text-size: 16px;
  --mat-form-field-container-text-tracking: 0.03125em;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 16px;
  --mat-form-field-subscript-text-font: Roboto;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: 0.0333333333em;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(0, 120, 158, 0.87);
  --mat-select-invalid-arrow-color: rgba(158, 27, 50, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(38, 188, 215, 0.87);
  --mat-select-invalid-arrow-color: rgba(158, 27, 50, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(158, 27, 50, 0.87);
  --mat-select-invalid-arrow-color: rgba(158, 27, 50, 0.87);
}

html {
  --mat-select-arrow-transform: none;
}

html {
  --mat-select-trigger-text-font: Roboto;
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 16px;
  --mat-select-trigger-text-tracking: 0.03125em;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-autocomplete-background-color: white;
}

html {
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
}

html {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

html {
  --mdc-dialog-subhead-font: Roboto;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: Roboto;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.03125em;
}

.mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #00789e;
  --mdc-chip-elevated-selected-container-color: #00789e;
  --mdc-chip-elevated-disabled-container-color: #00789e;
  --mdc-chip-flat-disabled-selected-container-color: #00789e;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-elevated-container-color: #26bcd7;
  --mdc-chip-elevated-selected-container-color: #26bcd7;
  --mdc-chip-elevated-disabled-container-color: #26bcd7;
  --mdc-chip-flat-disabled-selected-container-color: #26bcd7;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-selected-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-disabled-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-selected-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mat-chip-selected-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mat-chip-selected-trailing-icon-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #9e1b32;
  --mdc-chip-elevated-selected-container-color: #9e1b32;
  --mdc-chip-elevated-disabled-container-color: #9e1b32;
  --mdc-chip-flat-disabled-selected-container-color: #9e1b32;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 24px;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Roboto;
  --mdc-chip-label-text-line-height: 20px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: 0.0178571429em;
  --mdc-chip-label-text-weight: 400;
}

html {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}

html {
  --mdc-switch-selected-focus-state-layer-color: #007096;
  --mdc-switch-selected-handle-color: #007096;
  --mdc-switch-selected-hover-state-layer-color: #007096;
  --mdc-switch-selected-pressed-state-layer-color: #007096;
  --mdc-switch-selected-focus-handle-color: #004871;
  --mdc-switch-selected-hover-handle-color: #004871;
  --mdc-switch-selected-pressed-handle-color: #004871;
  --mdc-switch-selected-focus-track-color: #4da1bb;
  --mdc-switch-selected-hover-track-color: #4da1bb;
  --mdc-switch-selected-pressed-track-color: #4da1bb;
  --mdc-switch-selected-track-color: #4da1bb;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #22b6d3;
  --mdc-switch-selected-handle-color: #22b6d3;
  --mdc-switch-selected-hover-state-layer-color: #22b6d3;
  --mdc-switch-selected-pressed-state-layer-color: #22b6d3;
  --mdc-switch-selected-focus-handle-color: #0e97be;
  --mdc-switch-selected-hover-handle-color: #0e97be;
  --mdc-switch-selected-pressed-handle-color: #0e97be;
  --mdc-switch-selected-focus-track-color: #67d0e3;
  --mdc-switch-selected-hover-track-color: #67d0e3;
  --mdc-switch-selected-pressed-track-color: #67d0e3;
  --mdc-switch-selected-track-color: #67d0e3;
}
html .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #96182d;
  --mdc-switch-selected-handle-color: #96182d;
  --mdc-switch-selected-hover-state-layer-color: #96182d;
  --mdc-switch-selected-pressed-state-layer-color: #96182d;
  --mdc-switch-selected-focus-handle-color: #710914;
  --mdc-switch-selected-hover-handle-color: #710914;
  --mdc-switch-selected-pressed-handle-color: #710914;
  --mdc-switch-selected-focus-track-color: #bb5f70;
  --mdc-switch-selected-hover-track-color: #bb5f70;
  --mdc-switch-selected-pressed-track-color: #bb5f70;
  --mdc-switch-selected-track-color: #bb5f70;
}

html {
  --mdc-switch-state-layer-size: 32px;
}

html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-font: Roboto;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #00789e;
  --mdc-radio-selected-hover-icon-color: #00789e;
  --mdc-radio-selected-icon-color: #00789e;
  --mdc-radio-selected-pressed-icon-color: #00789e;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #00789e;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #26bcd7;
  --mdc-radio-selected-hover-icon-color: #26bcd7;
  --mdc-radio-selected-icon-color: #26bcd7;
  --mdc-radio-selected-pressed-icon-color: #26bcd7;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #26bcd7;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #9e1b32;
  --mdc-radio-selected-hover-icon-color: #9e1b32;
  --mdc-radio-selected-icon-color: #9e1b32;
  --mdc-radio-selected-pressed-icon-color: #9e1b32;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #9e1b32;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-radio-state-layer-size: 32px;
  --mat-radio-touch-target-display: none;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-font: Roboto;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
}

html {
  --mdc-slider-handle-color: #00789e;
  --mdc-slider-focus-handle-color: #00789e;
  --mdc-slider-hover-handle-color: #00789e;
  --mdc-slider-active-track-color: #00789e;
  --mdc-slider-inactive-track-color: #00789e;
  --mdc-slider-with-tick-marks-inactive-container-color: #00789e;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: #00789e;
  --mat-slider-hover-state-layer-color: rgba(0, 120, 158, 0.05);
  --mat-slider-focus-state-layer-color: rgba(0, 120, 158, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
}
html .mat-accent {
  --mat-slider-ripple-color: #26bcd7;
  --mat-slider-hover-state-layer-color: rgba(38, 188, 215, 0.05);
  --mat-slider-focus-state-layer-color: rgba(38, 188, 215, 0.2);
  --mdc-slider-handle-color: #26bcd7;
  --mdc-slider-focus-handle-color: #26bcd7;
  --mdc-slider-hover-handle-color: #26bcd7;
  --mdc-slider-active-track-color: #26bcd7;
  --mdc-slider-inactive-track-color: #26bcd7;
  --mdc-slider-with-tick-marks-inactive-container-color: #26bcd7;
  --mdc-slider-with-tick-marks-active-container-color: rgba(0, 0, 0, 0.87);
}
html .mat-warn {
  --mat-slider-ripple-color: #9e1b32;
  --mat-slider-hover-state-layer-color: rgba(158, 27, 50, 0.05);
  --mat-slider-focus-state-layer-color: rgba(158, 27, 50, 0.2);
  --mdc-slider-handle-color: #9e1b32;
  --mdc-slider-focus-handle-color: #9e1b32;
  --mdc-slider-hover-handle-color: #9e1b32;
  --mdc-slider-active-track-color: #9e1b32;
  --mdc-slider-inactive-track-color: #9e1b32;
  --mdc-slider-with-tick-marks-inactive-container-color: #9e1b32;
  --mdc-slider-with-tick-marks-active-container-color: white;
}

html {
  --mdc-slider-label-label-text-font: Roboto;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: 0.0071428571em;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-menu-item-label-text-font: Roboto;
  --mat-menu-item-label-text-size: 16px;
  --mat-menu-item-label-text-tracking: 0.03125em;
  --mat-menu-item-label-text-line-height: 24px;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 0;
}

html {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #00789e;
  --mdc-radio-selected-hover-icon-color: #00789e;
  --mdc-radio-selected-icon-color: #00789e;
  --mdc-radio-selected-pressed-icon-color: #00789e;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #26bcd7;
  --mdc-radio-selected-hover-icon-color: #26bcd7;
  --mdc-radio-selected-icon-color: #26bcd7;
  --mdc-radio-selected-pressed-icon-color: #26bcd7;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #9e1b32;
  --mdc-radio-selected-hover-icon-color: #9e1b32;
  --mdc-radio-selected-icon-color: #9e1b32;
  --mdc-radio-selected-pressed-icon-color: #9e1b32;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #00789e;
  --mdc-checkbox-selected-hover-icon-color: #00789e;
  --mdc-checkbox-selected-icon-color: #00789e;
  --mdc-checkbox-selected-pressed-icon-color: #00789e;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #00789e;
  --mdc-checkbox-selected-hover-state-layer-color: #00789e;
  --mdc-checkbox-selected-pressed-state-layer-color: #00789e;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #26bcd7;
  --mdc-checkbox-selected-hover-icon-color: #26bcd7;
  --mdc-checkbox-selected-icon-color: #26bcd7;
  --mdc-checkbox-selected-pressed-icon-color: #26bcd7;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #26bcd7;
  --mdc-checkbox-selected-hover-state-layer-color: #26bcd7;
  --mdc-checkbox-selected-pressed-state-layer-color: #26bcd7;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #9e1b32;
  --mdc-checkbox-selected-hover-icon-color: #9e1b32;
  --mdc-checkbox-selected-icon-color: #9e1b32;
  --mdc-checkbox-selected-pressed-icon-color: #9e1b32;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #9e1b32;
  --mdc-checkbox-selected-hover-state-layer-color: #9e1b32;
  --mdc-checkbox-selected-pressed-state-layer-color: #9e1b32;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #00789e;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #00789e;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

html {
  --mdc-list-list-item-one-line-container-height: 40px;
  --mdc-list-list-item-two-line-container-height: 56px;
  --mdc-list-list-item-three-line-container-height: 80px;
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 32px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 48px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 64px;
}

html {
  --mdc-list-list-item-label-text-font: Roboto;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 16px;
  --mdc-list-list-item-label-text-tracking: 0.03125em;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Roboto;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: 0.0178571429em;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Roboto;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333333333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font: 400 16px / 28px Roboto;
  letter-spacing: 0.009375em;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 48px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
}

html {
  --mat-paginator-container-text-font: Roboto;
  --mat-paginator-container-text-line-height: 20px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: 0.0333333333em;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

html {
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #00789e;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #00789e;
  --mat-tab-header-active-ripple-color: #00789e;
  --mat-tab-header-inactive-ripple-color: #00789e;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #00789e;
  --mat-tab-header-active-hover-label-text-color: #00789e;
  --mat-tab-header-active-focus-indicator-color: #00789e;
  --mat-tab-header-active-hover-indicator-color: #00789e;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #26bcd7;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #26bcd7;
  --mat-tab-header-active-ripple-color: #26bcd7;
  --mat-tab-header-inactive-ripple-color: #26bcd7;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #26bcd7;
  --mat-tab-header-active-hover-label-text-color: #26bcd7;
  --mat-tab-header-active-focus-indicator-color: #26bcd7;
  --mat-tab-header-active-hover-indicator-color: #26bcd7;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #9e1b32;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #9e1b32;
  --mat-tab-header-active-ripple-color: #9e1b32;
  --mat-tab-header-inactive-ripple-color: #9e1b32;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #9e1b32;
  --mat-tab-header-active-hover-label-text-color: #9e1b32;
  --mat-tab-header-active-focus-indicator-color: #9e1b32;
  --mat-tab-header-active-hover-indicator-color: #9e1b32;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #00789e;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #26bcd7;
  --mat-tab-header-with-background-foreground-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #9e1b32;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 40px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Roboto;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: 0.0892857143em;
  --mat-tab-header-label-text-line-height: 36px;
  --mat-tab-header-label-text-weight: 500;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #26bcd7;
  --mdc-checkbox-selected-hover-icon-color: #26bcd7;
  --mdc-checkbox-selected-icon-color: #26bcd7;
  --mdc-checkbox-selected-pressed-icon-color: #26bcd7;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #26bcd7;
  --mdc-checkbox-selected-hover-state-layer-color: #26bcd7;
  --mdc-checkbox-selected-pressed-state-layer-color: #26bcd7;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #00789e;
  --mdc-checkbox-selected-hover-icon-color: #00789e;
  --mdc-checkbox-selected-icon-color: #00789e;
  --mdc-checkbox-selected-pressed-icon-color: #00789e;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #00789e;
  --mdc-checkbox-selected-hover-state-layer-color: #00789e;
  --mdc-checkbox-selected-pressed-state-layer-color: #00789e;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #9e1b32;
  --mdc-checkbox-selected-hover-icon-color: #9e1b32;
  --mdc-checkbox-selected-icon-color: #9e1b32;
  --mdc-checkbox-selected-pressed-icon-color: #9e1b32;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #9e1b32;
  --mdc-checkbox-selected-hover-state-layer-color: #9e1b32;
  --mdc-checkbox-selected-pressed-state-layer-color: #9e1b32;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

html {
  --mdc-checkbox-state-layer-size: 32px;
  --mat-checkbox-touch-target-display: none;
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-font: Roboto;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
}

html {
  --mdc-text-button-label-text-color: #000;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: #000;
  --mat-text-button-disabled-state-layer-color: #000;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: #000;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: #000;
  --mat-filled-button-disabled-state-layer-color: #000;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: #000;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: #000;
  --mat-protected-button-disabled-state-layer-color: #000;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: #000;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #000;
  --mat-outlined-button-disabled-state-layer-color: #000;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #00789e;
  --mat-text-button-state-layer-color: #00789e;
  --mat-text-button-ripple-color: rgba(0, 120, 158, 0.1);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #26bcd7;
  --mat-text-button-state-layer-color: #26bcd7;
  --mat-text-button-ripple-color: rgba(38, 188, 215, 0.1);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #9e1b32;
  --mat-text-button-state-layer-color: #9e1b32;
  --mat-text-button-ripple-color: rgba(158, 27, 50, 0.1);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #00789e;
  --mdc-filled-button-label-text-color: #fff;
  --mat-filled-button-state-layer-color: #fff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #26bcd7;
  --mdc-filled-button-label-text-color: #000;
  --mat-filled-button-state-layer-color: #000;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #9e1b32;
  --mdc-filled-button-label-text-color: #fff;
  --mat-filled-button-state-layer-color: #fff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #00789e;
  --mdc-protected-button-label-text-color: #fff;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #26bcd7;
  --mdc-protected-button-label-text-color: #000;
  --mat-protected-button-state-layer-color: #000;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #9e1b32;
  --mdc-protected-button-label-text-color: #fff;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #00789e;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #00789e;
  --mat-outlined-button-ripple-color: rgba(0, 120, 158, 0.1);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #26bcd7;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #26bcd7;
  --mat-outlined-button-ripple-color: rgba(38, 188, 215, 0.1);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #9e1b32;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #9e1b32;
  --mat-outlined-button-ripple-color: rgba(158, 27, 50, 0.1);
}

html {
  --mdc-text-button-container-height: 28px;
  --mdc-filled-button-container-height: 28px;
  --mdc-outlined-button-container-height: 28px;
  --mdc-protected-button-container-height: 28px;
  --mat-text-button-touch-target-display: none;
  --mat-filled-button-touch-target-display: none;
  --mat-protected-button-touch-target-display: none;
  --mat-outlined-button-touch-target-display: none;
}

html {
  --mdc-text-button-label-text-font: Roboto;
  --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: 0.0892857143em;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: Roboto;
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: 0.0892857143em;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: Roboto;
  --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: 0.0892857143em;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: Roboto;
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: 0.0892857143em;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-label-text-transform: none;
}

html {
  --mdc-icon-button-icon-size: 24px;
}

html {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: #000;
  --mat-icon-button-disabled-state-layer-color: #000;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
}
html .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #00789e;
  --mat-icon-button-state-layer-color: #00789e;
  --mat-icon-button-ripple-color: rgba(0, 120, 158, 0.1);
}
html .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #26bcd7;
  --mat-icon-button-state-layer-color: #26bcd7;
  --mat-icon-button-ripple-color: rgba(38, 188, 215, 0.1);
}
html .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #9e1b32;
  --mat-icon-button-state-layer-color: #9e1b32;
  --mat-icon-button-ripple-color: rgba(158, 27, 50, 0.1);
}

html {
  --mat-icon-button-touch-target-display: none;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
}

html {
  --mdc-fab-container-color: white;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: #000;
  --mat-fab-disabled-state-layer-color: #000;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: #000;
  --mat-fab-small-disabled-state-layer-color: #000;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
}
html .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #00789e;
  --mat-fab-foreground-color: #fff;
  --mat-fab-state-layer-color: #fff;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #26bcd7;
  --mat-fab-foreground-color: #000;
  --mat-fab-state-layer-color: #000;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #9e1b32;
  --mat-fab-foreground-color: #fff;
  --mat-fab-state-layer-color: #fff;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #00789e;
  --mat-fab-small-foreground-color: #fff;
  --mat-fab-small-state-layer-color: #fff;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #26bcd7;
  --mat-fab-small-foreground-color: #000;
  --mat-fab-small-state-layer-color: #000;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #9e1b32;
  --mat-fab-small-foreground-color: #fff;
  --mat-fab-small-state-layer-color: #fff;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}

html {
  --mat-fab-touch-target-display: none;
  --mat-fab-small-touch-target-display: none;
}

html {
  --mdc-extended-fab-label-text-font: Roboto;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: 0.0892857143em;
  --mdc-extended-fab-label-text-weight: 500;
}

html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #26bcd7;
}

html {
  --mdc-snackbar-supporting-text-font: Roboto;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 48px;
  --mat-table-footer-container-height: 44px;
  --mat-table-row-item-container-height: 44px;
}

html {
  --mat-table-header-headline-font: Roboto;
  --mat-table-header-headline-line-height: 22px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: 0.0071428571em;
  --mat-table-row-item-label-text-font: Roboto;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: 0.0178571429em;
  --mat-table-footer-supporting-text-font: Roboto;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: 0.0178571429em;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mdc-circular-progress-active-indicator-color: #00789e;
}
html .mat-accent {
  --mdc-circular-progress-active-indicator-color: #26bcd7;
}
html .mat-warn {
  --mdc-circular-progress-active-indicator-color: #9e1b32;
}

html {
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
}

html {
  --mat-badge-background-color: #00789e;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #26bcd7;
  --mat-badge-text-color: rgba(0, 0, 0, 0.87);
}

.mat-badge-warn {
  --mat-badge-background-color: #9e1b32;
  --mat-badge-text-color: white;
}

html {
  --mat-badge-text-font: Roboto;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

html {
  --mat-bottom-sheet-container-shape: 4px;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-bottom-sheet-container-text-font: Roboto;
  --mat-bottom-sheet-container-text-line-height: 20px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: 0.0178571429em;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 40px;
}

html {
  --mat-legacy-button-toggle-label-text-font: Roboto;
  --mat-legacy-button-toggle-label-text-line-height: 24px;
  --mat-legacy-button-toggle-label-text-size: 16px;
  --mat-legacy-button-toggle-label-text-tracking: 0.03125em;
  --mat-legacy-button-toggle-label-text-weight: 400;
  --mat-standard-button-toggle-label-text-font: Roboto;
  --mat-standard-button-toggle-label-text-line-height: 24px;
  --mat-standard-button-toggle-label-text-size: 16px;
  --mat-standard-button-toggle-label-text-tracking: 0.03125em;
  --mat-standard-button-toggle-label-text-weight: 400;
}

html {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #00789e;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(0, 120, 158, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(0, 120, 158, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(0, 120, 158, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #00789e;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(0, 120, 158, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: #000;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-selected-state-background-color: #26bcd7;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(38, 188, 215, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(38, 188, 215, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(38, 188, 215, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(38, 188, 215, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #9e1b32;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(158, 27, 50, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(158, 27, 50, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(158, 27, 50, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(158, 27, 50, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #26bcd7;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #9e1b32;
}

.mat-calendar-controls {
  --mat-icon-button-touch-target-display: none;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mat-datepicker-calendar-text-font: Roboto;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 40px;
  --mat-expansion-header-expanded-state-height: 56px;
}

html {
  --mat-expansion-header-text-font: Roboto;
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Roboto;
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: 0.0178571429em;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #00789e;
}
.mat-icon.mat-accent {
  --mat-icon-color: #26bcd7;
}
.mat-icon.mat-warn {
  --mat-icon-color: #9e1b32;
}

html {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #00789e;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #00789e;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #00789e;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #9e1b32;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #9e1b32;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-selected-state-icon-background-color: #26bcd7;
  --mat-stepper-header-selected-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-done-state-icon-background-color: #26bcd7;
  --mat-stepper-header-done-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-edit-state-icon-background-color: #26bcd7;
  --mat-stepper-header-edit-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #9e1b32;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #9e1b32;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #9e1b32;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 64px;
}

html {
  --mat-stepper-container-text-font: Roboto;
  --mat-stepper-header-label-text-font: Roboto;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-sort-arrow-color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #00789e;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #26bcd7;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #9e1b32;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 56px;
  --mat-toolbar-mobile-height: 48px;
}

html {
  --mat-toolbar-title-text-font: Roboto;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: 0.0125em;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mat-tree-node-min-height: 40px;
}

html {
  --mat-tree-node-text-font: Roboto;
  --mat-tree-node-text-size: 14px;
  --mat-tree-node-text-weight: 400;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #005984;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #269dbd;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #9e0018;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 56px;
  --mat-toolbar-mobile-height: 48px;
}

html {
  --mat-toolbar-title-text-font: Roboto;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: 0.0125em;
  --mat-toolbar-title-text-weight: 500;
}

:root {
  --iux-ng-mat-theme-dark-color-primary-50: #e5f7fa;
  --iux-ng-mat-theme-dark-color-primary-100: #beebf3;
  --iux-ng-mat-theme-dark-color-primary-200: #93deeb;
  --iux-ng-mat-theme-dark-color-primary-300: #67d0e3;
  --iux-ng-mat-theme-dark-color-primary-400: #47c6dd;
  --iux-ng-mat-theme-dark-color-primary-500: #26bcd7;
  --iux-ng-mat-theme-dark-color-primary-600: #22b6d3;
  --iux-ng-mat-theme-dark-color-primary-700: #1cadcd;
  --iux-ng-mat-theme-dark-color-primary-800: #17a5c7;
  --iux-ng-mat-theme-dark-color-primary-900: #0e97be;
  --iux-ng-mat-theme-dark-color-primary-A100: #aaf9ff;
  --iux-ng-mat-theme-dark-color-primary-A200: #6df5ff;
  --iux-ng-mat-theme-dark-color-primary-A400: #53f3ff;
  --iux-ng-mat-theme-dark-color-primary-A700: #44f2ff;
  --iux-ng-mat-theme-dark-color-primary-contrast-50: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-primary-contrast-100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-primary-contrast-200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-primary-contrast-300: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-primary-contrast-400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-primary-contrast-500: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-primary-contrast-600: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-primary-contrast-700: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-primary-contrast-800: white;
  --iux-ng-mat-theme-dark-color-primary-contrast-900: white;
  --iux-ng-mat-theme-dark-color-primary-contrast-A100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-primary-contrast-A200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-primary-contrast-A400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-primary-contrast-A700: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-primary-default: #26bcd7;
  --iux-ng-mat-theme-dark-color-primary-lighter: #beebf3;
  --iux-ng-mat-theme-dark-color-primary-darker: #1cadcd;
  --iux-ng-mat-theme-dark-color-primary-text: #26bcd7;
  --iux-ng-mat-theme-dark-color-primary-default-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-primary-lighter-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-primary-darker-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-primary-50-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-primary-100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-primary-200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-primary-300-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-primary-400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-primary-500-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-primary-600-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-primary-700-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-primary-800-contrast: white;
  --iux-ng-mat-theme-dark-color-primary-900-contrast: white;
  --iux-ng-mat-theme-dark-color-primary-A100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-primary-A200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-primary-A400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-primary-A700-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-accent-50: #eaf7e9;
  --iux-ng-mat-theme-dark-color-accent-100: #cceac8;
  --iux-ng-mat-theme-dark-color-accent-200: #aadca4;
  --iux-ng-mat-theme-dark-color-accent-300: #87ce7f;
  --iux-ng-mat-theme-dark-color-accent-400: #6ec463;
  --iux-ng-mat-theme-dark-color-accent-500: #54b948;
  --iux-ng-mat-theme-dark-color-accent-600: #4db241;
  --iux-ng-mat-theme-dark-color-accent-700: #43aa38;
  --iux-ng-mat-theme-dark-color-accent-800: #3aa230;
  --iux-ng-mat-theme-dark-color-accent-900: #2a9321;
  --iux-ng-mat-theme-dark-color-accent-A100: #aeff95;
  --iux-ng-mat-theme-dark-color-accent-A200: #7fff58;
  --iux-ng-mat-theme-dark-color-accent-A400: #6bff3e;
  --iux-ng-mat-theme-dark-color-accent-A700: #5fff2f;
  --iux-ng-mat-theme-dark-color-accent-contrast-50: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-accent-contrast-100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-accent-contrast-200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-accent-contrast-300: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-accent-contrast-400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-accent-contrast-500: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-accent-contrast-600: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-accent-contrast-700: white;
  --iux-ng-mat-theme-dark-color-accent-contrast-800: white;
  --iux-ng-mat-theme-dark-color-accent-contrast-900: white;
  --iux-ng-mat-theme-dark-color-accent-contrast-A100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-accent-contrast-A200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-accent-contrast-A400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-accent-contrast-A700: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-accent-default: #54b948;
  --iux-ng-mat-theme-dark-color-accent-lighter: #cceac8;
  --iux-ng-mat-theme-dark-color-accent-darker: #43aa38;
  --iux-ng-mat-theme-dark-color-accent-text: #54b948;
  --iux-ng-mat-theme-dark-color-accent-default-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-accent-lighter-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-accent-darker-contrast: white;
  --iux-ng-mat-theme-dark-color-accent-50-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-accent-100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-accent-200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-accent-300-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-accent-400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-accent-500-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-accent-600-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-accent-700-contrast: white;
  --iux-ng-mat-theme-dark-color-accent-800-contrast: white;
  --iux-ng-mat-theme-dark-color-accent-900-contrast: white;
  --iux-ng-mat-theme-dark-color-accent-A100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-accent-A200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-accent-A400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-accent-A700-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-warn-50: #f7ecee;
  --iux-ng-mat-theme-dark-color-warn-100: #ebcfd4;
  --iux-ng-mat-theme-dark-color-warn-200: #ddafb8;
  --iux-ng-mat-theme-dark-color-warn-300: #cf8f9b;
  --iux-ng-mat-theme-dark-color-warn-400: #c57785;
  --iux-ng-mat-theme-dark-color-warn-500: #bb5f70;
  --iux-ng-mat-theme-dark-color-warn-600: #b55768;
  --iux-ng-mat-theme-dark-color-warn-700: #ac4d5d;
  --iux-ng-mat-theme-dark-color-warn-800: #a44353;
  --iux-ng-mat-theme-dark-color-warn-900: #963241;
  --iux-ng-mat-theme-dark-color-warn-A100: #ffa9c1;
  --iux-ng-mat-theme-dark-color-warn-A200: #ff6c96;
  --iux-ng-mat-theme-dark-color-warn-A400: #ff5284;
  --iux-ng-mat-theme-dark-color-warn-A700: #ff4379;
  --iux-ng-mat-theme-dark-color-warn-contrast-50: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-warn-contrast-100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-warn-contrast-200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-warn-contrast-300: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-warn-contrast-400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-warn-contrast-500: white;
  --iux-ng-mat-theme-dark-color-warn-contrast-600: white;
  --iux-ng-mat-theme-dark-color-warn-contrast-700: white;
  --iux-ng-mat-theme-dark-color-warn-contrast-800: white;
  --iux-ng-mat-theme-dark-color-warn-contrast-900: white;
  --iux-ng-mat-theme-dark-color-warn-contrast-A100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-warn-contrast-A200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-warn-contrast-A400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-warn-contrast-A700: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-warn-default: #bb5f70;
  --iux-ng-mat-theme-dark-color-warn-lighter: #ebcfd4;
  --iux-ng-mat-theme-dark-color-warn-darker: #ac4d5d;
  --iux-ng-mat-theme-dark-color-warn-text: #bb5f70;
  --iux-ng-mat-theme-dark-color-warn-default-contrast: white;
  --iux-ng-mat-theme-dark-color-warn-lighter-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-warn-darker-contrast: white;
  --iux-ng-mat-theme-dark-color-warn-50-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-warn-100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-warn-200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-warn-300-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-warn-400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-warn-500-contrast: white;
  --iux-ng-mat-theme-dark-color-warn-600-contrast: white;
  --iux-ng-mat-theme-dark-color-warn-700-contrast: white;
  --iux-ng-mat-theme-dark-color-warn-800-contrast: white;
  --iux-ng-mat-theme-dark-color-warn-900-contrast: white;
  --iux-ng-mat-theme-dark-color-warn-A100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-warn-A200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-warn-A400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-warn-A700-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-color-foreground-base: white;
  --iux-ng-mat-theme-dark-color-foreground-divider: rgba(255, 255, 255, 0.12);
  --iux-ng-mat-theme-dark-color-foreground-dividers: rgba(255, 255, 255, 0.12);
  --iux-ng-mat-theme-dark-color-foreground-disabled: rgba(255, 255, 255, 0.5);
  --iux-ng-mat-theme-dark-color-foreground-disabled-button: rgba(255, 255, 255, 0.3);
  --iux-ng-mat-theme-dark-color-foreground-disabled-text: rgba(255, 255, 255, 0.5);
  --iux-ng-mat-theme-dark-color-foreground-elevation: black;
  --iux-ng-mat-theme-dark-color-foreground-hint-text: rgba(255, 255, 255, 0.5);
  --iux-ng-mat-theme-dark-color-foreground-secondary-text: rgba(255, 255, 255, 0.7);
  --iux-ng-mat-theme-dark-color-foreground-icon: white;
  --iux-ng-mat-theme-dark-color-foreground-icons: white;
  --iux-ng-mat-theme-dark-color-foreground-text: white;
  --iux-ng-mat-theme-dark-color-foreground-slider-min: white;
  --iux-ng-mat-theme-dark-color-foreground-slider-off: rgba(255, 255, 255, 0.3);
  --iux-ng-mat-theme-dark-color-foreground-slider-off-active: rgba(255, 255, 255, 0.3);
  --iux-ng-mat-theme-dark-color-background-status-bar: black;
  --iux-ng-mat-theme-dark-color-background-app-bar: #212121;
  --iux-ng-mat-theme-dark-color-background-background: #303030;
  --iux-ng-mat-theme-dark-color-background-hover: rgba(255, 255, 255, 0.04);
  --iux-ng-mat-theme-dark-color-background-card: #424242;
  --iux-ng-mat-theme-dark-color-background-dialog: #424242;
  --iux-ng-mat-theme-dark-color-background-disabled-button: rgba(255, 255, 255, 0.12);
  --iux-ng-mat-theme-dark-color-background-raised-button: #424242;
  --iux-ng-mat-theme-dark-color-background-focused-button: rgba(255, 255, 255, 0.12);
  --iux-ng-mat-theme-dark-color-background-selected-button: #212121;
  --iux-ng-mat-theme-dark-color-background-selected-disabled-button: #424242;
  --iux-ng-mat-theme-dark-color-background-disabled-button-toggle: black;
  --iux-ng-mat-theme-dark-color-background-unselected-chip: #616161;
  --iux-ng-mat-theme-dark-color-background-disabled-list-option: rgba(255, 255, 255, 0.12);
  --iux-ng-mat-theme-dark-color-background-tooltip: #616161;
  --iux-ng-mat-theme-dark-primary-50: #e5f7fa;
  --iux-ng-mat-theme-dark-primary-100: #beebf3;
  --iux-ng-mat-theme-dark-primary-200: #93deeb;
  --iux-ng-mat-theme-dark-primary-300: #67d0e3;
  --iux-ng-mat-theme-dark-primary-400: #47c6dd;
  --iux-ng-mat-theme-dark-primary-500: #26bcd7;
  --iux-ng-mat-theme-dark-primary-600: #22b6d3;
  --iux-ng-mat-theme-dark-primary-700: #1cadcd;
  --iux-ng-mat-theme-dark-primary-800: #17a5c7;
  --iux-ng-mat-theme-dark-primary-900: #0e97be;
  --iux-ng-mat-theme-dark-primary-A100: #aaf9ff;
  --iux-ng-mat-theme-dark-primary-A200: #6df5ff;
  --iux-ng-mat-theme-dark-primary-A400: #53f3ff;
  --iux-ng-mat-theme-dark-primary-A700: #44f2ff;
  --iux-ng-mat-theme-dark-primary-contrast-50: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-primary-contrast-100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-primary-contrast-200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-primary-contrast-300: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-primary-contrast-400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-primary-contrast-500: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-primary-contrast-600: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-primary-contrast-700: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-primary-contrast-800: white;
  --iux-ng-mat-theme-dark-primary-contrast-900: white;
  --iux-ng-mat-theme-dark-primary-contrast-A100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-primary-contrast-A200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-primary-contrast-A400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-primary-contrast-A700: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-primary-default: #26bcd7;
  --iux-ng-mat-theme-dark-primary-lighter: #beebf3;
  --iux-ng-mat-theme-dark-primary-darker: #1cadcd;
  --iux-ng-mat-theme-dark-primary-text: #26bcd7;
  --iux-ng-mat-theme-dark-primary-default-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-primary-lighter-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-primary-darker-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-primary-50-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-primary-100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-primary-200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-primary-300-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-primary-400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-primary-500-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-primary-600-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-primary-700-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-primary-800-contrast: white;
  --iux-ng-mat-theme-dark-primary-900-contrast: white;
  --iux-ng-mat-theme-dark-primary-A100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-primary-A200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-primary-A400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-primary-A700-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-accent-50: #eaf7e9;
  --iux-ng-mat-theme-dark-accent-100: #cceac8;
  --iux-ng-mat-theme-dark-accent-200: #aadca4;
  --iux-ng-mat-theme-dark-accent-300: #87ce7f;
  --iux-ng-mat-theme-dark-accent-400: #6ec463;
  --iux-ng-mat-theme-dark-accent-500: #54b948;
  --iux-ng-mat-theme-dark-accent-600: #4db241;
  --iux-ng-mat-theme-dark-accent-700: #43aa38;
  --iux-ng-mat-theme-dark-accent-800: #3aa230;
  --iux-ng-mat-theme-dark-accent-900: #2a9321;
  --iux-ng-mat-theme-dark-accent-A100: #aeff95;
  --iux-ng-mat-theme-dark-accent-A200: #7fff58;
  --iux-ng-mat-theme-dark-accent-A400: #6bff3e;
  --iux-ng-mat-theme-dark-accent-A700: #5fff2f;
  --iux-ng-mat-theme-dark-accent-contrast-50: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-accent-contrast-100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-accent-contrast-200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-accent-contrast-300: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-accent-contrast-400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-accent-contrast-500: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-accent-contrast-600: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-accent-contrast-700: white;
  --iux-ng-mat-theme-dark-accent-contrast-800: white;
  --iux-ng-mat-theme-dark-accent-contrast-900: white;
  --iux-ng-mat-theme-dark-accent-contrast-A100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-accent-contrast-A200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-accent-contrast-A400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-accent-contrast-A700: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-accent-default: #54b948;
  --iux-ng-mat-theme-dark-accent-lighter: #cceac8;
  --iux-ng-mat-theme-dark-accent-darker: #43aa38;
  --iux-ng-mat-theme-dark-accent-text: #54b948;
  --iux-ng-mat-theme-dark-accent-default-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-accent-lighter-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-accent-darker-contrast: white;
  --iux-ng-mat-theme-dark-accent-50-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-accent-100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-accent-200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-accent-300-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-accent-400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-accent-500-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-accent-600-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-accent-700-contrast: white;
  --iux-ng-mat-theme-dark-accent-800-contrast: white;
  --iux-ng-mat-theme-dark-accent-900-contrast: white;
  --iux-ng-mat-theme-dark-accent-A100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-accent-A200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-accent-A400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-accent-A700-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-warn-50: #f7ecee;
  --iux-ng-mat-theme-dark-warn-100: #ebcfd4;
  --iux-ng-mat-theme-dark-warn-200: #ddafb8;
  --iux-ng-mat-theme-dark-warn-300: #cf8f9b;
  --iux-ng-mat-theme-dark-warn-400: #c57785;
  --iux-ng-mat-theme-dark-warn-500: #bb5f70;
  --iux-ng-mat-theme-dark-warn-600: #b55768;
  --iux-ng-mat-theme-dark-warn-700: #ac4d5d;
  --iux-ng-mat-theme-dark-warn-800: #a44353;
  --iux-ng-mat-theme-dark-warn-900: #963241;
  --iux-ng-mat-theme-dark-warn-A100: #ffa9c1;
  --iux-ng-mat-theme-dark-warn-A200: #ff6c96;
  --iux-ng-mat-theme-dark-warn-A400: #ff5284;
  --iux-ng-mat-theme-dark-warn-A700: #ff4379;
  --iux-ng-mat-theme-dark-warn-contrast-50: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-warn-contrast-100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-warn-contrast-200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-warn-contrast-300: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-warn-contrast-400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-warn-contrast-500: white;
  --iux-ng-mat-theme-dark-warn-contrast-600: white;
  --iux-ng-mat-theme-dark-warn-contrast-700: white;
  --iux-ng-mat-theme-dark-warn-contrast-800: white;
  --iux-ng-mat-theme-dark-warn-contrast-900: white;
  --iux-ng-mat-theme-dark-warn-contrast-A100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-warn-contrast-A200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-warn-contrast-A400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-warn-contrast-A700: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-warn-default: #bb5f70;
  --iux-ng-mat-theme-dark-warn-lighter: #ebcfd4;
  --iux-ng-mat-theme-dark-warn-darker: #ac4d5d;
  --iux-ng-mat-theme-dark-warn-text: #bb5f70;
  --iux-ng-mat-theme-dark-warn-default-contrast: white;
  --iux-ng-mat-theme-dark-warn-lighter-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-warn-darker-contrast: white;
  --iux-ng-mat-theme-dark-warn-50-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-warn-100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-warn-200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-warn-300-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-warn-400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-warn-500-contrast: white;
  --iux-ng-mat-theme-dark-warn-600-contrast: white;
  --iux-ng-mat-theme-dark-warn-700-contrast: white;
  --iux-ng-mat-theme-dark-warn-800-contrast: white;
  --iux-ng-mat-theme-dark-warn-900-contrast: white;
  --iux-ng-mat-theme-dark-warn-A100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-warn-A200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-warn-A400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-warn-A700-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-dark-foreground-base: white;
  --iux-ng-mat-theme-dark-foreground-divider: rgba(255, 255, 255, 0.12);
  --iux-ng-mat-theme-dark-foreground-dividers: rgba(255, 255, 255, 0.12);
  --iux-ng-mat-theme-dark-foreground-disabled: rgba(255, 255, 255, 0.5);
  --iux-ng-mat-theme-dark-foreground-disabled-button: rgba(255, 255, 255, 0.3);
  --iux-ng-mat-theme-dark-foreground-disabled-text: rgba(255, 255, 255, 0.5);
  --iux-ng-mat-theme-dark-foreground-elevation: black;
  --iux-ng-mat-theme-dark-foreground-hint-text: rgba(255, 255, 255, 0.5);
  --iux-ng-mat-theme-dark-foreground-secondary-text: rgba(255, 255, 255, 0.7);
  --iux-ng-mat-theme-dark-foreground-icon: white;
  --iux-ng-mat-theme-dark-foreground-icons: white;
  --iux-ng-mat-theme-dark-foreground-text: white;
  --iux-ng-mat-theme-dark-foreground-slider-min: white;
  --iux-ng-mat-theme-dark-foreground-slider-off: rgba(255, 255, 255, 0.3);
  --iux-ng-mat-theme-dark-foreground-slider-off-active: rgba(255, 255, 255, 0.3);
  --iux-ng-mat-theme-dark-background-status-bar: black;
  --iux-ng-mat-theme-dark-background-app-bar: #212121;
  --iux-ng-mat-theme-dark-background-background: #303030;
  --iux-ng-mat-theme-dark-background-hover: rgba(255, 255, 255, 0.04);
  --iux-ng-mat-theme-dark-background-card: #424242;
  --iux-ng-mat-theme-dark-background-dialog: #424242;
  --iux-ng-mat-theme-dark-background-disabled-button: rgba(255, 255, 255, 0.12);
  --iux-ng-mat-theme-dark-background-raised-button: #424242;
  --iux-ng-mat-theme-dark-background-focused-button: rgba(255, 255, 255, 0.12);
  --iux-ng-mat-theme-dark-background-selected-button: #212121;
  --iux-ng-mat-theme-dark-background-selected-disabled-button: #424242;
  --iux-ng-mat-theme-dark-background-disabled-button-toggle: black;
  --iux-ng-mat-theme-dark-background-unselected-chip: #616161;
  --iux-ng-mat-theme-dark-background-disabled-list-option: rgba(255, 255, 255, 0.12);
  --iux-ng-mat-theme-dark-background-tooltip: #616161;
}

:root {
  --iux-ng-mat-theme-offsetdark-color-primary-50: #e5fbfd;
  --iux-ng-mat-theme-offsetdark-color-primary-100: #bef4fb;
  --iux-ng-mat-theme-offsetdark-color-primary-200: #93edf8;
  --iux-ng-mat-theme-offsetdark-color-primary-300: #67e6f5;
  --iux-ng-mat-theme-offsetdark-color-primary-400: #47e0f3;
  --iux-ng-mat-theme-offsetdark-color-primary-500: #26dbf1;
  --iux-ng-mat-theme-offsetdark-color-primary-600: #22d7ef;
  --iux-ng-mat-theme-offsetdark-color-primary-700: #1cd2ed;
  --iux-ng-mat-theme-offsetdark-color-primary-800: #17cdeb;
  --iux-ng-mat-theme-offsetdark-color-primary-900: #0ec4e7;
  --iux-ng-mat-theme-offsetdark-color-primary-A100: #d5feff;
  --iux-ng-mat-theme-offsetdark-color-primary-A200: #98fdff;
  --iux-ng-mat-theme-offsetdark-color-primary-A400: #7efcff;
  --iux-ng-mat-theme-offsetdark-color-primary-A700: #6ffcff;
  --iux-ng-mat-theme-offsetdark-color-primary-contrast-50: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-primary-contrast-100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-primary-contrast-200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-primary-contrast-300: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-primary-contrast-400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-primary-contrast-500: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-primary-contrast-600: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-primary-contrast-700: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-primary-contrast-800: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-primary-contrast-900: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-primary-contrast-A100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-primary-contrast-A200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-primary-contrast-A400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-primary-contrast-A700: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-primary-default: #26dbf1;
  --iux-ng-mat-theme-offsetdark-color-primary-lighter: #bef4fb;
  --iux-ng-mat-theme-offsetdark-color-primary-darker: #1cd2ed;
  --iux-ng-mat-theme-offsetdark-color-primary-text: #26dbf1;
  --iux-ng-mat-theme-offsetdark-color-primary-default-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-primary-lighter-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-primary-darker-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-primary-50-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-primary-100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-primary-200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-primary-300-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-primary-400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-primary-500-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-primary-600-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-primary-700-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-primary-800-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-primary-900-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-primary-A100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-primary-A200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-primary-A400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-primary-A700-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-accent-50: #eafaec;
  --iux-ng-mat-theme-offsetdark-color-accent-100: #ccf3d0;
  --iux-ng-mat-theme-offsetdark-color-accent-200: #aaecb1;
  --iux-ng-mat-theme-offsetdark-color-accent-300: #87e491;
  --iux-ng-mat-theme-offsetdark-color-accent-400: #6ede7a;
  --iux-ng-mat-theme-offsetdark-color-accent-500: #54d862;
  --iux-ng-mat-theme-offsetdark-color-accent-600: #4dd45a;
  --iux-ng-mat-theme-offsetdark-color-accent-700: #43ce50;
  --iux-ng-mat-theme-offsetdark-color-accent-800: #3ac946;
  --iux-ng-mat-theme-offsetdark-color-accent-900: #2abf35;
  --iux-ng-mat-theme-offsetdark-color-accent-A100: #daffd7;
  --iux-ng-mat-theme-offsetdark-color-accent-A200: #a2ff9a;
  --iux-ng-mat-theme-offsetdark-color-accent-A400: #8aff80;
  --iux-ng-mat-theme-offsetdark-color-accent-A700: #7cff71;
  --iux-ng-mat-theme-offsetdark-color-accent-contrast-50: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-accent-contrast-100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-accent-contrast-200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-accent-contrast-300: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-accent-contrast-400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-accent-contrast-500: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-accent-contrast-600: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-accent-contrast-700: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-accent-contrast-800: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-accent-contrast-900: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-accent-contrast-A100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-accent-contrast-A200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-accent-contrast-A400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-accent-contrast-A700: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-accent-default: #54d862;
  --iux-ng-mat-theme-offsetdark-color-accent-lighter: #ccf3d0;
  --iux-ng-mat-theme-offsetdark-color-accent-darker: #43ce50;
  --iux-ng-mat-theme-offsetdark-color-accent-text: #54d862;
  --iux-ng-mat-theme-offsetdark-color-accent-default-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-accent-lighter-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-accent-darker-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-accent-50-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-accent-100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-accent-200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-accent-300-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-accent-400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-accent-500-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-accent-600-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-accent-700-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-accent-800-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-accent-900-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-accent-A100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-accent-A200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-accent-A400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-accent-A700-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-warn-50: #f7f0f1;
  --iux-ng-mat-theme-offsetdark-color-warn-100: #ebd8dc;
  --iux-ng-mat-theme-offsetdark-color-warn-200: #ddbfc5;
  --iux-ng-mat-theme-offsetdark-color-warn-300: #cfa5ad;
  --iux-ng-mat-theme-offsetdark-color-warn-400: #c5919c;
  --iux-ng-mat-theme-offsetdark-color-warn-500: #bb7e8a;
  --iux-ng-mat-theme-offsetdark-color-warn-600: #b57682;
  --iux-ng-mat-theme-offsetdark-color-warn-700: #ac6b77;
  --iux-ng-mat-theme-offsetdark-color-warn-800: #a4616d;
  --iux-ng-mat-theme-offsetdark-color-warn-900: #964e5b;
  --iux-ng-mat-theme-offsetdark-color-warn-A100: #ffc8d9;
  --iux-ng-mat-theme-offsetdark-color-warn-A200: #ff8bae;
  --iux-ng-mat-theme-offsetdark-color-warn-A400: #ff719c;
  --iux-ng-mat-theme-offsetdark-color-warn-A700: #ff6291;
  --iux-ng-mat-theme-offsetdark-color-warn-contrast-50: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-warn-contrast-100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-warn-contrast-200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-warn-contrast-300: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-warn-contrast-400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-warn-contrast-500: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-warn-contrast-600: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-warn-contrast-700: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-warn-contrast-800: white;
  --iux-ng-mat-theme-offsetdark-color-warn-contrast-900: white;
  --iux-ng-mat-theme-offsetdark-color-warn-contrast-A100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-warn-contrast-A200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-warn-contrast-A400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-warn-contrast-A700: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-warn-default: #bb7e8a;
  --iux-ng-mat-theme-offsetdark-color-warn-lighter: #ebd8dc;
  --iux-ng-mat-theme-offsetdark-color-warn-darker: #ac6b77;
  --iux-ng-mat-theme-offsetdark-color-warn-text: #bb7e8a;
  --iux-ng-mat-theme-offsetdark-color-warn-default-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-warn-lighter-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-warn-darker-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-warn-50-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-warn-100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-warn-200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-warn-300-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-warn-400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-warn-500-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-warn-600-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-warn-700-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-warn-800-contrast: white;
  --iux-ng-mat-theme-offsetdark-color-warn-900-contrast: white;
  --iux-ng-mat-theme-offsetdark-color-warn-A100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-warn-A200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-warn-A400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-warn-A700-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-color-foreground-base: white;
  --iux-ng-mat-theme-offsetdark-color-foreground-divider: rgba(255, 255, 255, 0.12);
  --iux-ng-mat-theme-offsetdark-color-foreground-dividers: rgba(255, 255, 255, 0.12);
  --iux-ng-mat-theme-offsetdark-color-foreground-disabled: rgba(255, 255, 255, 0.5);
  --iux-ng-mat-theme-offsetdark-color-foreground-disabled-button: rgba(255, 255, 255, 0.3);
  --iux-ng-mat-theme-offsetdark-color-foreground-disabled-text: rgba(255, 255, 255, 0.5);
  --iux-ng-mat-theme-offsetdark-color-foreground-elevation: black;
  --iux-ng-mat-theme-offsetdark-color-foreground-hint-text: rgba(255, 255, 255, 0.5);
  --iux-ng-mat-theme-offsetdark-color-foreground-secondary-text: rgba(255, 255, 255, 0.7);
  --iux-ng-mat-theme-offsetdark-color-foreground-icon: white;
  --iux-ng-mat-theme-offsetdark-color-foreground-icons: white;
  --iux-ng-mat-theme-offsetdark-color-foreground-text: white;
  --iux-ng-mat-theme-offsetdark-color-foreground-slider-min: white;
  --iux-ng-mat-theme-offsetdark-color-foreground-slider-off: rgba(255, 255, 255, 0.3);
  --iux-ng-mat-theme-offsetdark-color-foreground-slider-off-active: rgba(255, 255, 255, 0.3);
  --iux-ng-mat-theme-offsetdark-color-background-status-bar: black;
  --iux-ng-mat-theme-offsetdark-color-background-app-bar: #212121;
  --iux-ng-mat-theme-offsetdark-color-background-background: #303030;
  --iux-ng-mat-theme-offsetdark-color-background-hover: rgba(255, 255, 255, 0.04);
  --iux-ng-mat-theme-offsetdark-color-background-card: #424242;
  --iux-ng-mat-theme-offsetdark-color-background-dialog: #424242;
  --iux-ng-mat-theme-offsetdark-color-background-disabled-button: rgba(255, 255, 255, 0.12);
  --iux-ng-mat-theme-offsetdark-color-background-raised-button: #424242;
  --iux-ng-mat-theme-offsetdark-color-background-focused-button: rgba(255, 255, 255, 0.12);
  --iux-ng-mat-theme-offsetdark-color-background-selected-button: #212121;
  --iux-ng-mat-theme-offsetdark-color-background-selected-disabled-button: #424242;
  --iux-ng-mat-theme-offsetdark-color-background-disabled-button-toggle: black;
  --iux-ng-mat-theme-offsetdark-color-background-unselected-chip: #616161;
  --iux-ng-mat-theme-offsetdark-color-background-disabled-list-option: rgba(255, 255, 255, 0.12);
  --iux-ng-mat-theme-offsetdark-color-background-tooltip: #616161;
  --iux-ng-mat-theme-offsetdark-primary-50: #e5fbfd;
  --iux-ng-mat-theme-offsetdark-primary-100: #bef4fb;
  --iux-ng-mat-theme-offsetdark-primary-200: #93edf8;
  --iux-ng-mat-theme-offsetdark-primary-300: #67e6f5;
  --iux-ng-mat-theme-offsetdark-primary-400: #47e0f3;
  --iux-ng-mat-theme-offsetdark-primary-500: #26dbf1;
  --iux-ng-mat-theme-offsetdark-primary-600: #22d7ef;
  --iux-ng-mat-theme-offsetdark-primary-700: #1cd2ed;
  --iux-ng-mat-theme-offsetdark-primary-800: #17cdeb;
  --iux-ng-mat-theme-offsetdark-primary-900: #0ec4e7;
  --iux-ng-mat-theme-offsetdark-primary-A100: #d5feff;
  --iux-ng-mat-theme-offsetdark-primary-A200: #98fdff;
  --iux-ng-mat-theme-offsetdark-primary-A400: #7efcff;
  --iux-ng-mat-theme-offsetdark-primary-A700: #6ffcff;
  --iux-ng-mat-theme-offsetdark-primary-contrast-50: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-primary-contrast-100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-primary-contrast-200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-primary-contrast-300: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-primary-contrast-400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-primary-contrast-500: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-primary-contrast-600: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-primary-contrast-700: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-primary-contrast-800: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-primary-contrast-900: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-primary-contrast-A100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-primary-contrast-A200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-primary-contrast-A400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-primary-contrast-A700: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-primary-default: #26dbf1;
  --iux-ng-mat-theme-offsetdark-primary-lighter: #bef4fb;
  --iux-ng-mat-theme-offsetdark-primary-darker: #1cd2ed;
  --iux-ng-mat-theme-offsetdark-primary-text: #26dbf1;
  --iux-ng-mat-theme-offsetdark-primary-default-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-primary-lighter-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-primary-darker-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-primary-50-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-primary-100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-primary-200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-primary-300-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-primary-400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-primary-500-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-primary-600-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-primary-700-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-primary-800-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-primary-900-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-primary-A100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-primary-A200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-primary-A400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-primary-A700-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-accent-50: #eafaec;
  --iux-ng-mat-theme-offsetdark-accent-100: #ccf3d0;
  --iux-ng-mat-theme-offsetdark-accent-200: #aaecb1;
  --iux-ng-mat-theme-offsetdark-accent-300: #87e491;
  --iux-ng-mat-theme-offsetdark-accent-400: #6ede7a;
  --iux-ng-mat-theme-offsetdark-accent-500: #54d862;
  --iux-ng-mat-theme-offsetdark-accent-600: #4dd45a;
  --iux-ng-mat-theme-offsetdark-accent-700: #43ce50;
  --iux-ng-mat-theme-offsetdark-accent-800: #3ac946;
  --iux-ng-mat-theme-offsetdark-accent-900: #2abf35;
  --iux-ng-mat-theme-offsetdark-accent-A100: #daffd7;
  --iux-ng-mat-theme-offsetdark-accent-A200: #a2ff9a;
  --iux-ng-mat-theme-offsetdark-accent-A400: #8aff80;
  --iux-ng-mat-theme-offsetdark-accent-A700: #7cff71;
  --iux-ng-mat-theme-offsetdark-accent-contrast-50: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-accent-contrast-100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-accent-contrast-200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-accent-contrast-300: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-accent-contrast-400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-accent-contrast-500: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-accent-contrast-600: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-accent-contrast-700: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-accent-contrast-800: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-accent-contrast-900: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-accent-contrast-A100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-accent-contrast-A200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-accent-contrast-A400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-accent-contrast-A700: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-accent-default: #54d862;
  --iux-ng-mat-theme-offsetdark-accent-lighter: #ccf3d0;
  --iux-ng-mat-theme-offsetdark-accent-darker: #43ce50;
  --iux-ng-mat-theme-offsetdark-accent-text: #54d862;
  --iux-ng-mat-theme-offsetdark-accent-default-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-accent-lighter-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-accent-darker-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-accent-50-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-accent-100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-accent-200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-accent-300-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-accent-400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-accent-500-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-accent-600-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-accent-700-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-accent-800-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-accent-900-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-accent-A100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-accent-A200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-accent-A400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-accent-A700-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-warn-50: #f7f0f1;
  --iux-ng-mat-theme-offsetdark-warn-100: #ebd8dc;
  --iux-ng-mat-theme-offsetdark-warn-200: #ddbfc5;
  --iux-ng-mat-theme-offsetdark-warn-300: #cfa5ad;
  --iux-ng-mat-theme-offsetdark-warn-400: #c5919c;
  --iux-ng-mat-theme-offsetdark-warn-500: #bb7e8a;
  --iux-ng-mat-theme-offsetdark-warn-600: #b57682;
  --iux-ng-mat-theme-offsetdark-warn-700: #ac6b77;
  --iux-ng-mat-theme-offsetdark-warn-800: #a4616d;
  --iux-ng-mat-theme-offsetdark-warn-900: #964e5b;
  --iux-ng-mat-theme-offsetdark-warn-A100: #ffc8d9;
  --iux-ng-mat-theme-offsetdark-warn-A200: #ff8bae;
  --iux-ng-mat-theme-offsetdark-warn-A400: #ff719c;
  --iux-ng-mat-theme-offsetdark-warn-A700: #ff6291;
  --iux-ng-mat-theme-offsetdark-warn-contrast-50: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-warn-contrast-100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-warn-contrast-200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-warn-contrast-300: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-warn-contrast-400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-warn-contrast-500: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-warn-contrast-600: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-warn-contrast-700: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-warn-contrast-800: white;
  --iux-ng-mat-theme-offsetdark-warn-contrast-900: white;
  --iux-ng-mat-theme-offsetdark-warn-contrast-A100: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-warn-contrast-A200: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-warn-contrast-A400: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-warn-contrast-A700: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-warn-default: #bb7e8a;
  --iux-ng-mat-theme-offsetdark-warn-lighter: #ebd8dc;
  --iux-ng-mat-theme-offsetdark-warn-darker: #ac6b77;
  --iux-ng-mat-theme-offsetdark-warn-text: #bb7e8a;
  --iux-ng-mat-theme-offsetdark-warn-default-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-warn-lighter-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-warn-darker-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-warn-50-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-warn-100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-warn-200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-warn-300-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-warn-400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-warn-500-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-warn-600-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-warn-700-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-warn-800-contrast: white;
  --iux-ng-mat-theme-offsetdark-warn-900-contrast: white;
  --iux-ng-mat-theme-offsetdark-warn-A100-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-warn-A200-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-warn-A400-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-warn-A700-contrast: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-offsetdark-foreground-base: white;
  --iux-ng-mat-theme-offsetdark-foreground-divider: rgba(255, 255, 255, 0.12);
  --iux-ng-mat-theme-offsetdark-foreground-dividers: rgba(255, 255, 255, 0.12);
  --iux-ng-mat-theme-offsetdark-foreground-disabled: rgba(255, 255, 255, 0.5);
  --iux-ng-mat-theme-offsetdark-foreground-disabled-button: rgba(255, 255, 255, 0.3);
  --iux-ng-mat-theme-offsetdark-foreground-disabled-text: rgba(255, 255, 255, 0.5);
  --iux-ng-mat-theme-offsetdark-foreground-elevation: black;
  --iux-ng-mat-theme-offsetdark-foreground-hint-text: rgba(255, 255, 255, 0.5);
  --iux-ng-mat-theme-offsetdark-foreground-secondary-text: rgba(255, 255, 255, 0.7);
  --iux-ng-mat-theme-offsetdark-foreground-icon: white;
  --iux-ng-mat-theme-offsetdark-foreground-icons: white;
  --iux-ng-mat-theme-offsetdark-foreground-text: white;
  --iux-ng-mat-theme-offsetdark-foreground-slider-min: white;
  --iux-ng-mat-theme-offsetdark-foreground-slider-off: rgba(255, 255, 255, 0.3);
  --iux-ng-mat-theme-offsetdark-foreground-slider-off-active: rgba(255, 255, 255, 0.3);
  --iux-ng-mat-theme-offsetdark-background-status-bar: black;
  --iux-ng-mat-theme-offsetdark-background-app-bar: #212121;
  --iux-ng-mat-theme-offsetdark-background-background: #303030;
  --iux-ng-mat-theme-offsetdark-background-hover: rgba(255, 255, 255, 0.04);
  --iux-ng-mat-theme-offsetdark-background-card: #424242;
  --iux-ng-mat-theme-offsetdark-background-dialog: #424242;
  --iux-ng-mat-theme-offsetdark-background-disabled-button: rgba(255, 255, 255, 0.12);
  --iux-ng-mat-theme-offsetdark-background-raised-button: #424242;
  --iux-ng-mat-theme-offsetdark-background-focused-button: rgba(255, 255, 255, 0.12);
  --iux-ng-mat-theme-offsetdark-background-selected-button: #212121;
  --iux-ng-mat-theme-offsetdark-background-selected-disabled-button: #424242;
  --iux-ng-mat-theme-offsetdark-background-disabled-button-toggle: black;
  --iux-ng-mat-theme-offsetdark-background-unselected-chip: #616161;
  --iux-ng-mat-theme-offsetdark-background-disabled-list-option: rgba(255, 255, 255, 0.12);
  --iux-ng-mat-theme-offsetdark-background-tooltip: #616161;
}

@media (prefers-color-scheme: dark) {
  html {
    --mat-ripple-color: rgba(255, 255, 255, 0.1);
  }
  html {
    --mat-option-selected-state-label-text-color: #26bcd7;
    --mat-option-label-text-color: white;
    --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
    --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
    --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
  }
  .mat-accent {
    --mat-option-selected-state-label-text-color: #54b948;
    --mat-option-label-text-color: white;
    --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
    --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
    --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
  }
  .mat-warn {
    --mat-option-selected-state-label-text-color: #bb5f70;
    --mat-option-label-text-color: white;
    --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
    --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
    --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
  }
  html {
    --mat-optgroup-label-text-color: white;
  }
  .mat-primary {
    --mat-full-pseudo-checkbox-selected-icon-color: #26bcd7;
    --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
    --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
    --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
    --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
    --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
    --mat-minimal-pseudo-checkbox-selected-checkmark-color: #26bcd7;
    --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
  }
  html {
    --mat-full-pseudo-checkbox-selected-icon-color: #54b948;
    --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
    --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
    --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
    --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
    --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
    --mat-minimal-pseudo-checkbox-selected-checkmark-color: #54b948;
    --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
  }
  .mat-accent {
    --mat-full-pseudo-checkbox-selected-icon-color: #54b948;
    --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
    --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
    --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
    --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
    --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
    --mat-minimal-pseudo-checkbox-selected-checkmark-color: #54b948;
    --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
  }
  .mat-warn {
    --mat-full-pseudo-checkbox-selected-icon-color: #bb5f70;
    --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
    --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
    --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
    --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
    --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
    --mat-minimal-pseudo-checkbox-selected-checkmark-color: #bb5f70;
    --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
  }
  html {
    --mat-app-background-color: #303030;
    --mat-app-text-color: white;
  }
  .mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
    box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
    box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
    box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
    box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
    box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
    box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
    box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
    box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
    box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
    box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
    box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
    box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
    box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
    box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
    box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  }
  .mat-theme-loaded-marker {
    display: none;
  }
  html {
    --mat-ripple-color: rgba(255, 255, 255, 0.1);
  }
  html {
    --mat-option-selected-state-label-text-color: #26bcd7;
    --mat-option-label-text-color: white;
    --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
    --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
    --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
  }
  .mat-accent {
    --mat-option-selected-state-label-text-color: #54b948;
    --mat-option-label-text-color: white;
    --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
    --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
    --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
  }
  .mat-warn {
    --mat-option-selected-state-label-text-color: #bb5f70;
    --mat-option-label-text-color: white;
    --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
    --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
    --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
  }
  html {
    --mat-optgroup-label-text-color: white;
  }
  .mat-primary {
    --mat-full-pseudo-checkbox-selected-icon-color: #26bcd7;
    --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
    --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
    --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
    --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
    --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
    --mat-minimal-pseudo-checkbox-selected-checkmark-color: #26bcd7;
    --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
  }
  html {
    --mat-full-pseudo-checkbox-selected-icon-color: #54b948;
    --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
    --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
    --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
    --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
    --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
    --mat-minimal-pseudo-checkbox-selected-checkmark-color: #54b948;
    --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
  }
  .mat-accent {
    --mat-full-pseudo-checkbox-selected-icon-color: #54b948;
    --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
    --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
    --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
    --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
    --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
    --mat-minimal-pseudo-checkbox-selected-checkmark-color: #54b948;
    --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
  }
  .mat-warn {
    --mat-full-pseudo-checkbox-selected-icon-color: #bb5f70;
    --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
    --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
    --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
    --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
    --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
    --mat-minimal-pseudo-checkbox-selected-checkmark-color: #bb5f70;
    --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
  }
  html {
    --mat-app-background-color: #303030;
    --mat-app-text-color: white;
  }
  .mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
    box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
    box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
    box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
    box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
    box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
    box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
    box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
    box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
    box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
    box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
    box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
    box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
    box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
    box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
    box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  }
  .mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  }
  .mat-theme-loaded-marker {
    display: none;
  }
  html {
    --mdc-elevated-card-container-shape: 4px;
    --mdc-outlined-card-container-shape: 4px;
    --mdc-outlined-card-outline-width: 1px;
  }
  html {
    --mdc-elevated-card-container-color: #424242;
    --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    --mdc-outlined-card-container-color: #424242;
    --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
    --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    --mat-card-subtitle-text-color: rgba(255, 255, 255, 0.7);
  }
  html {
    --mdc-linear-progress-active-indicator-height: 4px;
    --mdc-linear-progress-track-height: 4px;
    --mdc-linear-progress-track-shape: 0;
  }
  .mat-mdc-progress-bar {
    --mdc-linear-progress-active-indicator-color: #26bcd7;
    --mdc-linear-progress-track-color: rgba(38, 188, 215, 0.25);
  }
  .mat-mdc-progress-bar.mat-accent {
    --mdc-linear-progress-active-indicator-color: #54b948;
    --mdc-linear-progress-track-color: rgba(84, 185, 72, 0.25);
  }
  .mat-mdc-progress-bar.mat-warn {
    --mdc-linear-progress-active-indicator-color: #bb5f70;
    --mdc-linear-progress-track-color: rgba(187, 95, 112, 0.25);
  }
  html {
    --mdc-plain-tooltip-container-shape: 4px;
    --mdc-plain-tooltip-supporting-text-line-height: 16px;
  }
  html {
    --mdc-plain-tooltip-container-color: #616161;
    --mdc-plain-tooltip-supporting-text-color: #fff;
  }
  html {
    --mdc-filled-text-field-active-indicator-height: 1px;
    --mdc-filled-text-field-focus-active-indicator-height: 2px;
    --mdc-filled-text-field-container-shape: 4px;
    --mdc-outlined-text-field-outline-width: 1px;
    --mdc-outlined-text-field-focus-outline-width: 2px;
    --mdc-outlined-text-field-container-shape: 4px;
  }
  html {
    --mdc-filled-text-field-caret-color: #26bcd7;
    --mdc-filled-text-field-focus-active-indicator-color: #26bcd7;
    --mdc-filled-text-field-focus-label-text-color: rgba(38, 188, 215, 0.87);
    --mdc-filled-text-field-container-color: #4a4a4a;
    --mdc-filled-text-field-disabled-container-color: #464646;
    --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, 0.6);
    --mdc-filled-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
    --mdc-filled-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
    --mdc-filled-text-field-input-text-color: rgba(255, 255, 255, 0.87);
    --mdc-filled-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
    --mdc-filled-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
    --mdc-filled-text-field-error-hover-label-text-color: #bb5f70;
    --mdc-filled-text-field-error-focus-label-text-color: #bb5f70;
    --mdc-filled-text-field-error-label-text-color: #bb5f70;
    --mdc-filled-text-field-error-caret-color: #bb5f70;
    --mdc-filled-text-field-active-indicator-color: rgba(255, 255, 255, 0.42);
    --mdc-filled-text-field-disabled-active-indicator-color: rgba(255, 255, 255, 0.06);
    --mdc-filled-text-field-hover-active-indicator-color: rgba(255, 255, 255, 0.87);
    --mdc-filled-text-field-error-active-indicator-color: #bb5f70;
    --mdc-filled-text-field-error-focus-active-indicator-color: #bb5f70;
    --mdc-filled-text-field-error-hover-active-indicator-color: #bb5f70;
    --mdc-outlined-text-field-caret-color: #26bcd7;
    --mdc-outlined-text-field-focus-outline-color: #26bcd7;
    --mdc-outlined-text-field-focus-label-text-color: rgba(38, 188, 215, 0.87);
    --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
    --mdc-outlined-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
    --mdc-outlined-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
    --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
    --mdc-outlined-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
    --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
    --mdc-outlined-text-field-error-caret-color: #bb5f70;
    --mdc-outlined-text-field-error-focus-label-text-color: #bb5f70;
    --mdc-outlined-text-field-error-label-text-color: #bb5f70;
    --mdc-outlined-text-field-error-hover-label-text-color: #bb5f70;
    --mdc-outlined-text-field-outline-color: rgba(255, 255, 255, 0.38);
    --mdc-outlined-text-field-disabled-outline-color: rgba(255, 255, 255, 0.06);
    --mdc-outlined-text-field-hover-outline-color: rgba(255, 255, 255, 0.87);
    --mdc-outlined-text-field-error-focus-outline-color: #bb5f70;
    --mdc-outlined-text-field-error-hover-outline-color: #bb5f70;
    --mdc-outlined-text-field-error-outline-color: #bb5f70;
    --mat-form-field-focus-select-arrow-color: rgba(38, 188, 215, 0.87);
    --mat-form-field-disabled-input-text-placeholder-color: rgba(255, 255, 255, 0.38);
    --mat-form-field-state-layer-color: rgba(255, 255, 255, 0.87);
    --mat-form-field-error-text-color: #bb5f70;
    --mat-form-field-select-option-text-color: rgba(0, 0, 0, 0.87);
    --mat-form-field-select-disabled-option-text-color: rgba(0, 0, 0, 0.38);
    --mat-form-field-leading-icon-color: unset;
    --mat-form-field-disabled-leading-icon-color: unset;
    --mat-form-field-trailing-icon-color: unset;
    --mat-form-field-disabled-trailing-icon-color: unset;
    --mat-form-field-error-focus-trailing-icon-color: unset;
    --mat-form-field-error-hover-trailing-icon-color: unset;
    --mat-form-field-error-trailing-icon-color: unset;
    --mat-form-field-enabled-select-arrow-color: rgba(255, 255, 255, 0.54);
    --mat-form-field-disabled-select-arrow-color: rgba(255, 255, 255, 0.38);
    --mat-form-field-hover-state-layer-opacity: 0.08;
    --mat-form-field-focus-state-layer-opacity: 0.24;
  }
  .mat-mdc-form-field.mat-accent {
    --mdc-filled-text-field-caret-color: #54b948;
    --mdc-filled-text-field-focus-active-indicator-color: #54b948;
    --mdc-filled-text-field-focus-label-text-color: rgba(84, 185, 72, 0.87);
    --mdc-outlined-text-field-caret-color: #54b948;
    --mdc-outlined-text-field-focus-outline-color: #54b948;
    --mdc-outlined-text-field-focus-label-text-color: rgba(84, 185, 72, 0.87);
    --mat-form-field-focus-select-arrow-color: rgba(84, 185, 72, 0.87);
  }
  .mat-mdc-form-field.mat-warn {
    --mdc-filled-text-field-caret-color: #bb5f70;
    --mdc-filled-text-field-focus-active-indicator-color: #bb5f70;
    --mdc-filled-text-field-focus-label-text-color: rgba(187, 95, 112, 0.87);
    --mdc-outlined-text-field-caret-color: #bb5f70;
    --mdc-outlined-text-field-focus-outline-color: #bb5f70;
    --mdc-outlined-text-field-focus-label-text-color: rgba(187, 95, 112, 0.87);
    --mat-form-field-focus-select-arrow-color: rgba(187, 95, 112, 0.87);
  }
  html {
    --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
  html {
    --mat-select-panel-background-color: #424242;
    --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
    --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
    --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
    --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
    --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
    --mat-select-focused-arrow-color: rgba(38, 188, 215, 0.87);
    --mat-select-invalid-arrow-color: rgba(187, 95, 112, 0.87);
  }
  html .mat-mdc-form-field.mat-accent {
    --mat-select-panel-background-color: #424242;
    --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
    --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
    --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
    --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
    --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
    --mat-select-focused-arrow-color: rgba(84, 185, 72, 0.87);
    --mat-select-invalid-arrow-color: rgba(187, 95, 112, 0.87);
  }
  html .mat-mdc-form-field.mat-warn {
    --mat-select-panel-background-color: #424242;
    --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
    --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
    --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
    --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
    --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
    --mat-select-focused-arrow-color: rgba(187, 95, 112, 0.87);
    --mat-select-invalid-arrow-color: rgba(187, 95, 112, 0.87);
  }
  html {
    --mat-autocomplete-container-shape: 4px;
    --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
  html {
    --mat-autocomplete-background-color: #424242;
  }
  html {
    --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    --mdc-dialog-container-shadow-color: #000;
    --mdc-dialog-container-shape: 4px;
    --mat-dialog-container-max-width: 80vw;
    --mat-dialog-container-small-max-width: 80vw;
    --mat-dialog-container-min-width: 0;
    --mat-dialog-actions-alignment: start;
    --mat-dialog-actions-padding: 8px;
    --mat-dialog-content-padding: 20px 24px;
    --mat-dialog-with-actions-content-padding: 20px 24px;
    --mat-dialog-headline-padding: 0 24px 9px;
  }
  html {
    --mdc-dialog-container-color: #424242;
    --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
    --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
  }
  .mat-mdc-standard-chip {
    --mdc-chip-container-shape-family: rounded;
    --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
    --mdc-chip-with-avatar-avatar-shape-family: rounded;
    --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
    --mdc-chip-with-avatar-avatar-size: 28px;
    --mdc-chip-with-icon-icon-size: 18px;
    --mdc-chip-outline-width: 0;
    --mdc-chip-outline-color: transparent;
    --mdc-chip-disabled-outline-color: transparent;
    --mdc-chip-focus-outline-color: transparent;
    --mdc-chip-hover-state-layer-opacity: 0.04;
    --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
    --mdc-chip-flat-selected-outline-width: 0;
    --mdc-chip-selected-hover-state-layer-opacity: 0.04;
    --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
    --mdc-chip-with-icon-disabled-icon-opacity: 1;
    --mat-chip-disabled-container-opacity: 0.4;
    --mat-chip-trailing-action-opacity: 0.54;
    --mat-chip-trailing-action-focus-opacity: 1;
    --mat-chip-trailing-action-state-layer-color: transparent;
    --mat-chip-selected-trailing-action-state-layer-color: transparent;
    --mat-chip-trailing-action-hover-state-layer-opacity: 0;
    --mat-chip-trailing-action-focus-state-layer-opacity: 0;
  }
  .mat-mdc-standard-chip {
    --mdc-chip-disabled-label-text-color: #fafafa;
    --mdc-chip-elevated-container-color: #595959;
    --mdc-chip-elevated-selected-container-color: #595959;
    --mdc-chip-elevated-disabled-container-color: #595959;
    --mdc-chip-flat-disabled-selected-container-color: #595959;
    --mdc-chip-focus-state-layer-color: white;
    --mdc-chip-hover-state-layer-color: white;
    --mdc-chip-selected-hover-state-layer-color: white;
    --mdc-chip-focus-state-layer-opacity: 0.12;
    --mdc-chip-selected-focus-state-layer-color: white;
    --mdc-chip-selected-focus-state-layer-opacity: 0.12;
    --mdc-chip-label-text-color: #fafafa;
    --mdc-chip-selected-label-text-color: #fafafa;
    --mdc-chip-with-icon-icon-color: #fafafa;
    --mdc-chip-with-icon-disabled-icon-color: #fafafa;
    --mdc-chip-with-icon-selected-icon-color: #fafafa;
    --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
    --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
    --mat-chip-selected-disabled-trailing-icon-color: #fafafa;
    --mat-chip-selected-trailing-icon-color: #fafafa;
  }
  .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
    --mdc-chip-disabled-label-text-color: rgba(0, 0, 0, 0.87);
    --mdc-chip-elevated-container-color: #26bcd7;
    --mdc-chip-elevated-selected-container-color: #26bcd7;
    --mdc-chip-elevated-disabled-container-color: #26bcd7;
    --mdc-chip-flat-disabled-selected-container-color: #26bcd7;
    --mdc-chip-focus-state-layer-color: white;
    --mdc-chip-hover-state-layer-color: white;
    --mdc-chip-selected-hover-state-layer-color: white;
    --mdc-chip-focus-state-layer-opacity: 0.12;
    --mdc-chip-selected-focus-state-layer-color: white;
    --mdc-chip-selected-focus-state-layer-opacity: 0.12;
    --mdc-chip-label-text-color: rgba(0, 0, 0, 0.87);
    --mdc-chip-selected-label-text-color: rgba(0, 0, 0, 0.87);
    --mdc-chip-with-icon-icon-color: rgba(0, 0, 0, 0.87);
    --mdc-chip-with-icon-disabled-icon-color: rgba(0, 0, 0, 0.87);
    --mdc-chip-with-icon-selected-icon-color: rgba(0, 0, 0, 0.87);
    --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
    --mdc-chip-with-trailing-icon-trailing-icon-color: rgba(0, 0, 0, 0.87);
    --mat-chip-selected-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
    --mat-chip-selected-trailing-icon-color: rgba(0, 0, 0, 0.87);
  }
  .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
    --mdc-chip-disabled-label-text-color: rgba(0, 0, 0, 0.87);
    --mdc-chip-elevated-container-color: #54b948;
    --mdc-chip-elevated-selected-container-color: #54b948;
    --mdc-chip-elevated-disabled-container-color: #54b948;
    --mdc-chip-flat-disabled-selected-container-color: #54b948;
    --mdc-chip-focus-state-layer-color: white;
    --mdc-chip-hover-state-layer-color: white;
    --mdc-chip-selected-hover-state-layer-color: white;
    --mdc-chip-focus-state-layer-opacity: 0.12;
    --mdc-chip-selected-focus-state-layer-color: white;
    --mdc-chip-selected-focus-state-layer-opacity: 0.12;
    --mdc-chip-label-text-color: rgba(0, 0, 0, 0.87);
    --mdc-chip-selected-label-text-color: rgba(0, 0, 0, 0.87);
    --mdc-chip-with-icon-icon-color: rgba(0, 0, 0, 0.87);
    --mdc-chip-with-icon-disabled-icon-color: rgba(0, 0, 0, 0.87);
    --mdc-chip-with-icon-selected-icon-color: rgba(0, 0, 0, 0.87);
    --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
    --mdc-chip-with-trailing-icon-trailing-icon-color: rgba(0, 0, 0, 0.87);
    --mat-chip-selected-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
    --mat-chip-selected-trailing-icon-color: rgba(0, 0, 0, 0.87);
  }
  .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
    --mdc-chip-disabled-label-text-color: white;
    --mdc-chip-elevated-container-color: #bb5f70;
    --mdc-chip-elevated-selected-container-color: #bb5f70;
    --mdc-chip-elevated-disabled-container-color: #bb5f70;
    --mdc-chip-flat-disabled-selected-container-color: #bb5f70;
    --mdc-chip-focus-state-layer-color: white;
    --mdc-chip-hover-state-layer-color: white;
    --mdc-chip-selected-hover-state-layer-color: white;
    --mdc-chip-focus-state-layer-opacity: 0.12;
    --mdc-chip-selected-focus-state-layer-color: white;
    --mdc-chip-selected-focus-state-layer-opacity: 0.12;
    --mdc-chip-label-text-color: white;
    --mdc-chip-selected-label-text-color: white;
    --mdc-chip-with-icon-icon-color: white;
    --mdc-chip-with-icon-disabled-icon-color: white;
    --mdc-chip-with-icon-selected-icon-color: white;
    --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
    --mdc-chip-with-trailing-icon-trailing-icon-color: white;
    --mat-chip-selected-disabled-trailing-icon-color: white;
    --mat-chip-selected-trailing-icon-color: white;
  }
  html {
    --mdc-switch-disabled-selected-icon-opacity: 0.38;
    --mdc-switch-disabled-track-opacity: 0.12;
    --mdc-switch-disabled-unselected-icon-opacity: 0.38;
    --mdc-switch-handle-height: 20px;
    --mdc-switch-handle-shape: 10px;
    --mdc-switch-handle-width: 20px;
    --mdc-switch-selected-icon-size: 18px;
    --mdc-switch-track-height: 14px;
    --mdc-switch-track-shape: 7px;
    --mdc-switch-track-width: 36px;
    --mdc-switch-unselected-icon-size: 18px;
    --mdc-switch-selected-focus-state-layer-opacity: 0.12;
    --mdc-switch-selected-hover-state-layer-opacity: 0.04;
    --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
    --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
    --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
    --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
    --mat-switch-disabled-selected-handle-opacity: 0.38;
    --mat-switch-disabled-unselected-handle-opacity: 0.38;
    --mat-switch-unselected-handle-size: 20px;
    --mat-switch-selected-handle-size: 20px;
    --mat-switch-pressed-handle-size: 20px;
    --mat-switch-with-icon-handle-size: 20px;
    --mat-switch-selected-handle-horizontal-margin: 0;
    --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
    --mat-switch-selected-pressed-handle-horizontal-margin: 0;
    --mat-switch-unselected-handle-horizontal-margin: 0;
    --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
    --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
    --mat-switch-visible-track-opacity: 1;
    --mat-switch-hidden-track-opacity: 1;
    --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
    --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
    --mat-switch-track-outline-width: 1px;
    --mat-switch-track-outline-color: transparent;
    --mat-switch-disabled-unselected-track-outline-width: 1px;
    --mat-switch-disabled-unselected-track-outline-color: transparent;
  }
  html {
    --mdc-switch-selected-focus-state-layer-color: #67d0e3;
    --mdc-switch-selected-handle-color: #67d0e3;
    --mdc-switch-selected-hover-state-layer-color: #67d0e3;
    --mdc-switch-selected-pressed-state-layer-color: #67d0e3;
    --mdc-switch-selected-focus-handle-color: #93deeb;
    --mdc-switch-selected-hover-handle-color: #93deeb;
    --mdc-switch-selected-pressed-handle-color: #93deeb;
    --mdc-switch-selected-focus-track-color: #22b6d3;
    --mdc-switch-selected-hover-track-color: #22b6d3;
    --mdc-switch-selected-pressed-track-color: #22b6d3;
    --mdc-switch-selected-track-color: #22b6d3;
    --mdc-switch-disabled-selected-handle-color: #000;
    --mdc-switch-disabled-selected-icon-color: #212121;
    --mdc-switch-disabled-selected-track-color: #f5f5f5;
    --mdc-switch-disabled-unselected-handle-color: #000;
    --mdc-switch-disabled-unselected-icon-color: #212121;
    --mdc-switch-disabled-unselected-track-color: #f5f5f5;
    --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
    --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    --mdc-switch-handle-shadow-color: black;
    --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    --mdc-switch-selected-icon-color: #212121;
    --mdc-switch-unselected-focus-handle-color: #fafafa;
    --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
    --mdc-switch-unselected-focus-track-color: #616161;
    --mdc-switch-unselected-handle-color: #9e9e9e;
    --mdc-switch-unselected-hover-handle-color: #fafafa;
    --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
    --mdc-switch-unselected-hover-track-color: #616161;
    --mdc-switch-unselected-icon-color: #212121;
    --mdc-switch-unselected-pressed-handle-color: #fafafa;
    --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
    --mdc-switch-unselected-pressed-track-color: #616161;
    --mdc-switch-unselected-track-color: #616161;
    --mdc-switch-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  }
  html .mat-mdc-slide-toggle {
    --mdc-form-field-label-text-color: white;
  }
  html .mat-mdc-slide-toggle.mat-accent {
    --mdc-switch-selected-focus-state-layer-color: #87ce7f;
    --mdc-switch-selected-handle-color: #87ce7f;
    --mdc-switch-selected-hover-state-layer-color: #87ce7f;
    --mdc-switch-selected-pressed-state-layer-color: #87ce7f;
    --mdc-switch-selected-focus-handle-color: #aadca4;
    --mdc-switch-selected-hover-handle-color: #aadca4;
    --mdc-switch-selected-pressed-handle-color: #aadca4;
    --mdc-switch-selected-focus-track-color: #4db241;
    --mdc-switch-selected-hover-track-color: #4db241;
    --mdc-switch-selected-pressed-track-color: #4db241;
    --mdc-switch-selected-track-color: #4db241;
  }
  html .mat-mdc-slide-toggle.mat-warn {
    --mdc-switch-selected-focus-state-layer-color: #cf8f9b;
    --mdc-switch-selected-handle-color: #cf8f9b;
    --mdc-switch-selected-hover-state-layer-color: #cf8f9b;
    --mdc-switch-selected-pressed-state-layer-color: #cf8f9b;
    --mdc-switch-selected-focus-handle-color: #ddafb8;
    --mdc-switch-selected-hover-handle-color: #ddafb8;
    --mdc-switch-selected-pressed-handle-color: #ddafb8;
    --mdc-switch-selected-focus-track-color: #b55768;
    --mdc-switch-selected-hover-track-color: #b55768;
    --mdc-switch-selected-pressed-track-color: #b55768;
    --mdc-switch-selected-track-color: #b55768;
  }
  html {
    --mdc-radio-disabled-selected-icon-opacity: 0.38;
    --mdc-radio-disabled-unselected-icon-opacity: 0.38;
    --mdc-radio-state-layer-size: 40px;
  }
  .mat-mdc-radio-button {
    --mdc-form-field-label-text-color: white;
  }
  .mat-mdc-radio-button.mat-primary {
    --mdc-radio-disabled-selected-icon-color: #fff;
    --mdc-radio-disabled-unselected-icon-color: #fff;
    --mdc-radio-unselected-hover-icon-color: #eeeeee;
    --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
    --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
    --mdc-radio-selected-focus-icon-color: #26bcd7;
    --mdc-radio-selected-hover-icon-color: #26bcd7;
    --mdc-radio-selected-icon-color: #26bcd7;
    --mdc-radio-selected-pressed-icon-color: #26bcd7;
    --mat-radio-ripple-color: #fff;
    --mat-radio-checked-ripple-color: #26bcd7;
    --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
  }
  .mat-mdc-radio-button.mat-accent {
    --mdc-radio-disabled-selected-icon-color: #fff;
    --mdc-radio-disabled-unselected-icon-color: #fff;
    --mdc-radio-unselected-hover-icon-color: #eeeeee;
    --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
    --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
    --mdc-radio-selected-focus-icon-color: #54b948;
    --mdc-radio-selected-hover-icon-color: #54b948;
    --mdc-radio-selected-icon-color: #54b948;
    --mdc-radio-selected-pressed-icon-color: #54b948;
    --mat-radio-ripple-color: #fff;
    --mat-radio-checked-ripple-color: #54b948;
    --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
  }
  .mat-mdc-radio-button.mat-warn {
    --mdc-radio-disabled-selected-icon-color: #fff;
    --mdc-radio-disabled-unselected-icon-color: #fff;
    --mdc-radio-unselected-hover-icon-color: #eeeeee;
    --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
    --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
    --mdc-radio-selected-focus-icon-color: #bb5f70;
    --mdc-radio-selected-hover-icon-color: #bb5f70;
    --mdc-radio-selected-icon-color: #bb5f70;
    --mdc-radio-selected-pressed-icon-color: #bb5f70;
    --mat-radio-ripple-color: #fff;
    --mat-radio-checked-ripple-color: #bb5f70;
    --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
  }
  html {
    --mat-slider-value-indicator-width: auto;
    --mat-slider-value-indicator-height: 32px;
    --mat-slider-value-indicator-caret-display: block;
    --mat-slider-value-indicator-border-radius: 4px;
    --mat-slider-value-indicator-padding: 0 12px;
    --mat-slider-value-indicator-text-transform: none;
    --mat-slider-value-indicator-container-transform: translateX(-50%);
    --mdc-slider-active-track-height: 6px;
    --mdc-slider-active-track-shape: 9999px;
    --mdc-slider-handle-height: 20px;
    --mdc-slider-handle-shape: 50%;
    --mdc-slider-handle-width: 20px;
    --mdc-slider-inactive-track-height: 4px;
    --mdc-slider-inactive-track-shape: 9999px;
    --mdc-slider-with-overlap-handle-outline-width: 1px;
    --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
    --mdc-slider-with-tick-marks-container-shape: 50%;
    --mdc-slider-with-tick-marks-container-size: 2px;
    --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  }
  html {
    --mdc-slider-handle-color: #26bcd7;
    --mdc-slider-focus-handle-color: #26bcd7;
    --mdc-slider-hover-handle-color: #26bcd7;
    --mdc-slider-active-track-color: #26bcd7;
    --mdc-slider-inactive-track-color: #26bcd7;
    --mdc-slider-with-tick-marks-inactive-container-color: #26bcd7;
    --mdc-slider-with-tick-marks-active-container-color: rgba(0, 0, 0, 0.87);
    --mdc-slider-disabled-active-track-color: #fff;
    --mdc-slider-disabled-handle-color: #fff;
    --mdc-slider-disabled-inactive-track-color: #fff;
    --mdc-slider-label-container-color: #fff;
    --mdc-slider-label-label-text-color: #000;
    --mdc-slider-with-overlap-handle-outline-color: #fff;
    --mdc-slider-with-tick-marks-disabled-container-color: #fff;
    --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    --mat-slider-ripple-color: #26bcd7;
    --mat-slider-hover-state-layer-color: rgba(38, 188, 215, 0.05);
    --mat-slider-focus-state-layer-color: rgba(38, 188, 215, 0.2);
    --mat-slider-value-indicator-opacity: 0.9;
  }
  html .mat-accent {
    --mat-slider-ripple-color: #54b948;
    --mat-slider-hover-state-layer-color: rgba(84, 185, 72, 0.05);
    --mat-slider-focus-state-layer-color: rgba(84, 185, 72, 0.2);
    --mdc-slider-handle-color: #54b948;
    --mdc-slider-focus-handle-color: #54b948;
    --mdc-slider-hover-handle-color: #54b948;
    --mdc-slider-active-track-color: #54b948;
    --mdc-slider-inactive-track-color: #54b948;
    --mdc-slider-with-tick-marks-inactive-container-color: #54b948;
    --mdc-slider-with-tick-marks-active-container-color: rgba(0, 0, 0, 0.87);
  }
  html .mat-warn {
    --mat-slider-ripple-color: #bb5f70;
    --mat-slider-hover-state-layer-color: rgba(187, 95, 112, 0.05);
    --mat-slider-focus-state-layer-color: rgba(187, 95, 112, 0.2);
    --mdc-slider-handle-color: #bb5f70;
    --mdc-slider-focus-handle-color: #bb5f70;
    --mdc-slider-hover-handle-color: #bb5f70;
    --mdc-slider-active-track-color: #bb5f70;
    --mdc-slider-inactive-track-color: #bb5f70;
    --mdc-slider-with-tick-marks-inactive-container-color: #bb5f70;
    --mdc-slider-with-tick-marks-active-container-color: white;
  }
  html {
    --mat-menu-container-shape: 4px;
    --mat-menu-divider-bottom-spacing: 0;
    --mat-menu-divider-top-spacing: 0;
    --mat-menu-item-spacing: 16px;
    --mat-menu-item-icon-size: 24px;
    --mat-menu-item-leading-spacing: 16px;
    --mat-menu-item-trailing-spacing: 16px;
    --mat-menu-item-with-icon-leading-spacing: 16px;
    --mat-menu-item-with-icon-trailing-spacing: 16px;
  }
  html {
    --mat-menu-item-label-text-color: white;
    --mat-menu-item-icon-color: white;
    --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.08);
    --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.08);
    --mat-menu-container-color: #424242;
    --mat-menu-divider-color: rgba(255, 255, 255, 0.12);
  }
  html {
    --mdc-list-list-item-container-shape: 0;
    --mdc-list-list-item-leading-avatar-shape: 50%;
    --mdc-list-list-item-container-color: transparent;
    --mdc-list-list-item-selected-container-color: transparent;
    --mdc-list-list-item-leading-avatar-color: transparent;
    --mdc-list-list-item-leading-icon-size: 24px;
    --mdc-list-list-item-leading-avatar-size: 40px;
    --mdc-list-list-item-trailing-icon-size: 24px;
    --mdc-list-list-item-disabled-state-layer-color: transparent;
    --mdc-list-list-item-disabled-state-layer-opacity: 0;
    --mdc-list-list-item-disabled-label-text-opacity: 0.38;
    --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
    --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
    --mat-list-active-indicator-color: transparent;
    --mat-list-active-indicator-shape: 0;
  }
  html {
    --mdc-list-list-item-label-text-color: white;
    --mdc-list-list-item-supporting-text-color: rgba(255, 255, 255, 0.7);
    --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
    --mdc-list-list-item-trailing-supporting-text-color: rgba(255, 255, 255, 0.5);
    --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
    --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
    --mdc-list-list-item-disabled-label-text-color: white;
    --mdc-list-list-item-disabled-leading-icon-color: white;
    --mdc-list-list-item-disabled-trailing-icon-color: white;
    --mdc-list-list-item-hover-label-text-color: white;
    --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
    --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
    --mdc-list-list-item-focus-label-text-color: white;
    --mdc-list-list-item-hover-state-layer-color: white;
    --mdc-list-list-item-hover-state-layer-opacity: 0.08;
    --mdc-list-list-item-focus-state-layer-color: white;
    --mdc-list-list-item-focus-state-layer-opacity: 0.24;
  }
  .mdc-list-item__start,
  .mdc-list-item__end {
    --mdc-radio-disabled-selected-icon-color: #fff;
    --mdc-radio-disabled-unselected-icon-color: #fff;
    --mdc-radio-unselected-hover-icon-color: #eeeeee;
    --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
    --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
    --mdc-radio-selected-focus-icon-color: #26bcd7;
    --mdc-radio-selected-hover-icon-color: #26bcd7;
    --mdc-radio-selected-icon-color: #26bcd7;
    --mdc-radio-selected-pressed-icon-color: #26bcd7;
  }
  .mat-accent .mdc-list-item__start,
  .mat-accent .mdc-list-item__end {
    --mdc-radio-disabled-selected-icon-color: #fff;
    --mdc-radio-disabled-unselected-icon-color: #fff;
    --mdc-radio-unselected-hover-icon-color: #eeeeee;
    --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
    --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
    --mdc-radio-selected-focus-icon-color: #54b948;
    --mdc-radio-selected-hover-icon-color: #54b948;
    --mdc-radio-selected-icon-color: #54b948;
    --mdc-radio-selected-pressed-icon-color: #54b948;
  }
  .mat-warn .mdc-list-item__start,
  .mat-warn .mdc-list-item__end {
    --mdc-radio-disabled-selected-icon-color: #fff;
    --mdc-radio-disabled-unselected-icon-color: #fff;
    --mdc-radio-unselected-hover-icon-color: #eeeeee;
    --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
    --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
    --mdc-radio-selected-focus-icon-color: #bb5f70;
    --mdc-radio-selected-hover-icon-color: #bb5f70;
    --mdc-radio-selected-icon-color: #bb5f70;
    --mdc-radio-selected-pressed-icon-color: #bb5f70;
  }
  .mat-mdc-list-option {
    --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
    --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
    --mdc-checkbox-selected-checkmark-color: #000;
    --mdc-checkbox-selected-focus-icon-color: #26bcd7;
    --mdc-checkbox-selected-hover-icon-color: #26bcd7;
    --mdc-checkbox-selected-icon-color: #26bcd7;
    --mdc-checkbox-selected-pressed-icon-color: #26bcd7;
    --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
    --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
    --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
    --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
    --mdc-checkbox-selected-focus-state-layer-color: #26bcd7;
    --mdc-checkbox-selected-hover-state-layer-color: #26bcd7;
    --mdc-checkbox-selected-pressed-state-layer-color: #26bcd7;
    --mdc-checkbox-unselected-focus-state-layer-color: white;
    --mdc-checkbox-unselected-hover-state-layer-color: white;
    --mdc-checkbox-unselected-pressed-state-layer-color: white;
  }
  .mat-mdc-list-option.mat-accent {
    --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
    --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
    --mdc-checkbox-selected-checkmark-color: #000;
    --mdc-checkbox-selected-focus-icon-color: #54b948;
    --mdc-checkbox-selected-hover-icon-color: #54b948;
    --mdc-checkbox-selected-icon-color: #54b948;
    --mdc-checkbox-selected-pressed-icon-color: #54b948;
    --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
    --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
    --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
    --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
    --mdc-checkbox-selected-focus-state-layer-color: #54b948;
    --mdc-checkbox-selected-hover-state-layer-color: #54b948;
    --mdc-checkbox-selected-pressed-state-layer-color: #54b948;
    --mdc-checkbox-unselected-focus-state-layer-color: white;
    --mdc-checkbox-unselected-hover-state-layer-color: white;
    --mdc-checkbox-unselected-pressed-state-layer-color: white;
  }
  .mat-mdc-list-option.mat-warn {
    --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
    --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
    --mdc-checkbox-selected-checkmark-color: #fff;
    --mdc-checkbox-selected-focus-icon-color: #bb5f70;
    --mdc-checkbox-selected-hover-icon-color: #bb5f70;
    --mdc-checkbox-selected-icon-color: #bb5f70;
    --mdc-checkbox-selected-pressed-icon-color: #bb5f70;
    --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
    --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
    --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
    --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
    --mdc-checkbox-selected-focus-state-layer-color: #bb5f70;
    --mdc-checkbox-selected-hover-state-layer-color: #bb5f70;
    --mdc-checkbox-selected-pressed-state-layer-color: #bb5f70;
    --mdc-checkbox-unselected-focus-state-layer-color: white;
    --mdc-checkbox-unselected-hover-state-layer-color: white;
    --mdc-checkbox-unselected-pressed-state-layer-color: white;
  }
  .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
  .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
    color: #26bcd7;
  }
  .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
  .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
    color: #26bcd7;
  }
  .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
  .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
  .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
    opacity: 1;
  }
  html {
    --mat-paginator-container-text-color: rgba(255, 255, 255, 0.87);
    --mat-paginator-container-background-color: #424242;
    --mat-paginator-enabled-icon-color: rgba(255, 255, 255, 0.54);
    --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);
  }
  html {
    --mdc-tab-indicator-active-indicator-height: 2px;
    --mdc-tab-indicator-active-indicator-shape: 0;
    --mdc-secondary-navigation-tab-container-height: 48px;
    --mat-tab-header-divider-color: transparent;
    --mat-tab-header-divider-height: 0;
  }
  .mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
    --mdc-tab-indicator-active-indicator-color: #26bcd7;
    --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
    --mat-tab-header-pagination-icon-color: #fff;
    --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
    --mat-tab-header-active-label-text-color: #26bcd7;
    --mat-tab-header-active-ripple-color: #26bcd7;
    --mat-tab-header-inactive-ripple-color: #26bcd7;
    --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
    --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
    --mat-tab-header-active-focus-label-text-color: #26bcd7;
    --mat-tab-header-active-hover-label-text-color: #26bcd7;
    --mat-tab-header-active-focus-indicator-color: #26bcd7;
    --mat-tab-header-active-hover-indicator-color: #26bcd7;
  }
  .mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
    --mdc-tab-indicator-active-indicator-color: #54b948;
    --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
    --mat-tab-header-pagination-icon-color: #fff;
    --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
    --mat-tab-header-active-label-text-color: #54b948;
    --mat-tab-header-active-ripple-color: #54b948;
    --mat-tab-header-inactive-ripple-color: #54b948;
    --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
    --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
    --mat-tab-header-active-focus-label-text-color: #54b948;
    --mat-tab-header-active-hover-label-text-color: #54b948;
    --mat-tab-header-active-focus-indicator-color: #54b948;
    --mat-tab-header-active-hover-indicator-color: #54b948;
  }
  .mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
    --mdc-tab-indicator-active-indicator-color: #bb5f70;
    --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
    --mat-tab-header-pagination-icon-color: #fff;
    --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
    --mat-tab-header-active-label-text-color: #bb5f70;
    --mat-tab-header-active-ripple-color: #bb5f70;
    --mat-tab-header-inactive-ripple-color: #bb5f70;
    --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
    --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
    --mat-tab-header-active-focus-label-text-color: #bb5f70;
    --mat-tab-header-active-hover-label-text-color: #bb5f70;
    --mat-tab-header-active-focus-indicator-color: #bb5f70;
    --mat-tab-header-active-hover-indicator-color: #bb5f70;
  }
  .mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
    --mat-tab-header-with-background-background-color: #26bcd7;
    --mat-tab-header-with-background-foreground-color: rgba(0, 0, 0, 0.87);
  }
  .mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
    --mat-tab-header-with-background-background-color: #54b948;
    --mat-tab-header-with-background-foreground-color: rgba(0, 0, 0, 0.87);
  }
  .mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
    --mat-tab-header-with-background-background-color: #bb5f70;
    --mat-tab-header-with-background-foreground-color: white;
  }
  html {
    --mdc-checkbox-disabled-selected-checkmark-color: #fff;
    --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
    --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
    --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
    --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
    --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
    --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  }
  html {
    --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
    --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
    --mdc-checkbox-selected-checkmark-color: #000;
    --mdc-checkbox-selected-focus-icon-color: #54b948;
    --mdc-checkbox-selected-hover-icon-color: #54b948;
    --mdc-checkbox-selected-icon-color: #54b948;
    --mdc-checkbox-selected-pressed-icon-color: #54b948;
    --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
    --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
    --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
    --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
    --mdc-checkbox-selected-focus-state-layer-color: #54b948;
    --mdc-checkbox-selected-hover-state-layer-color: #54b948;
    --mdc-checkbox-selected-pressed-state-layer-color: #54b948;
    --mdc-checkbox-unselected-focus-state-layer-color: white;
    --mdc-checkbox-unselected-hover-state-layer-color: white;
    --mdc-checkbox-unselected-pressed-state-layer-color: white;
    --mat-checkbox-disabled-label-color: rgba(255, 255, 255, 0.5);
  }
  .mat-mdc-checkbox {
    --mdc-form-field-label-text-color: white;
  }
  .mat-mdc-checkbox.mat-primary {
    --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
    --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
    --mdc-checkbox-selected-checkmark-color: #000;
    --mdc-checkbox-selected-focus-icon-color: #26bcd7;
    --mdc-checkbox-selected-hover-icon-color: #26bcd7;
    --mdc-checkbox-selected-icon-color: #26bcd7;
    --mdc-checkbox-selected-pressed-icon-color: #26bcd7;
    --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
    --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
    --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
    --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
    --mdc-checkbox-selected-focus-state-layer-color: #26bcd7;
    --mdc-checkbox-selected-hover-state-layer-color: #26bcd7;
    --mdc-checkbox-selected-pressed-state-layer-color: #26bcd7;
    --mdc-checkbox-unselected-focus-state-layer-color: white;
    --mdc-checkbox-unselected-hover-state-layer-color: white;
    --mdc-checkbox-unselected-pressed-state-layer-color: white;
  }
  .mat-mdc-checkbox.mat-warn {
    --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
    --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
    --mdc-checkbox-selected-checkmark-color: #fff;
    --mdc-checkbox-selected-focus-icon-color: #bb5f70;
    --mdc-checkbox-selected-hover-icon-color: #bb5f70;
    --mdc-checkbox-selected-icon-color: #bb5f70;
    --mdc-checkbox-selected-pressed-icon-color: #bb5f70;
    --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
    --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
    --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
    --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
    --mdc-checkbox-selected-focus-state-layer-color: #bb5f70;
    --mdc-checkbox-selected-hover-state-layer-color: #bb5f70;
    --mdc-checkbox-selected-pressed-state-layer-color: #bb5f70;
    --mdc-checkbox-unselected-focus-state-layer-color: white;
    --mdc-checkbox-unselected-hover-state-layer-color: white;
    --mdc-checkbox-unselected-pressed-state-layer-color: white;
  }
  html {
    --mdc-text-button-container-shape: 4px;
    --mdc-text-button-keep-touch-target: false;
    --mdc-filled-button-container-shape: 4px;
    --mdc-filled-button-keep-touch-target: false;
    --mdc-protected-button-container-shape: 4px;
    --mdc-protected-button-keep-touch-target: false;
    --mdc-outlined-button-keep-touch-target: false;
    --mdc-outlined-button-outline-width: 1px;
    --mdc-outlined-button-container-shape: 4px;
    --mat-text-button-horizontal-padding: 8px;
    --mat-text-button-with-icon-horizontal-padding: 8px;
    --mat-text-button-icon-spacing: 8px;
    --mat-text-button-icon-offset: 0;
    --mat-filled-button-horizontal-padding: 16px;
    --mat-filled-button-icon-spacing: 8px;
    --mat-filled-button-icon-offset: -4px;
    --mat-protected-button-horizontal-padding: 16px;
    --mat-protected-button-icon-spacing: 8px;
    --mat-protected-button-icon-offset: -4px;
    --mat-outlined-button-horizontal-padding: 15px;
    --mat-outlined-button-icon-spacing: 8px;
    --mat-outlined-button-icon-offset: -4px;
  }
  html {
    --mdc-text-button-label-text-color: #fff;
    --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
    --mat-text-button-state-layer-color: #fff;
    --mat-text-button-disabled-state-layer-color: #fff;
    --mat-text-button-ripple-color: rgba(255, 255, 255, 0.1);
    --mat-text-button-hover-state-layer-opacity: 0.08;
    --mat-text-button-focus-state-layer-opacity: 0.24;
    --mat-text-button-pressed-state-layer-opacity: 0.24;
    --mdc-filled-button-container-color: #424242;
    --mdc-filled-button-label-text-color: #fff;
    --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
    --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
    --mat-filled-button-state-layer-color: #fff;
    --mat-filled-button-disabled-state-layer-color: #fff;
    --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
    --mat-filled-button-hover-state-layer-opacity: 0.08;
    --mat-filled-button-focus-state-layer-opacity: 0.24;
    --mat-filled-button-pressed-state-layer-opacity: 0.24;
    --mdc-protected-button-container-color: #424242;
    --mdc-protected-button-label-text-color: #fff;
    --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
    --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
    --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    --mdc-protected-button-container-shadow-color: #000;
    --mat-protected-button-state-layer-color: #fff;
    --mat-protected-button-disabled-state-layer-color: #fff;
    --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
    --mat-protected-button-hover-state-layer-opacity: 0.08;
    --mat-protected-button-focus-state-layer-opacity: 0.24;
    --mat-protected-button-pressed-state-layer-opacity: 0.24;
    --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
    --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
    --mdc-outlined-button-label-text-color: #fff;
    --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
    --mat-outlined-button-state-layer-color: #fff;
    --mat-outlined-button-disabled-state-layer-color: #fff;
    --mat-outlined-button-ripple-color: rgba(255, 255, 255, 0.1);
    --mat-outlined-button-hover-state-layer-opacity: 0.08;
    --mat-outlined-button-focus-state-layer-opacity: 0.24;
    --mat-outlined-button-pressed-state-layer-opacity: 0.24;
  }
  .mat-mdc-button.mat-primary {
    --mdc-text-button-label-text-color: #26bcd7;
    --mat-text-button-state-layer-color: #26bcd7;
    --mat-text-button-ripple-color: rgba(38, 188, 215, 0.1);
  }
  .mat-mdc-button.mat-accent {
    --mdc-text-button-label-text-color: #54b948;
    --mat-text-button-state-layer-color: #54b948;
    --mat-text-button-ripple-color: rgba(84, 185, 72, 0.1);
  }
  .mat-mdc-button.mat-warn {
    --mdc-text-button-label-text-color: #bb5f70;
    --mat-text-button-state-layer-color: #bb5f70;
    --mat-text-button-ripple-color: rgba(187, 95, 112, 0.1);
  }
  .mat-mdc-unelevated-button.mat-primary {
    --mdc-filled-button-container-color: #26bcd7;
    --mdc-filled-button-label-text-color: #000;
    --mat-filled-button-state-layer-color: #000;
    --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  }
  .mat-mdc-unelevated-button.mat-accent {
    --mdc-filled-button-container-color: #54b948;
    --mdc-filled-button-label-text-color: #000;
    --mat-filled-button-state-layer-color: #000;
    --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  }
  .mat-mdc-unelevated-button.mat-warn {
    --mdc-filled-button-container-color: #bb5f70;
    --mdc-filled-button-label-text-color: #fff;
    --mat-filled-button-state-layer-color: #fff;
    --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
  }
  .mat-mdc-raised-button.mat-primary {
    --mdc-protected-button-container-color: #26bcd7;
    --mdc-protected-button-label-text-color: #000;
    --mat-protected-button-state-layer-color: #000;
    --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  }
  .mat-mdc-raised-button.mat-accent {
    --mdc-protected-button-container-color: #54b948;
    --mdc-protected-button-label-text-color: #000;
    --mat-protected-button-state-layer-color: #000;
    --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  }
  .mat-mdc-raised-button.mat-warn {
    --mdc-protected-button-container-color: #bb5f70;
    --mdc-protected-button-label-text-color: #fff;
    --mat-protected-button-state-layer-color: #fff;
    --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
  }
  .mat-mdc-outlined-button.mat-primary {
    --mdc-outlined-button-label-text-color: #26bcd7;
    --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
    --mat-outlined-button-state-layer-color: #26bcd7;
    --mat-outlined-button-ripple-color: rgba(38, 188, 215, 0.1);
  }
  .mat-mdc-outlined-button.mat-accent {
    --mdc-outlined-button-label-text-color: #54b948;
    --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
    --mat-outlined-button-state-layer-color: #54b948;
    --mat-outlined-button-ripple-color: rgba(84, 185, 72, 0.1);
  }
  .mat-mdc-outlined-button.mat-warn {
    --mdc-outlined-button-label-text-color: #bb5f70;
    --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
    --mat-outlined-button-state-layer-color: #bb5f70;
    --mat-outlined-button-ripple-color: rgba(187, 95, 112, 0.1);
  }
  html {
    --mdc-icon-button-icon-size: 24px;
  }
  html {
    --mdc-icon-button-icon-color: inherit;
    --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
    --mat-icon-button-state-layer-color: #fff;
    --mat-icon-button-disabled-state-layer-color: #fff;
    --mat-icon-button-ripple-color: rgba(255, 255, 255, 0.1);
    --mat-icon-button-hover-state-layer-opacity: 0.08;
    --mat-icon-button-focus-state-layer-opacity: 0.24;
    --mat-icon-button-pressed-state-layer-opacity: 0.24;
  }
  html .mat-mdc-icon-button.mat-primary {
    --mdc-icon-button-icon-color: #26bcd7;
    --mat-icon-button-state-layer-color: #26bcd7;
    --mat-icon-button-ripple-color: rgba(38, 188, 215, 0.1);
  }
  html .mat-mdc-icon-button.mat-accent {
    --mdc-icon-button-icon-color: #54b948;
    --mat-icon-button-state-layer-color: #54b948;
    --mat-icon-button-ripple-color: rgba(84, 185, 72, 0.1);
  }
  html .mat-mdc-icon-button.mat-warn {
    --mdc-icon-button-icon-color: #bb5f70;
    --mat-icon-button-state-layer-color: #bb5f70;
    --mat-icon-button-ripple-color: rgba(187, 95, 112, 0.1);
  }
  html {
    --mdc-fab-container-shape: 50%;
    --mdc-fab-icon-size: 24px;
    --mdc-fab-small-container-shape: 50%;
    --mdc-fab-small-icon-size: 24px;
    --mdc-extended-fab-container-height: 48px;
    --mdc-extended-fab-container-shape: 24px;
  }
  html {
    --mdc-fab-container-color: #424242;
    --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
    --mdc-fab-container-shadow-color: #000;
    --mat-fab-foreground-color: white;
    --mat-fab-state-layer-color: #fff;
    --mat-fab-disabled-state-layer-color: #fff;
    --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
    --mat-fab-hover-state-layer-opacity: 0.08;
    --mat-fab-focus-state-layer-opacity: 0.24;
    --mat-fab-pressed-state-layer-opacity: 0.24;
    --mat-fab-disabled-state-container-color: rgba(255, 255, 255, 0.12);
    --mat-fab-disabled-state-foreground-color: rgba(255, 255, 255, 0.5);
    --mdc-fab-small-container-color: #424242;
    --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
    --mdc-fab-small-container-shadow-color: #000;
    --mat-fab-small-foreground-color: white;
    --mat-fab-small-state-layer-color: #fff;
    --mat-fab-small-disabled-state-layer-color: #fff;
    --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
    --mat-fab-small-hover-state-layer-opacity: 0.08;
    --mat-fab-small-focus-state-layer-opacity: 0.24;
    --mat-fab-small-pressed-state-layer-opacity: 0.24;
    --mat-fab-small-disabled-state-container-color: rgba(255, 255, 255, 0.12);
    --mat-fab-small-disabled-state-foreground-color: rgba(255, 255, 255, 0.5);
    --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
    --mdc-extended-fab-container-shadow-color: #000;
  }
  html .mat-mdc-fab.mat-primary {
    --mdc-fab-container-color: #26bcd7;
    --mat-fab-foreground-color: #000;
    --mat-fab-state-layer-color: #000;
    --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  }
  html .mat-mdc-fab.mat-accent {
    --mdc-fab-container-color: #54b948;
    --mat-fab-foreground-color: #000;
    --mat-fab-state-layer-color: #000;
    --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  }
  html .mat-mdc-fab.mat-warn {
    --mdc-fab-container-color: #bb5f70;
    --mat-fab-foreground-color: #fff;
    --mat-fab-state-layer-color: #fff;
    --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
  }
  html .mat-mdc-mini-fab.mat-primary {
    --mdc-fab-small-container-color: #26bcd7;
    --mat-fab-small-foreground-color: #000;
    --mat-fab-small-state-layer-color: #000;
    --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  }
  html .mat-mdc-mini-fab.mat-accent {
    --mdc-fab-small-container-color: #54b948;
    --mat-fab-small-foreground-color: #000;
    --mat-fab-small-state-layer-color: #000;
    --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  }
  html .mat-mdc-mini-fab.mat-warn {
    --mdc-fab-small-container-color: #bb5f70;
    --mat-fab-small-foreground-color: #fff;
    --mat-fab-small-state-layer-color: #fff;
    --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
  }
  html {
    --mdc-snackbar-container-shape: 4px;
  }
  html {
    --mdc-snackbar-container-color: #d9d9d9;
    --mdc-snackbar-supporting-text-color: rgba(66, 66, 66, 0.87);
    --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
  }
  html {
    --mat-table-row-item-outline-width: 1px;
  }
  html {
    --mat-table-background-color: #424242;
    --mat-table-header-headline-color: white;
    --mat-table-row-item-label-text-color: white;
    --mat-table-row-item-outline-color: rgba(255, 255, 255, 0.12);
  }
  html {
    --mdc-circular-progress-active-indicator-width: 4px;
    --mdc-circular-progress-size: 48px;
  }
  html {
    --mdc-circular-progress-active-indicator-color: #26bcd7;
  }
  html .mat-accent {
    --mdc-circular-progress-active-indicator-color: #54b948;
  }
  html .mat-warn {
    --mdc-circular-progress-active-indicator-color: #bb5f70;
  }
  html {
    --mat-badge-container-shape: 50%;
    --mat-badge-container-size: unset;
    --mat-badge-small-size-container-size: unset;
    --mat-badge-large-size-container-size: unset;
    --mat-badge-legacy-container-size: 22px;
    --mat-badge-legacy-small-size-container-size: 16px;
    --mat-badge-legacy-large-size-container-size: 28px;
    --mat-badge-container-offset: -11px 0;
    --mat-badge-small-size-container-offset: -8px 0;
    --mat-badge-large-size-container-offset: -14px 0;
    --mat-badge-container-overlap-offset: -11px;
    --mat-badge-small-size-container-overlap-offset: -8px;
    --mat-badge-large-size-container-overlap-offset: -14px;
    --mat-badge-container-padding: 0;
    --mat-badge-small-size-container-padding: 0;
    --mat-badge-large-size-container-padding: 0;
  }
  html {
    --mat-badge-background-color: #26bcd7;
    --mat-badge-text-color: rgba(0, 0, 0, 0.87);
    --mat-badge-disabled-state-background-color: #6e6e6e;
    --mat-badge-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  }
  .mat-badge-accent {
    --mat-badge-background-color: #54b948;
    --mat-badge-text-color: rgba(0, 0, 0, 0.87);
  }
  .mat-badge-warn {
    --mat-badge-background-color: #bb5f70;
    --mat-badge-text-color: white;
  }
  html {
    --mat-bottom-sheet-container-shape: 4px;
  }
  html {
    --mat-bottom-sheet-container-text-color: white;
    --mat-bottom-sheet-container-background-color: #424242;
  }
  html {
    --mat-legacy-button-toggle-height: 36px;
    --mat-legacy-button-toggle-shape: 2px;
    --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
    --mat-standard-button-toggle-shape: 4px;
    --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
    --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  }
  html {
    --mat-legacy-button-toggle-text-color: rgba(255, 255, 255, 0.5);
    --mat-legacy-button-toggle-state-layer-color: rgba(255, 255, 255, 0.12);
    --mat-legacy-button-toggle-selected-state-text-color: rgba(255, 255, 255, 0.7);
    --mat-legacy-button-toggle-selected-state-background-color: #212121;
    --mat-legacy-button-toggle-disabled-state-text-color: rgba(255, 255, 255, 0.3);
    --mat-legacy-button-toggle-disabled-state-background-color: black;
    --mat-legacy-button-toggle-disabled-selected-state-background-color: #424242;
    --mat-standard-button-toggle-text-color: white;
    --mat-standard-button-toggle-background-color: #424242;
    --mat-standard-button-toggle-state-layer-color: white;
    --mat-standard-button-toggle-selected-state-background-color: #212121;
    --mat-standard-button-toggle-selected-state-text-color: white;
    --mat-standard-button-toggle-disabled-state-text-color: rgba(255, 255, 255, 0.3);
    --mat-standard-button-toggle-disabled-state-background-color: #424242;
    --mat-standard-button-toggle-disabled-selected-state-text-color: white;
    --mat-standard-button-toggle-disabled-selected-state-background-color: #424242;
    --mat-standard-button-toggle-divider-color: #595959;
  }
  html {
    --mat-datepicker-calendar-container-shape: 4px;
    --mat-datepicker-calendar-container-touch-shape: 4px;
    --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  }
  html {
    --mat-datepicker-calendar-date-selected-state-text-color: rgba(0, 0, 0, 0.87);
    --mat-datepicker-calendar-date-selected-state-background-color: #26bcd7;
    --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(38, 188, 215, 0.4);
    --mat-datepicker-calendar-date-today-selected-state-outline-color: rgba(0, 0, 0, 0.87);
    --mat-datepicker-calendar-date-focus-state-background-color: rgba(38, 188, 215, 0.3);
    --mat-datepicker-calendar-date-hover-state-background-color: rgba(38, 188, 215, 0.3);
    --mat-datepicker-toggle-active-state-icon-color: #26bcd7;
    --mat-datepicker-calendar-date-in-range-state-background-color: rgba(38, 188, 215, 0.2);
    --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
    --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
    --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
    --mat-datepicker-toggle-icon-color: white;
    --mat-datepicker-calendar-body-label-text-color: rgba(255, 255, 255, 0.7);
    --mat-datepicker-calendar-period-button-text-color: #fff;
    --mat-datepicker-calendar-period-button-icon-color: white;
    --mat-datepicker-calendar-navigation-button-icon-color: white;
    --mat-datepicker-calendar-header-divider-color: rgba(255, 255, 255, 0.12);
    --mat-datepicker-calendar-header-text-color: rgba(255, 255, 255, 0.7);
    --mat-datepicker-calendar-date-today-outline-color: rgba(255, 255, 255, 0.5);
    --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(255, 255, 255, 0.3);
    --mat-datepicker-calendar-date-text-color: white;
    --mat-datepicker-calendar-date-outline-color: transparent;
    --mat-datepicker-calendar-date-disabled-state-text-color: rgba(255, 255, 255, 0.5);
    --mat-datepicker-calendar-date-preview-state-outline-color: rgba(255, 255, 255, 0.24);
    --mat-datepicker-range-input-separator-color: white;
    --mat-datepicker-range-input-disabled-state-separator-color: rgba(255, 255, 255, 0.5);
    --mat-datepicker-range-input-disabled-state-text-color: rgba(255, 255, 255, 0.5);
    --mat-datepicker-calendar-container-background-color: #424242;
    --mat-datepicker-calendar-container-text-color: white;
  }
  .mat-datepicker-content.mat-accent {
    --mat-datepicker-calendar-date-selected-state-text-color: rgba(0, 0, 0, 0.87);
    --mat-datepicker-calendar-date-selected-state-background-color: #54b948;
    --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(84, 185, 72, 0.4);
    --mat-datepicker-calendar-date-today-selected-state-outline-color: rgba(0, 0, 0, 0.87);
    --mat-datepicker-calendar-date-focus-state-background-color: rgba(84, 185, 72, 0.3);
    --mat-datepicker-calendar-date-hover-state-background-color: rgba(84, 185, 72, 0.3);
    --mat-datepicker-calendar-date-in-range-state-background-color: rgba(84, 185, 72, 0.2);
    --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
    --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
    --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  }
  .mat-datepicker-content.mat-warn {
    --mat-datepicker-calendar-date-selected-state-text-color: white;
    --mat-datepicker-calendar-date-selected-state-background-color: #bb5f70;
    --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(187, 95, 112, 0.4);
    --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
    --mat-datepicker-calendar-date-focus-state-background-color: rgba(187, 95, 112, 0.3);
    --mat-datepicker-calendar-date-hover-state-background-color: rgba(187, 95, 112, 0.3);
    --mat-datepicker-calendar-date-in-range-state-background-color: rgba(187, 95, 112, 0.2);
    --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
    --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
    --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  }
  .mat-datepicker-toggle-active.mat-accent {
    --mat-datepicker-toggle-active-state-icon-color: #54b948;
  }
  .mat-datepicker-toggle-active.mat-warn {
    --mat-datepicker-toggle-active-state-icon-color: #bb5f70;
  }
  html {
    --mat-divider-width: 1px;
  }
  html {
    --mat-divider-color: rgba(255, 255, 255, 0.12);
  }
  html {
    --mat-expansion-container-shape: 4px;
    --mat-expansion-legacy-header-indicator-display: inline-block;
    --mat-expansion-header-indicator-display: none;
  }
  html {
    --mat-expansion-container-background-color: #424242;
    --mat-expansion-container-text-color: white;
    --mat-expansion-actions-divider-color: rgba(255, 255, 255, 0.12);
    --mat-expansion-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
    --mat-expansion-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
    --mat-expansion-header-disabled-state-text-color: rgba(255, 255, 255, 0.3);
    --mat-expansion-header-text-color: white;
    --mat-expansion-header-description-color: rgba(255, 255, 255, 0.7);
    --mat-expansion-header-indicator-color: rgba(255, 255, 255, 0.7);
  }
  html {
    --mat-icon-color: inherit;
  }
  .mat-icon.mat-primary {
    --mat-icon-color: #26bcd7;
  }
  .mat-icon.mat-accent {
    --mat-icon-color: #54b948;
  }
  .mat-icon.mat-warn {
    --mat-icon-color: #bb5f70;
  }
  html {
    --mat-sidenav-container-shape: 0;
    --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    --mat-sidenav-container-width: auto;
  }
  html {
    --mat-sidenav-container-divider-color: rgba(255, 255, 255, 0.12);
    --mat-sidenav-container-background-color: #424242;
    --mat-sidenav-container-text-color: white;
    --mat-sidenav-content-background-color: #303030;
    --mat-sidenav-content-text-color: white;
    --mat-sidenav-scrim-color: rgba(189, 189, 189, 0.6);
  }
  html {
    --mat-stepper-header-icon-foreground-color: rgba(0, 0, 0, 0.87);
    --mat-stepper-header-selected-state-icon-background-color: #26bcd7;
    --mat-stepper-header-selected-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
    --mat-stepper-header-done-state-icon-background-color: #26bcd7;
    --mat-stepper-header-done-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
    --mat-stepper-header-edit-state-icon-background-color: #26bcd7;
    --mat-stepper-header-edit-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
    --mat-stepper-container-color: #424242;
    --mat-stepper-line-color: rgba(255, 255, 255, 0.12);
    --mat-stepper-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
    --mat-stepper-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
    --mat-stepper-header-label-text-color: rgba(255, 255, 255, 0.7);
    --mat-stepper-header-optional-label-text-color: rgba(255, 255, 255, 0.7);
    --mat-stepper-header-selected-state-label-text-color: white;
    --mat-stepper-header-error-state-label-text-color: #bb5f70;
    --mat-stepper-header-icon-background-color: rgba(255, 255, 255, 0.7);
    --mat-stepper-header-error-state-icon-foreground-color: #bb5f70;
    --mat-stepper-header-error-state-icon-background-color: transparent;
  }
  html .mat-step-header.mat-accent {
    --mat-stepper-header-icon-foreground-color: rgba(0, 0, 0, 0.87);
    --mat-stepper-header-selected-state-icon-background-color: #54b948;
    --mat-stepper-header-selected-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
    --mat-stepper-header-done-state-icon-background-color: #54b948;
    --mat-stepper-header-done-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
    --mat-stepper-header-edit-state-icon-background-color: #54b948;
    --mat-stepper-header-edit-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
  }
  html .mat-step-header.mat-warn {
    --mat-stepper-header-icon-foreground-color: white;
    --mat-stepper-header-selected-state-icon-background-color: #bb5f70;
    --mat-stepper-header-selected-state-icon-foreground-color: white;
    --mat-stepper-header-done-state-icon-background-color: #bb5f70;
    --mat-stepper-header-done-state-icon-foreground-color: white;
    --mat-stepper-header-edit-state-icon-background-color: #bb5f70;
    --mat-stepper-header-edit-state-icon-foreground-color: white;
  }
  html {
    --mat-sort-arrow-color: #c6c6c6;
  }
  html {
    --mat-toolbar-container-background-color: #212121;
    --mat-toolbar-container-text-color: white;
  }
  .mat-toolbar.mat-primary {
    --mat-toolbar-container-background-color: #26bcd7;
    --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
  }
  .mat-toolbar.mat-accent {
    --mat-toolbar-container-background-color: #54b948;
    --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
  }
  .mat-toolbar.mat-warn {
    --mat-toolbar-container-background-color: #bb5f70;
    --mat-toolbar-container-text-color: white;
  }
  html {
    --mat-tree-container-background-color: #424242;
    --mat-tree-node-text-color: white;
  }
  html {
    --mat-toolbar-container-background-color: #212121;
    --mat-toolbar-container-text-color: white;
  }
  .mat-toolbar.mat-primary {
    --mat-toolbar-container-background-color: #26dbf1;
    --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
  }
  .mat-toolbar.mat-accent {
    --mat-toolbar-container-background-color: #54d862;
    --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
  }
  .mat-toolbar.mat-warn {
    --mat-toolbar-container-background-color: #bb7e8a;
    --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
  }
  :where(a:link) {
    color: #9e9eff;
  }
  a:visited {
    color: #d0adf0;
  }
}
html {
  --mat-form-field-container-height: 48px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 12px;
  --mat-form-field-filled-with-label-container-padding-top: 20px;
  --mat-form-field-filled-with-label-container-padding-bottom: 4px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: inline;
}

:root {
  --mat-select-arrow-transform: translateY(-8px);
}

@font-face {
  font-display: swap;
  font-family: Roboto;
  font-style: italic;
  font-weight: 100;
  src: url(roboto-italic-100.woff2) format(woff2);
}
@font-face {
  font-display: swap;
  font-family: Roboto;
  font-style: italic;
  font-weight: 300;
  src: url(roboto-italic-300.woff2) format(woff2);
}
@font-face {
  font-display: swap;
  font-family: Roboto;
  font-style: italic;
  font-weight: 400;
  src: url(roboto-italic-400.woff2) format(woff2);
}
@font-face {
  font-display: swap;
  font-family: Roboto;
  font-style: italic;
  font-weight: 500;
  src: url(roboto-italic-500.woff2) format(woff2);
}
@font-face {
  font-display: swap;
  font-family: Roboto;
  font-style: italic;
  font-weight: 700;
  src: url(roboto-italic-700.woff2) format(woff2);
}
@font-face {
  font-display: swap;
  font-family: Roboto;
  font-style: italic;
  font-weight: 900;
  src: url(roboto-italic-900.woff2) format(woff2);
}
@font-face {
  font-display: swap;
  font-family: Roboto;
  font-style: normal;
  font-weight: 100;
  src: url(roboto-normal-100.woff2) format(woff2);
}
@font-face {
  font-display: swap;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  src: url(roboto-normal-300.woff2) format(woff2);
}
@font-face {
  font-display: swap;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  src: url(roboto-normal-400.woff2) format(woff2);
}
@font-face {
  font-display: swap;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  src: url(roboto-normal-500.woff2) format(woff2);
}
@font-face {
  font-display: swap;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  src: url(roboto-normal-700.woff2) format(woff2);
}
@font-face {
  font-display: swap;
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  src: url(roboto-normal-900.woff2) format(woff2);
}
.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  font: 400 24px / 32px Roboto;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2 {
  font: 500 20px / 32px Roboto;
  letter-spacing: 0.0125em;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3 {
  font: 400 16px / 28px Roboto;
  letter-spacing: 0.009375em;
  margin: 0 0 16px;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4 {
  font: 400 16px / 24px Roboto;
  letter-spacing: 0.03125em;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-subtitle-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-subtitle-2 {
  font: 500 14px / 22px Roboto;
  letter-spacing: 0.0071428571em;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font: 400 14px / 20px Roboto;
  letter-spacing: 0.0178571429em;
}
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto;
  letter-spacing: 0.0333333333em;
}

.mat-headline-1,
.mat-typography .mat-headline-1 {
  font: 300 96px / 96px Roboto;
  letter-spacing: -0.015625em;
  margin: 0 0 56px;
}

.mat-headline-2,
.mat-typography .mat-headline-2 {
  font: 300 60px / 60px Roboto;
  letter-spacing: -0.0083333333em;
  margin: 0 0 64px;
}

.mat-headline-3,
.mat-typography .mat-headline-3 {
  font: 400 48px / 50px Roboto;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-4,
.mat-typography .mat-headline-4 {
  font: 400 34px / 40px Roboto;
  letter-spacing: 0.0073529412em;
  margin: 0 0 64px;
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("material-icons-regular.woff2") format("woff2");
}
.material-icons {
  font-family: "Material Icons";
  font-size: 24px; /* Preferred icon size */
  font-style: normal;
  font-weight: normal;
  direction: ltr;
  display: inline-block;
  letter-spacing: normal;
  line-height: 1;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
}

:root {
  --iux-ng-mat-theme-blue-dark-primary: #005984;
  --iux-ng-mat-theme-blue-light-primary: #26bcd7;
  --iux-ng-mat-theme-green-primary: #54b948;
  --iux-ng-mat-theme-burgandy-dark-secondary: #8b0e04;
  --iux-ng-mat-theme-burgandy-medium-secondary: #9e1b32;
  --iux-ng-mat-theme-cream-secondary: #feb;
  --iux-ng-mat-theme-gray-1-secondary: #696969;
  --iux-ng-mat-theme-gray-2-secondary: #d7d9da;
  --iux-ng-mat-theme-green-dark-secondary: #00502f;
  --iux-ng-mat-theme-green-medium-secondary: #00853f;
  --iux-ng-mat-theme-orange-secondary: #f78e1e;
  --iux-ng-mat-theme-tan-secondary: #e7d2ad;
  --iux-ng-mat-theme-teal-secondary: #69b8d0;
  --iux-ng-mat-theme-yellow-medium-secondary: #fdb913;
  --iux-ng-mat-theme-yellow-secondary: #ffd200;
}

:root {
  --iux-ng-mat-theme-contrast-blue-dark-primary: white;
  --iux-ng-mat-theme-contrast-blue-light-primary: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-contrast-green-primary: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-contrast-burgandy-dark-secondary: white;
  --iux-ng-mat-theme-contrast-burgandy-medium-secondary: white;
  --iux-ng-mat-theme-contrast-cream-secondary: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-contrast-gray-1-secondary: white;
  --iux-ng-mat-theme-contrast-gray-2-secondary: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-contrast-green-dark-secondary: white;
  --iux-ng-mat-theme-contrast-green-medium-secondary: white;
  --iux-ng-mat-theme-contrast-orange-secondary: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-contrast-tan-secondary: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-contrast-teal-secondary: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-contrast-yellow-medium-secondary: rgba(0, 0, 0, 0.87);
  --iux-ng-mat-theme-contrast-yellow-secondary: rgba(0, 0, 0, 0.87);
}

:root {
  --iux-ng-mat-theme-contrast-dark-background: white;
  --iux-ng-mat-theme-contrast-light-background: rgba(0, 0, 0, 0.87);
}