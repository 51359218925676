// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming


// @SuppressWarnings({"css:S4662"})
@use '@angular/material' as mat;
// Plus imports for other components in your app.
// @SuppressWarnings({"css:S4662"})
@use '@iux/ng-mat-theme';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@include ng-mat-theme.theme;

@import "@de-electron/design-tokens/dist/web/__all";
@import "@de-electron/component-library/src/global/scss/base/_reset";
// import fonts here
@import "@de-electron/component-library/src/global/scss/base/_typography";
@import "@de-electron/component-library/src/global/scss/base/_loader";

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// $SCSTLeadTime_Frontend-primary: mat.define-palette(mat.$indigo-palette);
// $SCSTLeadTime_Frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// // The warn palette is optional (defaults to red).
// $SCSTLeadTime_Frontend-warn: mat.define-palette(mat.$red-palette);

// // Create the theme object. A theme consists of configurations for individual
// // theming systems such as "color" or "typography".
// $SCSTLeadTime_Frontend-theme: mat.define-light-theme((
//   color: (
//     primary: $SCSTLeadTime_Frontend-primary,
//     accent: $SCSTLeadTime_Frontend-accent,
//     warn: $SCSTLeadTime_Frontend-warn,
//   ),
//   typography: mat.define-typography-config(),
//   density: 0
// ));

// // Include theme styles for core and each component used in your app.
// // Alternatively, you can import and @include the theme mixins for each component
// // that you are using.
// @include mat.all-component-themes($SCSTLeadTime_Frontend-theme);

/* You can add global styles to this file, and also import other style files */

@import 'assets/styles/electron';
html {
    --mat-dialog-container-max-width: 560px !important;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
