// ---------------------------------------------------------------
// Breakpoints Map
// Modular scale increasing at multiples of 1.2
// ---------------------------------------------------------------

$breakpoints: (
   xs: $breakpoint-xs,
    s: $breakpoint-s,
    m: $breakpoint-m,
    l: $breakpoint-l,
   xl: $breakpoint-xl,
  2xl: $breakpoint-2xl,
  3xl: $breakpoint-3xl
);

// ---------------------------------------------------------------
// Function to handle consuming the breakpoints map
// ---------------------------------------------------------------

@function breakpoint($breakpoint, $rule: null) {
  @if $rule == 'max-width' {
    @return (map-get($breakpoints, $breakpoint) - 1px);
  } @else {
    @return map-get($breakpoints, $breakpoint);
  }
}

// ---------------------------------------------------------------
// Function to handle interaction media query
// ---------------------------------------------------------------

@function breakpoint-interaction($interaction) {
  @if $interaction == 'touch' {
    @return (pointer: course);
  } @else if $interaction == 'click' {
    @return (pointer: fine);
  } @else {
    @return null;
  }
}

// ---------------------------------------------------------------
// Breakpoint Mixins
// Sets breakpoint values as a mixin based on the
// ---------------------------------------------------------------

// General Breakpoint Mixin
// @media rule defaults to 'min-width', but you may use 'max-width'
// Usage:
//    @include breakpoint(l, max-width) {
//        ...
//    }

@mixin breakpoint($breakpoint, $rule: min-width) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media only screen and (#{$rule}: breakpoint($breakpoint, #{$rule})) {
      @content;
    }
  } @else {
    @warn "Unknown `#{$breakpoint}` in $breakpoints map";
  }
}

/// Media query merger
/// Create a single media condition out of a map of queries
/// @param {Map} $queries - Map of media queries

@mixin breakpoint-media($queries) {
  // @warn 'Queries: `#{$queries}`.';
  @if length($queries) == 0 {
    @content;
  } @else {
    $first-key: nth(map-keys($queries), 1);

    @media ($first-key: map-get($queries, $first-key)) {
      $queries: map-remove($queries, $first-key);

      @include breakpoint-media($queries) {
        @content;
      }
    }
  }
}

/*
 * Breakpoint Min
 * Used to create min-width breakpoints. Mobile first approach. You also have
 * option to target touch screens or traditional click interaction screens.
 *
 * @param {string}  $breakpoint   Breakpoint scale value (s, m, l ...)
 * @param {string}  $interaction  How you interact with screen (touch or click)
 *
 * Usage
 * @include breakpoint-min(m);
 * @include breakpoint-min(m, touch);
 */

@mixin breakpoint-min($breakpoint, $interaction: null) {

  // If the breakpoint exists in the map
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value
    $breakpoint-value: (min-width: map-get($breakpoints, $breakpoint));

    // Add breakpoint to query
    $queries: $breakpoint-value;

    // If interaction exists
    @if $interaction != null and breakpoint-interaction($interaction) != null {

      // Get the interaction query
      $breakpoint-interaction: breakpoint-interaction($interaction);

      // Merge interaction value with breakpoint value in query
      $queries: map-merge($breakpoint-value, $breakpoint-interaction);
    }

    // Include the media mixin with $queries to write the media query
    @include breakpoint-media($queries) {
      @content;
    }

  // If the breakpoint doesn't exist in the map.
  } @else {

    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

/*
 * Breakpoint Max
 * Used to create max-width breakpoints. Desktop first approach. You also have
 * option to target touch screens or traditional click interaction screens.
 *
 * @param {string}  $breakpoint   Breakpoint scale value (s, m, l ...)
 * @param {string}  $interaction  How you interact with screen (touch or click)
 *
 * Usage
 * @include breakpoint-max(m);
 * @include breakpoint-max(m, touch);
 */

@mixin breakpoint-max($breakpoint, $interaction: null) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: (max-width: map-get($breakpoints, $breakpoint) - 1);

    // Add breakpoint to query
    $queries: $breakpoint-value;

    // If interaction exists
    @if $interaction != null and breakpoint-interaction($interaction) != null {

      // Get the interaction query
      $breakpoint-interaction: breakpoint-interaction($interaction);

      // Merge interaction value with breakpoint value in query
      $queries: map-merge($breakpoint-value, $breakpoint-interaction);
    }

    // Include the media mixin with $queries to write the media query
    @include breakpoint-media($queries) {
      @content;
    }

  // If the breakpoint doesn't exist in the map.
  } @else {

    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }

}

/*
 * Breakpoint Min Max
 * Used to create min-width and max-width breakpoint.
 *
 * @param {map}  $min  Breakpoint scale value (s, m, l ...)
 * @param {map}  $max  Breakpoint scale value (s, m, l ...)
 * @param {string}     $interaction  How you interact with screen (touch or click)
 *
 * Usage
 * @include breakpoint-minmax(m, xl) {
 *   ...
 * }
 */

@mixin breakpoint-minmax($min, $max) {

  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $min) and map-has-key($breakpoints, $max) {

    // Get the lower and upper breakpoints.
    $min-breakpoint: map-get($breakpoints, $min);
    $max-breakpoint: map-get($breakpoints, $max);

    // Write the media query.
    @media (min-width: $min-breakpoint) and (max-width: ($max-breakpoint - 1)) {
      @content;
    }

  // If one or both of the breakpoints don't exist.
  } @else {

    // If min breakpoint is invalid.
    @if (map-has-key($breakpoints, $min) == false) {

      // Log a warning.
      @warn 'Your min breakpoint was invalid: #{$min}.';
    }

    // If max breakpoint is invalid.
    @if (map-has-key($breakpoints, $max) == false) {

      // Log a warning.
      @warn 'Your max breakpoint was invalid: #{$max}.';
    }
  }

}

// Min/Max Breakpoint Mixin
// Usage:
//    @include breakpoint-min-max(l, xl) {
//        ...
//    }

@mixin breakpoint-min-max($minBreakpoint, $maxBreakpoint) {
  @if map-has-key($breakpoints, $minBreakpoint) and map-has-key($breakpoints, $maxBreakpoint) {
    @media only screen and (min-width: breakpoint($minBreakpoint, min-width)) and (max-width: breakpoint($maxBreakpoint, max-width)) {
      @content;
    }
  } @else if not map-has-key($breakpoints, $minBreakpoint) and not map-has-key($breakpoints, $maxBreakpoint) {
    @warn "Unknown minimum breakpoint `#{$minBreakpoint}` and maximum breakpoint `#{$maxBreakpoint}` in $breakpoints map";
  } @else if not map-has-key($breakpoints, $minBreakpoint) {
    @warn "Unknown minimum breakpoint `#{$minBreakpoint}` in $breakpoints map";
  } @else if not map-has-key($breakpoints, $maxBreakpoint) {
    @warn "Unknown maximum breakpoint `#{$maxBreakpoint}` in $breakpoints map";
  }
}
